// src/components/Calculators/DTCCalculator.js
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { sessionStore } from '../../utils/sessionStore';
import csvDataUrl from '../../constants/obd-trouble-codes.csv';

// Parse the OBD-II trouble codes CSV data into a map
const obdTroubleCodes = {};

// Parse the CSV data
const parseCSV = (csvText) => {
  if (!csvText) {
    console.error('CSV data is empty or undefined');
    return;
  }

  // Log the raw CSV first for debugging
  console.log('Raw CSV (first 100 chars):', csvText.substring(0, 100));
  
  // Split into lines - handle different line endings (CRLF, LF)
  const lines = csvText.split(/\r?\n/);
  console.log(`Found ${lines.length} lines in CSV`);
  
  // Print the first few lines for debugging
  lines.slice(0, 5).forEach((line, i) => {
    console.log(`Line ${i}: ${line}`);
  });
  
  // Parse each line - format is "CODE, DESCRIPTION" without quotes
  lines.forEach((line, index) => {
    if (line.trim()) {
      try {
        // Split by first comma
        const commaIndex = line.indexOf(',');
        if (commaIndex !== -1) {
          const code = line.substring(0, commaIndex).trim();
          const description = line.substring(commaIndex + 1).trim();
          
          if (code) {
            obdTroubleCodes[code] = description;
          }
        }
      } catch (e) {
        console.error(`Error parsing CSV line ${index}:`, line, e);
      }
    }
  });

  console.log('OBD Codes loaded:', Object.keys(obdTroubleCodes).length);
  if (Object.keys(obdTroubleCodes).length > 0) {
    console.log('Example codes:', Object.keys(obdTroubleCodes).slice(0, 5));
  }
};

// Function to fetch the CSV file
const fetchCSVFile = async () => {
  try {
    console.log('Fetching CSV from URL:', csvDataUrl);
    const response = await fetch(csvDataUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch CSV: ${response.status}`);
    }
    
    const text = await response.text();
    console.log('CSV fetch successful, length:', text.length);
    parseCSV(text);
  } catch (error) {
    console.error('Error fetching CSV file:', error);
  }
};

// Start the fetch process, but don't wait for it to complete before rendering
fetchCSVFile();

const SESSION_KEY = 'dtcCalculator';

/**
 * Converts a number (or hex string) to an upper-case hex string padded with zeros.
 */
const toPaddedHex = (value, length) => {
  if (typeof value === 'number') {
    value = value.toString(16);
  }
  return value.toUpperCase().padStart(length, '0');
};

/**
 * Given a DTC string and the current format, returns an object with the four bytes.
 *
 * In hex mode the string is assumed to be little-endian:
 *   first 2 hex digits = byte3, then byte4, then byte5, then byte6.
 *
 * If fewer than 8 hex digits are entered, the missing digits are padded on the right.
 */
const parseFullDTCBytes = (dtcStr, format) => {
  if (format === 'hex') {
    let cleanHex = dtcStr.replace(/[^0-9A-Fa-f]/g, '').padEnd(8, '0'); // pad right
    return {
      byte3: parseInt(cleanHex.substring(0, 2), 16),
      byte4: parseInt(cleanHex.substring(2, 4), 16),
      byte5: parseInt(cleanHex.substring(4, 6), 16),
      byte6: parseInt(cleanHex.substring(6, 8), 16)
    };
  } else {
    // In decimal mode, convert the number then extract bytes from the 32-bit integer.
    const dtcNum = parseInt(dtcStr, 10) || 0;
    return {
      byte6: (dtcNum >> 24) & 0xFF,
      byte5: (dtcNum >> 16) & 0xFF,
      byte4: (dtcNum >> 8) & 0xFF,
      byte3: dtcNum & 0xFF
    };
  }
};

/**
 * Decodes a full 4-byte (32-bit) J1939 DTC into its components.
 *
 * The bytes are interpreted in little-endian order:
 *   dtc = [byte3, byte4, byte5, byte6]
 *
 * Returns an object { spn, fmi, oc, cm }.
 */
const decodeJ1939DTC = (dtcStr, format) => {
  const { byte3, byte4, byte5, byte6 } = parseFullDTCBytes(dtcStr, format);
  const spnHigh3 = (byte5 & 0b11100000) >> 5;
  const spn = (spnHigh3 << 16) | (byte4 << 8) | byte3;
  const fmi = byte5 & 0b00011111;
  const cm = (byte6 & 0b10000000) >> 7;
  const oc = byte6 & 0b01111111;
  return { spn, fmi, oc, cm };
};

/**
 * Encodes SPN, FMI, OC, and CM into 4 bytes.
 *
 * Returns an object with properties: byte3, byte4, byte5, byte6.
 *
 * (byte3 is the low SPN byte, byte4 the middle, byte5 contains the top 3 bits of SPN (in its upper 3 bits)
 * combined with the 5-bit FMI, and byte6 contains CM in its MSB and OC in its lower 7 bits.)
 */
const encodeJ1939DTC = (spn, fmi, oc, cm) => {
  const byte3 = (spn & 0xFF) | 0x80;
  const byte4 = (spn >> 8) & 0xFF;
  const spnHigh3 = (spn >> 16) & 0x07;
  const byte5 = (spnHigh3 << 5) | (fmi & 0x1F);
  const byte6 = ((cm & 0x1) << 7) | (oc & 0x7F);
  return { byte3, byte4, byte5, byte6 };
};

/**
 * Converts 4 bytes (in little-endian order: [byte3, byte4, byte5, byte6])
 * into a hex string.
 */
const bytesToDTCHex = (byte3, byte4, byte5, byte6) => {
  return [byte3, byte4, byte5, byte6]
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase();
};

/**
 * Converts 4 bytes into a 32-bit unsigned integer.
 * (Uses big-endian reassembly: dtcNum = (byte6<<24)|(byte5<<16)|(byte4<<8)|byte3.)
 */
const dtcBytesToDecimal = (byte3, byte4, byte5, byte6) => {
  return ((byte6 << 24) | (byte5 << 16) | (byte4 << 8) | byte3) >>> 0;
};

/**
 * Gets the description for an OBD-II trouble code
 * @param {string} code - The OBD code (e.g., "P0123")
 * @returns {string|null} The description or null if not found
 */
const getOBDCodeDescription = (code) => {
  if (!code) return null;
  
  // Clean up the code (remove any whitespace, ensure uppercase)
  const cleanCode = code.trim().toUpperCase();
  
  // Check if the code exists in our database
  if (cleanCode in obdTroubleCodes) {
    return obdTroubleCodes[cleanCode];
  }
  
  return null;
};

export const DTCCalculator = ({ open, onClose }) => {
  const [mode, setMode] = useState('j1939'); // 'j1939' or 'obdii'
  const [j1939Format, setJ1939Format] = useState('hex'); // 'hex' or 'decimal'

  // J1939 fields – dtcValue holds the full 4-byte DTC.
  const [dtcValue, setDtcValue] = useState('');
  const [spn, setSPN] = useState('');
  const [fmi, setFMI] = useState('');
  const [oc, setOC] = useState('');
  const [cm, setCM] = useState('');

  // Flags to avoid recursive updates.
  const [isUpdatingFromDTC, setIsUpdatingFromDTC] = useState(false);
  const [isUpdatingFromComponents, setIsUpdatingFromComponents] = useState(false);

  // OBD-II fields
  const [obdDTC, setObdDTC] = useState('');
  const [system, setSystem] = useState('');
  const [subsystem, setSubsystem] = useState(''); // Changed from pid to subsystem
  const [fault, setFault] = useState('');
  const [faultDescription, setFaultDescription] = useState('');

  useEffect(() => {
    const savedValues = sessionStore.getItem(SESSION_KEY);
    if (savedValues) {
      setMode(savedValues.mode || 'j1939');
      setJ1939Format(savedValues.j1939Format || 'hex');
      setDtcValue(savedValues.dtcValue || '');
      setSPN(savedValues.spn || '');
      setFMI(savedValues.fmi || '');
      setOC(savedValues.oc || '');
      setCM(savedValues.cm || '');
      setObdDTC(savedValues.obdDTC || '');
      setSystem(savedValues.system || '');
      setSubsystem(savedValues.subsystem || ''); // Changed from pid to subsystem
      setFault(savedValues.fault || '');
      
      // Look up fault description if we have a code
      if (savedValues.obdDTC) {
        setFaultDescription(getOBDCodeDescription(savedValues.obdDTC) || '');
      } else {
        setFaultDescription('');
      }
    }
  }, [open]);

  const saveToSession = () => {
    sessionStore.setItem(SESSION_KEY, {
      mode,
      j1939Format,
      dtcValue,
      spn,
      fmi,
      oc,
      cm,
      obdDTC,
      system,
      subsystem, // Changed from pid to subsystem
      fault
    });
  };

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
      saveToSession();
    }
  };

  // Parse a value (for components) based on current format.
  const parseValueByFormat = (value) => {
    // Return 0 for empty, null, undefined or NaN values
    if (value === '' || value === null || value === undefined) return 0;
    try {
      const parsed = parseInt(value, j1939Format === 'hex' ? 16 : 10);
      return isNaN(parsed) ? 0 : parsed;
    } catch (e) {
      console.error('Error parsing value:', e);
      return 0;
    }
  };

  // Format a number for display based on the current format.
  const formatValueByFormat = (value) => {
    if (value === undefined || value === null) return '';
    if (j1939Format === 'hex') {
      // For components we don’t need extra padding.
      return value.toString(16).toUpperCase();
    } else {
      return value.toString(10);
    }
  };

  // When the user toggles hex/decimal, convert all values.
  const handleJ1939FormatChange = () => {
    const newFormat = j1939Format === 'decimal' ? 'hex' : 'decimal';
    setJ1939Format(newFormat);

    // Convert SPN, FMI, OC, and also the DTC value.
    if (spn) {
      const spnValue = parseValueByFormat(spn);
      setSPN(newFormat === 'hex' ? spnValue.toString(16).toUpperCase() : spnValue.toString(10));
    }
    if (fmi) {
      const fmiValue = parseValueByFormat(fmi);
      setFMI(newFormat === 'hex' ? fmiValue.toString(16).toUpperCase() : fmiValue.toString(10));
    }
    if (oc) {
      const ocValue = parseValueByFormat(oc);
      setOC(newFormat === 'hex' ? ocValue.toString(16).toUpperCase() : ocValue.toString(10));
    }
    if (cm) {
      const cmValue = parseInt(cm, 10);
      setCM(cmValue.toString(10));
    }
    if (dtcValue) {
      if (newFormat === 'hex') {
        // If switching to hex, convert the decimal number to our little-endian hex string.
        const dtcNum = parseInt(dtcValue, 10) || 0;
        const byte6 = (dtcNum >> 24) & 0xFF;
        const byte5 = (dtcNum >> 16) & 0xFF;
        const byte4 = (dtcNum >> 8) & 0xFF;
        const byte3 = dtcNum & 0xFF;
        const hexStr = bytesToDTCHex(byte3, byte4, byte5, byte6);
        setDtcValue(hexStr);
      } else {
        // Switching to decimal: convert the current hex (little-endian) into a 32-bit number.
        const { byte3, byte4, byte5, byte6 } = parseFullDTCBytes(dtcValue, 'hex');
        const dtcNum = dtcBytesToDecimal(byte3, byte4, byte5, byte6);
        setDtcValue(dtcNum.toString(10));
      }
    }
    saveToSession();
  };

  // When any component field changes, recalculate the full DTC.
  const calculateDTCFromComponents = () => {
    if (isUpdatingFromDTC) return;
    try {
      setIsUpdatingFromComponents(true);
      const spnValue = parseValueByFormat(spn);
      const fmiValue = parseValueByFormat(fmi);
      const ocValue = parseValueByFormat(oc);
      const cmValue = parseValueByFormat(cm);
      
      const { byte3, byte4, byte5, byte6 } = encodeJ1939DTC(spnValue, fmiValue, ocValue, cmValue);
      if (j1939Format === 'hex') {
        const dtcStr = bytesToDTCHex(byte3, byte4, byte5, byte6);
        setDtcValue(dtcStr);
      } else {
        const dtcNum = dtcBytesToDecimal(byte3, byte4, byte5, byte6);
        setDtcValue(dtcNum.toString(10));
      }
    } catch (e) {
      console.error('Error calculating DTC:', e);
    } finally {
      setIsUpdatingFromComponents(false);
      saveToSession();
    }
  };

  // Handle changes when the user enters a DTC value.
  const handleDTCChange = (value) => {
    setDtcValue(value);
    if (isUpdatingFromComponents) return;
    try {
      setIsUpdatingFromDTC(true);
      let dtcStr;
      if (j1939Format === 'hex') {
        dtcStr = value.replace(/[^0-9A-Fa-f]/g, '').padEnd(8, '0');
      } else {
        // For decimal, parse the number and then extract bytes.
        const dtcNum = parseInt(value, 10) || 0;
        const byte6 = (dtcNum >> 24) & 0xFF;
        const byte5 = (dtcNum >> 16) & 0xFF;
        const byte4 = (dtcNum >> 8) & 0xFF;
        const byte3 = dtcNum & 0xFF;
        dtcStr = bytesToDTCHex(byte3, byte4, byte5, byte6);
      }
      // Decode the DTC.
      const decoded = decodeJ1939DTC(dtcStr, 'hex');
      if (decoded) {
        setSPN(j1939Format === 'hex' ? decoded.spn.toString(16).toUpperCase() : decoded.spn.toString(10));
        setFMI(j1939Format === 'hex' ? decoded.fmi.toString(16).toUpperCase() : decoded.fmi.toString(10));
        setOC(j1939Format === 'hex' ? decoded.oc.toString(16).toUpperCase() : decoded.oc.toString(10));
        setCM(decoded.cm.toString());
      }
    } catch (e) {
      console.error('Error parsing DTC:', e);
    } finally {
      setIsUpdatingFromDTC(false);
      saveToSession();
    }
  };

  // On blur in hex mode, ensure the DTC is padded to 8 digits by padding on the right.
  const handleDTCBlur = () => {
    if (j1939Format === 'hex') {
      const clean = dtcValue.replace(/[^0-9A-Fa-f]/g, '').padEnd(8, '0');
      setDtcValue(clean.toUpperCase());
      saveToSession();
    }
  };

  // Component field change handlers.
  const handleSPNChange = (value) => { 
    setSPN(value); 
    calculateDTCFromComponents(); 
  };
  
  const handleFMIChange = (value) => { 
    setFMI(value); 
    calculateDTCFromComponents(); 
  };
  
  const handleOCChange = (value) => { 
    setOC(value); 
    calculateDTCFromComponents(); 
  };
  
  const handleCMChange = (value) => {
    const cmValue = parseValueByFormat(value);
    setCM(cmValue > 0 ? '1' : '0');
    calculateDTCFromComponents();
  };

  // OBD-II functions
  const handleOBDDTCChange = (value) => {
    const uppercaseValue = value.toUpperCase();
    setObdDTC(uppercaseValue);
    
    try {
      // Process if we have a valid code format (e.g., P0123)
      if (uppercaseValue && uppercaseValue.length === 5) {
        const systemCode = uppercaseValue.charAt(0);
        const subsystemValue = uppercaseValue.charAt(2); // Get just the first digit
        const faultValue = uppercaseValue.substring(3, 5); // Get the last two digits
        
        // Set the system name based on the first letter
        let systemName;
        switch (systemCode) {
          case 'P': systemName = 'Powertrain'; break;
          case 'C': systemName = 'Chassis'; break;
          case 'B': systemName = 'Body'; break;
          case 'U': systemName = 'Network'; break;
          default: systemName = 'Unknown';
        }
        
        setSystem(systemName);
        setSubsystem(subsystemValue);
        setFault(faultValue);
        
        const description = getOBDCodeDescription(uppercaseValue);
        console.log("Code looked up:", uppercaseValue, "Description:", description);
        setFaultDescription(description || '');
      } else {
        setFaultDescription('');
      }
    } catch (e) {
      console.error('Error parsing OBD-II DTC:', e);
      setFaultDescription('');
    }
    saveToSession();
  };

  const handleSystemChange = (value) => {
    setSystem(value);
    updateOBDCode('system', value);
  };

  const handleSubsystemChange = (value) => {
    // Ensure subsystem is just a single digit
    const singleDigit = value.length > 0 ? value.charAt(0) : '';
    setSubsystem(singleDigit);
    updateOBDCode('subsystem', singleDigit);
  };

  const handleFaultChange = (value) => {
    // Allow up to 2 digits for fault
    const twoDigits = value.length > 2 ? value.substring(0, 2) : value;
    setFault(twoDigits);
    updateOBDCode('fault', twoDigits);
  };

  // Update the OBD code when system, subsystem, or fault changes
  const updateOBDCode = (changedField, value) => {
    try {
      let systemCode = 'P'; // Default to Powertrain
      
      // If the system field changed, update the system code
      if (changedField === 'system') {
        switch (value.toLowerCase()) {
          case 'powertrain': systemCode = 'P'; break;
          case 'chassis': systemCode = 'C'; break;
          case 'body': systemCode = 'B'; break;
          case 'network': systemCode = 'U'; break;
          default: systemCode = 'P';
        }
      } else {
        // Otherwise use the existing system value
        switch (system.toLowerCase()) {
          case 'powertrain': systemCode = 'P'; break;
          case 'chassis': systemCode = 'C'; break;
          case 'body': systemCode = 'B'; break;
          case 'network': systemCode = 'U'; break;
          default: systemCode = 'P';
        }
      }
      
      const firstDigit = '0'; // For generic codes
      const subsystemValue = changedField === 'subsystem' ? value : (subsystem || '0');
      const faultValue = changedField === 'fault' ? value.padStart(2, '0') : (fault || '00');
      
      const dtc = `${systemCode}${firstDigit}${subsystemValue}${faultValue}`;
      setObdDTC(dtc);
      
      const description = getOBDCodeDescription(dtc);
      setFaultDescription(description || '');
    } catch (e) {
      console.error('Error updating OBD-II DTC', e);
    }
    saveToSession();
  };

  const handleClear = () => {
    if (mode === 'j1939') {
      setDtcValue('');
      setSPN('');
      setFMI('');
      setOC('');
      setCM('');
    } else {
      setObdDTC('');
      setSystem('');
      setSubsystem('');
      setFault('');
      setFaultDescription('');
    }
    saveToSession();
  };

  // When the component mounts or values change, ensure consistent rendering
  useEffect(() => {
    // Ensure component values are calculated on mount
    if (!isUpdatingFromDTC && !isUpdatingFromComponents && dtcValue) {
      handleDTCChange(dtcValue);
    }
  }, [open]); // Only run on open to avoid infinite loops

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>DTC Calculator</DialogTitle>
      <DialogContent>
        <ToggleButtonGroup
          value={mode}
          exclusive
          onChange={handleModeChange}
          aria-label="DTC standard"
          fullWidth
          sx={{ mb: 3 }}
        >
          <ToggleButton value="j1939" aria-label="J1939">
            J1939
          </ToggleButton>
          <ToggleButton value="obdii" aria-label="OBD-II">
            OBD-II
          </ToggleButton>
        </ToggleButtonGroup>

        {mode === 'j1939' ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="subtitle1">J1939 DTC Conversion</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={j1939Format === 'hex'}
                    onChange={handleJ1939FormatChange}
                    color="primary"
                  />
                }
                label={j1939Format === 'hex' ? 'Hex' : 'Decimal'}
                labelPlacement="start"
              />
            </Box>
            
            <TextField
              label="DTC Value (4 bytes)"
              value={dtcValue}
              onChange={(e) => handleDTCChange(e.target.value)}
              onBlur={handleDTCBlur}
              fullWidth
              margin="normal"
              placeholder={j1939Format === 'hex' ? "Enter up to 8 hex digits (e.g., 72F0F37E)" : "Enter DTC as decimal"}
              helperText="Enter the full 32-bit DTC value"
            />
            
            <Divider sx={{ my: 2 }}>Component Values</Divider>
            
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TextField
                label="SPN"
                value={spn}
                onChange={(e) => handleSPNChange(e.target.value)}
                fullWidth
                margin="dense"
                helperText={`Suspect Parameter Number (${j1939Format})`}
              />
              <TextField
                label="FMI"
                value={fmi}
                onChange={(e) => handleFMIChange(e.target.value)}
                fullWidth
                margin="dense"
                helperText={`Failure Mode Identifier (${j1939Format})`}
              />
            </Box>
            
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TextField
                label="OC"
                value={oc}
                onChange={(e) => handleOCChange(e.target.value)}
                fullWidth
                margin="dense"
                helperText={`Occurrence Count (${j1939Format})`}
              />
              <TextField
                label="CM"
                value={cm}
                onChange={(e) => handleCMChange(e.target.value)}
                fullWidth
                margin="dense"
                helperText="Conversion Method (0-1)"
              />
            </Box>
          </>
        ) : (
          <>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>OBD-II DTC Conversion</Typography>
            
            <TextField
              label="DTC"
              value={obdDTC}
              onChange={(e) => handleOBDDTCChange(e.target.value)}
              fullWidth
              margin="normal"
              placeholder="Example: P0123"
              helperText="Enter DTC to convert to System/Subsystem/Fault (ex: P0123)"
            />
            
            <Divider sx={{ my: 2 }}>OR</Divider>
            
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 2 }}>
              <TextField
                label="System"
                value={system}
                onChange={(e) => handleSystemChange(e.target.value)}
                fullWidth
                margin="dense"
                placeholder="Powertrain, Chassis, Body, Network"
                helperText="P=Powertrain, C=Chassis, B=Body, U=Network"
              />
              <TextField
                label="Subsystem"
                value={subsystem}
                onChange={(e) => handleSubsystemChange(e.target.value)}
                fullWidth
                margin="dense"
                placeholder="Single digit (0-9)"
                helperText="Subsystem (1 digit)"
                inputProps={{ maxLength: 1 }}
              />
              <TextField
                label="Fault"
                value={fault}
                onChange={(e) => handleFaultChange(e.target.value)}
                fullWidth
                margin="dense"
                placeholder="Two digits (00-99)"
                helperText="Fault Code (2 digits)"
                inputProps={{ maxLength: 2 }}
              />
            </Box>
            
            {faultDescription && (
                <Box sx={{ mt: 2, mb: 2 }}>
                    <TextField
                        label="Fault Description"
                        value={faultDescription}
                        InputProps={{ readOnly: true }}
                        fullWidth
                        multiline
                        rows={2}
                        variant="filled"
                    />
                </Box>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClear}>Clear</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
