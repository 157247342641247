// src/App.js
import React, { useContext, useRef } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Workspace from './components/Workspace';
import TraceWindow from './components/TraceWindow';
import GraphWindow from './components/GraphWindow';
import TestCasesWindow from './components/TestCasesWindow';
import DocumentationPage from './pages/DocumentationPage';
import ProductsPage from './pages/ProductsPage';
import ProductPage from './pages/ProductPage';
import HomePage from './pages/HomePage';
import InScopePage from './pages/InScopePage';
import AccountPage from './pages/AccountPage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import { AuthContext } from './context/AuthContext';
import { LGraph, LiteGraph } from 'litegraph.js';
import { registerNodes } from './utils/nodeRegistration';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/@slickgrid-universal/common/dist/styles/css/slickgrid-theme-bootstrap.css';
import AdminRoute from './components/AdminRoute';
import AdminArticlesPage from './pages/AdminArticlesPage';
import AdminProductsPage from './pages/AdminProductsPage';
import LatestNewsPage from './pages/LatestNewsPage';
import ArticlePage from './pages/ArticlePage';

// Register all nodes
registerNodes();

window.LiteGraph = LiteGraph;

function App() {
  const { isAuthenticated, loading } = useContext(AuthContext);
  const graph = useRef(new LGraph()).current;

  // Don't render routes while authentication is being restored
  if (loading) {
    return null; // Or a loading spinner
  }

  return (
    <div className="app-container">
      <Header />
      <div className="content-container">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/products/:id" element={<ProductPage />} />
          <Route path="/latest" element={<LatestNewsPage />} />
          <Route path="/latest/:id" element={<ArticlePage />} />
          <Route path="/documentation" element={
            isAuthenticated ? <DocumentationPage /> : <Navigate to="/signin" replace />
          } />
          <Route
            path="/inscope/*"
            element={
              isAuthenticated ? <InScopePage graph={graph} /> : <Navigate to="/signin" replace />
            }
          >
            <Route index element={<Navigate to="workspace" replace />} />
            <Route path="workspace" element={<Workspace />} />
            <Route path="trace/:windowId" element={<TraceWindow />} />
            <Route path="graph/:windowId" element={<GraphWindow />} />
            <Route path="testcases/:windowId" element={<TestCasesWindow />} />
            {/* Add catch-all redirect */}
            <Route path="*" element={<Navigate to="/inscope/workspace" replace />} />
          </Route>
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/account" element={
            isAuthenticated ? <AccountPage /> : <Navigate to="/signin" replace />
          } />
          {/* Admin Routes */}
          <Route path="/admin/*" element={
            <AdminRoute>
              <Routes>
                <Route path="articles" element={<AdminArticlesPage />} />
                <Route path="products" element={<AdminProductsPage />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </AdminRoute>
          } />
        </Routes>
      </div>
    </div>
  );
}

export default App;
