// src/components/Calculators/Checksums.js
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

export const Checksums = ({ open, onClose }) => {
  const [input, setInput] = useState('');
  const [crcType, setCrcType] = useState('CRC8');
  const [result, setResult] = useState('');

  const calculateCRC8 = (input) => {
    let crc = 0xFF;
    const polynomial = 0x1D;
    
    for (let i = 0; i < input.length; i++) {
      crc ^= input.charCodeAt(i);
      for (let j = 0; j < 8; j++) {
        if ((crc & 0x80) !== 0) {
          crc = ((crc << 1) ^ polynomial) & 0xFF;
        } else {
          crc = (crc << 1) & 0xFF;
        }
      }
    }
    return crc.toString(16).toUpperCase().padStart(2, '0');
  };

  const calculateCRC16 = (input) => {
    let crc = 0xFFFF;
    const polynomial = 0x1021;
    
    for (let i = 0; i < input.length; i++) {
      crc ^= (input.charCodeAt(i) << 8);
      for (let j = 0; j < 8; j++) {
        if ((crc & 0x8000) !== 0) {
          crc = ((crc << 1) ^ polynomial) & 0xFFFF;
        } else {
          crc = (crc << 1) & 0xFFFF;
        }
      }
    }
    return crc.toString(16).toUpperCase().padStart(4, '0');
  };

  const calculateCRC32 = (input) => {
    let crc = 0xFFFFFFFF;
    const polynomial = 0x04C11DB7;
    
    for (let i = 0; i < input.length; i++) {
      crc ^= (input.charCodeAt(i) << 24);
      for (let j = 0; j < 8; j++) {
        if ((crc & 0x80000000) !== 0) {
          crc = ((crc << 1) ^ polynomial) >>> 0;
        } else {
          crc = (crc << 1) >>> 0;
        }
      }
    }
    return crc.toString(16).toUpperCase().padStart(8, '0');
  };

  const reflectByte = (val) => {
    let reflected = 0;
    for (let i = 0; i < 8; i++) {
      if ((val & (1 << i)) !== 0) {
        reflected |= (1 << (7 - i));
      }
    }
    return reflected;
  };

  const reflectBits = (val, width) => {
    let reflected = 0;
    for (let i = 0; i < width; i++) {
      if ((val & (1 << i)) !== 0) {
        reflected |= (1 << ((width - 1) - i));
      }
    }
    return reflected;
  };

  const calculateJ1939CRC32 = (input) => {
    let crc = 0xFFFFFFFF;
    const polynomial = 0x04C11DB7;
    
    for (let i = 0; i < input.length; i++) {
      const byte = reflectByte(input.charCodeAt(i));
      crc ^= (byte << 24);
      for (let j = 0; j < 8; j++) {
        if ((crc & 0x80000000) !== 0) {
          crc = ((crc << 1) ^ polynomial) >>> 0;
        } else {
          crc = (crc << 1) >>> 0;
        }
      }
    }
    return reflectBits(crc >>> 0, 32).toString(16).toUpperCase().padStart(8, '0');
  };

  const calculateJ1939CRC16 = (input) => {
    let crc = 0x0000;
    const polynomial = 0x1021;
    
    for (let i = 0; i < input.length; i++) {
      const byte = reflectByte(input.charCodeAt(i));
      crc ^= (byte << 8);
      for (let j = 0; j < 8; j++) {
        if ((crc & 0x8000) !== 0) {
          crc = ((crc << 1) ^ polynomial) & 0xFFFF;
        } else {
          crc = (crc << 1) & 0xFFFF;
        }
      }
    }
    return reflectBits(crc & 0xFFFF, 16).toString(16).toUpperCase().padStart(4, '0');
  };

  const calculateCRC16CCITT = (input) => {
    let crc = 0xFFFF;
    const polynomial = 0x1021;
    
    for (let i = 0; i < input.length; i++) {
      crc ^= (input.charCodeAt(i) << 8);
      for (let j = 0; j < 8; j++) {
        if ((crc & 0x8000) !== 0) {
          crc = ((crc << 1) ^ polynomial) & 0xFFFF;
        } else {
          crc = (crc << 1) & 0xFFFF;
        }
      }
    }
    return crc.toString(16).toUpperCase().padStart(4, '0');
  };

  const calculateCRC32Ethernet = (input) => {
    let crc = 0xFFFFFFFF;
    const polynomial = 0x04C11DB7;
    
    for (let i = 0; i < input.length; i++) {
      const byte = reflectByte(input.charCodeAt(i));
      crc ^= (byte << 24);
      for (let j = 0; j < 8; j++) {
        if ((crc & 0x80000000) !== 0) {
          crc = ((crc << 1) ^ polynomial) >>> 0;
        } else {
          crc = (crc << 1) >>> 0;
        }
      }
    }
    return reflectBits(~crc >>> 0, 32).toString(16).toUpperCase().padStart(8, '0');
  };

  useEffect(() => {
    if (input) {
      switch (crcType) {
        case 'CRC8':
          setResult(calculateCRC8(input));
          break;
        case 'CRC16':
          setResult(calculateCRC16(input));
          break;
        case 'CRC32':
          setResult(calculateCRC32(input));
          break;
        case 'J1939CRC32':
          setResult(calculateJ1939CRC32(input));
          break;
        case 'J1939CRC16':
          setResult(calculateJ1939CRC16(input));
          break;
        case 'CRC16CCITT':
          setResult(calculateCRC16CCITT(input));
          break;
        case 'CRC32ETH':
          setResult(calculateCRC32Ethernet(input));
          break;
        default:
          setResult('');
      }
    } else {
      setResult('');
    }
  }, [input, crcType]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Checksum Calculator</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mb: 2, mt: 1 }}>
          <InputLabel>CRC Type</InputLabel>
          <Select
            value={crcType}
            label="CRC Type"
            onChange={(e) => setCrcType(e.target.value)}
          >
            <MenuItem value="CRC8">AUTOSAR CRC8 (0x1D)</MenuItem>
            <MenuItem value="CRC16">AUTOSAR CRC16 (0x1021)</MenuItem>
            <MenuItem value="CRC32">AUTOSAR CRC32 (0x04C11DB7)</MenuItem>
            <MenuItem value="J1939CRC16">J1939 CRC16 (0x1021)</MenuItem>
            <MenuItem value="J1939CRC32">J1939 CRC32 (0x04C11DB7)</MenuItem>
            <MenuItem value="CRC16CCITT">CRC16-CCITT-FALSE (0x1021)</MenuItem>
            <MenuItem value="CRC32ETH">CRC32-Ethernet-JAMCRC (0x04C11DB7)</MenuItem>
          </Select>
        </FormControl>
        
        <TextField
          fullWidth
          label="Input Text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          sx={{ mb: 2 }}
        />

        <Typography variant="h6">Result:</Typography>
        <Typography variant="body1" sx={{ fontFamily: 'monospace' }}>
          {result ? `0x${result}` : 'Enter text to calculate'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};