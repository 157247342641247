// src/components/GraphWindow.js
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { Typography, Box, Button, Menu, MenuItem, Fab, CircularProgress, TextField } from '@mui/material';
// import { dataStore } from '../utils/dataStore';
import { sessionStore } from '../utils/sessionStore';
import { useParams, useOutletContext } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import UnfoldLessDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldLessDouble';

const HEADER_HEIGHT = 64; // Header height in pixels
const TABS_HEIGHT = 48;   // Tabs height in pixels
const TOTAL_OFFSET = HEADER_HEIGHT + TABS_HEIGHT;

// Helper function to format epoch to ISO string
const formatDateTime = (epoch) => {
  return new Date(epoch * 1000).toISOString();
};

function GraphWindow() {
  const { windowId } = useParams();
  const { graphWindows } = useOutletContext();
  const currentWindow = graphWindows.get(windowId);

  // Refs to track changes
  const prevWindowIdRef = useRef(windowId);
  const prevDataIdRef = useRef(null);
  const activeRequestsRef = useRef(new Set());
  // const pendingRequestsRef = useRef(new Map());

  const [signals, setSignals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [layout, setLayout] = useState({});
  const [sidebarWidth, setSidebarWidth] = useState(250);
  const [containerDimensions, setContainerDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState('name');
  const [loadingSignals, setLoadingSignals] = useState(new Set());
  const [signalsLoaded, setSignalsLoaded] = useState(false);
  const [isStacked, setIsStacked] = useState(currentWindow?.isStacked || false);
  const [searchTerm, setSearchTerm] = useState('');
  // const [pendingSignals, setPendingSignals] = useState(new Set());

  // Initialize with window-specific signals
  const [selectedSignals, setSelectedSignals] = useState(currentWindow?.selectedSignals || []);
  const [plotData, setPlotData] = useState(currentWindow?.plotData || []);

  // Get auth token from session storage in methods that need it
  const getAuthToken = () => {
    return localStorage.getItem('authToken');
  };

  // Reset states when windowId changes
  useEffect(() => {
    if (currentWindow) {
      setSelectedSignals(currentWindow.selectedSignals || []);
      setPlotData(currentWindow.plotData || []);
      setIsStacked(currentWindow.isStacked || false);
      setSignalsLoaded(false); // Force signals reload
    } else {
      // Reset states if no window found
      setSelectedSignals([]);
      setPlotData([]);
      setIsStacked(false);
      setSignalsLoaded(false);
    }
  }, [windowId, currentWindow]);

  // Combined data and signals loading effect
  useEffect(() => {
    const loadWindowData = async () => {
      // Return early if no data ID or already loaded
      if (!currentWindow?.dataId || signalsLoaded) return;

      // Check if data ID actually changed
      if (prevDataIdRef.current === currentWindow.dataId) return;

      setLoading(true);
      try {
        prevDataIdRef.current = currentWindow.dataId;

        // Restore selected signals, plotData, and stacked state from the window
        setSelectedSignals(currentWindow.selectedSignals || []);
        setPlotData(currentWindow.plotData || []);
        setIsStacked(currentWindow.isStacked || false);

        const token = getAuthToken();
        if (!token) {
          throw new Error('No authentication token found');
        }

        // Load signals only if not already cached
        let cachedSignals = sessionStore.getSignals(currentWindow.dataId);
        if (!cachedSignals) {
          console.log('Fetching signals for new dataId:', currentWindow.dataId);
          const signalsResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/offline_files/data/decoded_can_data/${currentWindow.dataId}/__signals`,
            {
              headers: { 
                'Authorization': `Bearer ${token}`
              },
            }
          );
          if (signalsResponse.data?.signals) {
            cachedSignals = signalsResponse.data.signals;
            sessionStore.setSignals(currentWindow.dataId, cachedSignals);
          }
        }

        setSignals(cachedSignals);
        setSignalsLoaded(true);

        // If the window has previously selected signals, restore them with channel+msgID
        if (currentWindow.selectedSignals?.length) {
          const restoredSignals = currentWindow.selectedSignals.map((s) => {
            if (s.includes('_')) return s;
            const signal = cachedSignals.find((sig) => sig.name === s);
            return signal ? `${signal.name}_${signal.channel}_${signal.message_id}` : s;
          });
          setSelectedSignals(restoredSignals);
        }
        setPlotData(currentWindow.plotData || []);

        // Restore stacked state
        if (currentWindow.isStacked !== undefined) {
          setIsStacked(currentWindow.isStacked);
        }
      } catch (error) {
        console.error('Error loading window data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadWindowData();
  }, [windowId, currentWindow, signalsLoaded]);

  /**
   * ------------------
   * LAYOUT CALCULATIONS
   * ------------------
   */

  // New stacked layout with a single x-axis domain (pattern = 'coupled')
  const calculateStackedLayout = (numGraphs) => {
    const availableHeight = containerDimensions.height - TOTAL_OFFSET - 10;
    const domains = Array(numGraphs).fill().map((_, i) => {
      const start = 1 - ((i + 1) / numGraphs);
      const end = 1 - (i / numGraphs);
      return [start, end];
    });

    return {
      grid: {
        rows: numGraphs,
        columns: 1,
        pattern: 'independent',
        roworder: 'top to bottom'
      },
      height: availableHeight,
      margin: { t: 25, b: 35, l: 50, r: 20 },
      showlegend: true,
      hovermode: 'x unified',
      hoverdistance: 20,
      xaxis: {
        showgrid: true,
        zeroline: true,
        showspikes: true,
        spikemode: 'across',
        spikesnap: 'cursor'
      },
      ...domains.reduce((acc, [start, end], i) => ({
        ...acc,
        [`yaxis${i + 1}`]: {
          domain: [start, end - 0.02],
          showgrid: true,
          zeroline: true
        }
      }), {})
    };
  };

  // Unstacked layout (single subplot). Keep hover/spike lines as is.
  const calculateUnstackedLayout = (numGraphs) => {
    const availableHeight = containerDimensions.height - TOTAL_OFFSET - 10;
    return {
      grid: {
        rows: 1,
        columns: 1,
        pattern: 'independent'
      },
      height: availableHeight,
      margin: { t: 25, b: 35, l: 50, r: 20 },
      showlegend: true,
      hovermode: 'x unified',
      yaxis: {
        showgrid: true,
        zeroline: true
      },
      xaxis: {
        showgrid: true,
        zeroline: true,
        showspikes: true,
        spikemode: 'across',
        spikesnap: 'cursor',
        spikethickness: 1
      }
    };
  };

  /**
   * --------------
   * SIGNAL CHECKBOX
   * --------------
   */
  const handleCheckboxChange = async (signal) => {
    const signalKey = `${signal.name}_${signal.channel}_${signal.message_id}`;
    
    // Early return if signal is already being processed
    if (loadingSignals.has(signalKey)) {
        console.log('Signal already processing:', signalKey);
        return;
    }

    try {
        // Handle removal synchronously
        if (selectedSignals.includes(signalKey)) {
            console.log('Removing signal:', signalKey);
            setSelectedSignals(prev => prev.filter(s => s !== signalKey));
            setPlotData(prev => prev.filter(trace => trace.name !== `${signal.name} (CAN ${signal.channel})`));
            
            if (window?.updateGraphWindowSignals) {
              window.updateGraphWindowSignals(windowId, 
                selectedSignals.filter(s => s !== signalKey), 
                plotData.filter(trace => trace.name !== `${signal.name} (CAN ${signal.channel})`), 
                isStacked);
            }
            
            return;
        }

        // For adding new signals
        setLoadingSignals(prev => new Set(prev).add(signalKey));

        try {
            const token = getAuthToken();
            if (!token) {
              throw new Error('No authentication token found');
            }

            const url = `${process.env.REACT_APP_API_URL}/offline_files/data/decoded_can_data/${currentWindow.dataId}/${signal.channel}/__signals/${signal.name}`;
            const response = await axios.get(url,
              {
                headers: { 
                  'Authorization': `Bearer ${token}`
                },
              }
            );
            
            if (response.data && response.data.timeseries) {
                const newTrace = {
                    type: 'scatter',
                    mode: 'lines',
                    name: `${signal.name} (CAN ${signal.channel})`,
                    x: response.data.timeseries.map(point => formatDateTime(point.timestamp)),
                    y: response.data.timeseries.map(point => {
                        const val = typeof point.value === 'object' ? 
                            point.value.value : point.value;
                        return Number(val);
                    }),
                    hoverinfo: 'x+y+name'
                };

                setSelectedSignals(prev => [...prev, signalKey]);
                setPlotData(prev => [...prev, newTrace]);
                
                if (window.updateGraphWindowSignals) {
                  window.updateGraphWindowSignals(windowId, [...selectedSignals, signalKey], [...plotData, newTrace], isStacked);
                }
            }
        } catch (error) {
            console.error('API request failed:', error);
            throw error;
        }

    } catch (error) {
        console.error('Error in handleCheckboxChange:', error);
    } finally {
        setLoadingSignals(prev => {
            const next = new Set(prev);
            next.delete(signalKey);
            return next;
        });
    }
};

  /**
   * ----------------
   * SIDEBAR & RESIZE
   * ----------------
   */
  const handleResize = (event, { size }) => {
    const newWidth = Math.min(Math.max(size.width, 200), containerDimensions.width * 0.4);
    setSidebarWidth(newWidth);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setContainerDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
      // Ensure sidebar doesn't exceed 40% of window after resize
      if (sidebarWidth > window.innerWidth * 0.4) {
        setSidebarWidth(window.innerWidth * 0.4);
      }
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [sidebarWidth]);

  useEffect(() => {
    if (plotData.length > 0) {
      // Expose current chart data for AI context (if used)
      window.currentChartData = {
        selectedSignals,
        data: plotData,
        isStacked
      };
    } else {
      window.currentChartData = null;
    }
  }, [plotData, selectedSignals, isStacked]);

  // Update layout when container dimensions change (unstacked scenario)
  useEffect(() => {
    setLayout({
      autosize: true,
      margin: { t: 25, b: 35, l: 50, r: 20 },
      xaxis: {
        automargin: true,
        showgrid: true,
        zeroline: false
      },
      yaxis: {
        automargin: true,
        showgrid: true,
        zeroline: false
      },
      showlegend: true,
      width: containerDimensions.width - sidebarWidth - 40,
      height: containerDimensions.height - 100
    });
  }, [containerDimensions, sidebarWidth]);

  /**
   * --------------
   * SORTING SIGNALS
   * --------------
   */
  const handleSortClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setAnchorEl(null);
  };

  const handleSortSelect = (sortType) => {
    setSortBy(sortType);
    handleSortClose();
  };

  const getFilteredAndSortedSignals = () => {
    return signals
      .filter(signal => 
        signal.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (signal.message_name && signal.message_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        signal.message_id?.toString(16).toUpperCase().includes(searchTerm.toUpperCase())
      )
      .sort((a, b) => {
        const signalKeyA = `${a.name}_${a.channel}_${a.message_id}`;
        const signalKeyB = `${b.name}_${b.channel}_${b.message_id}`;
        const isCheckedA = selectedSignals.includes(signalKeyA);
        const isCheckedB = selectedSignals.includes(signalKeyB);

        if (sortBy === 'checked') {
          return isCheckedB ? 1 : -1;
        }

        switch (sortBy) {
          case 'channel':
            return (a.channel || 0) - (b.channel || 0);
          case 'messageId':
            return (a.message_id || 0) - (b.message_id || 0);
          case 'messageName':
            return (a.message_name || '').localeCompare(b.message_name || '');
          case 'name':
          default:
            return a.name.localeCompare(b.name);
        }
      });
  };

  /**
   * --------------
   * STACK TOGGLE
   * --------------
   */
  const handleStackToggle = () => {
    const newStackedState = !isStacked;
    setIsStacked(newStackedState);

    if (window.updateGraphWindowSignals) {
      window.updateGraphWindowSignals(windowId, selectedSignals, plotData, newStackedState);
    }
  };

  // Recompute layout when toggling stacked and on dimension changes
  useEffect(() => {
    if (isStacked && plotData.length > 0) {
      setLayout((prevLayout) => ({
        ...prevLayout,
        ...calculateStackedLayout(plotData.length)
      }));
    }
  }, [containerDimensions, isStacked, plotData.length]);

  // Keep window store in sync
  useEffect(() => {
    if (window.updateGraphWindowSignals && currentWindow) {
      window.updateGraphWindowSignals(windowId, selectedSignals, plotData, isStacked);
    }
  }, [isStacked]);

  // Clear layout on window change and recalc
  useEffect(() => {
    if (prevWindowIdRef.current !== windowId) {
      prevWindowIdRef.current = windowId;
      setLayout({});
    }

    if (plotData.length > 0) {
      const numGraphs = plotData.length;
      const newLayout = isStacked
        ? calculateStackedLayout(numGraphs)
        : calculateUnstackedLayout(numGraphs);
      setLayout(newLayout);
    }
  }, [windowId, isStacked, plotData, containerDimensions, sidebarWidth]);

  /**
   * ---------------
   * RENDER
   * ---------------
   */
  return (
    <div
      style={{
        display: 'flex',
        height: `calc(100vh - ${TOTAL_OFFSET}px)`,
        width: '100%',
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
            padding: '20px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <CircularProgress size={48} />
          <Typography variant="body1">Loading signals...</Typography>
        </div>
      )}

      {/* Resizable sidebar */}
      <ResizableBox
        width={sidebarWidth}
        height={containerDimensions.height - TOTAL_OFFSET}
        minConstraints={[200, containerDimensions.height - TOTAL_OFFSET]}
        maxConstraints={[containerDimensions.width * 0.4, containerDimensions.height - TOTAL_OFFSET]}
        onResize={handleResize}
        axis="x"
        resizeHandles={['e']}
        handle={
          <div
            className="drag-handle"
            style={{
              width: '8px',
              height: '100%',
              position: 'absolute',
              right: '-4px',
              top: 0,
              cursor: 'col-resize',
              backgroundColor: '#e0e0e0',
              zIndex: 2
            }}
          />
        }
        style={{
          position: 'relative',
          borderRight: '1px solid #ddd',
          height: `calc(100vh - ${TOTAL_OFFSET}px)`
        }}
      >
        <div
          style={{
            height: '100%',
            width: `${sidebarWidth}px`,
            overflow: 'auto',
            padding: '16px',
            boxSizing: 'border-box'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Signals
            </Typography>
            <Button endIcon={<ArrowDropDownIcon />} onClick={handleSortClick} size="small">
              Sort by
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleSortClose}>
              <MenuItem onClick={() => handleSortSelect('name')}>Signal Name</MenuItem>
              <MenuItem onClick={() => handleSortSelect('channel')}>Channel Number</MenuItem>
              <MenuItem onClick={() => handleSortSelect('messageId')}>Message ID</MenuItem>
              <MenuItem onClick={() => handleSortSelect('messageName')}>Message Name</MenuItem>
              <MenuItem onClick={() => handleSortSelect('checked')}>Checked</MenuItem>
            </Menu>
          </Box>

          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search signals..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: 2 }}
          />

          {signals.length === 0 ? (
            <div style={{ padding: '10px', color: 'gray' }}>
              No signals available. Please ensure data is properly decoded and loaded.
            </div>
          ) : (
            getFilteredAndSortedSignals().map((signal) => {
              const signalKey = `${signal.name}_${signal.channel}_${signal.message_id}`;
              return (
                <div
                  // Create unique key using channel, name, and message_id
                  key={signalKey}
                  style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}
                >
                  <div style={{ flexGrow: 1 }}>
                    <label style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        checked={selectedSignals.includes(signalKey)}
                        onChange={() => handleCheckboxChange(signal, signalKey)}
                        style={{ marginRight: '8px' }}
                      />
                      {`${signal.name}`}
                      {loadingSignals.has(signalKey) && (
                        <CircularProgress size={16} style={{ marginLeft: '8px' }} />
                      )}
                    </label>
                    <div
                      style={{
                        marginLeft: '20px',
                        fontSize: '0.8em',
                        color: '#666',
                        fontStyle: 'italic'
                      }}
                    >
                      {`CAN ${signal.channel} :: 0x${signal.message_id
                        ?.toString(16)
                        .toUpperCase() || 'N/A'} :: ${signal.message_name || 'N/A'}`}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </ResizableBox>

      {/* Plot area */}
      <div
        style={{
          flex: 1,
          height: `calc(100vh - ${TOTAL_OFFSET}px)`,
          overflow: 'hidden'
        }}
      >
        <Fab
          onClick={handleStackToggle}
          color="primary"
          aria-label="Expand"
          style={{
            position: 'fixed',
            bottom: 'calc((100vh)/2)',
            right: 50
          }}
        >
          {isStacked ? <UnfoldLessDoubleIcon fontSize="large" /> : <UnfoldMoreDoubleIcon fontSize="large" />}
        </Fab>

        {plotData.length > 0 && (
          <Plot
            id="chartContainer"
            data={plotData.map((trace, index) => ({
              ...trace,
              xaxis: 'x',
              yaxis: isStacked ? `y${index + 1}` : 'y',
              hoverinfo: 'x+y+name'
            }))}
            layout={layout}
            style={{
              width: '100%',
              height: '100%',
              paddingLeft: '20px'
            }}
            useResizeHandler={true}
            config={{
              responsive: true,
              displayModeBar: true,
              scrollZoom: true
            }}
          />
        )}
      </div>
    </div>
  );
}

export default GraphWindow;
