// src/pages/InScopePage.js
import React, { useState, useEffect } from 'react';
import { Fab, Tabs, Tab, Dialog, IconButton, useMediaQuery, Typography, Box } from '@mui/material';
import { useNavigate, useLocation, Outlet, Link, useParams, Navigate} from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import AIHub from '../components/AIHub';
import { findNodeInGraph } from '../utils/graphUtils';
import { sessionStore } from '../utils/sessionStore';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import CalculateIcon from '@mui/icons-material/Calculate';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import { NumberConverter } from '../components/Calculators/NumberConverter';
import { Checksums } from '../components/Calculators/Checksums';
import { DTCCalculator } from '../components/Calculators/DTCCalculator';

// Import TestCasesWindow
import TestCasesWindow from '../components/TestCasesWindow';

function InScopePage({ graph }) {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  // State hooks
  const [value, setValue] = useState(location.pathname); // Set initial value to current path
  const [graphWindows, setGraphWindows] = useState(() => sessionStore.getGraphWindows());
  const [traceWindows, setTraceWindows] = useState(() => sessionStore.getTraceWindows());
  const [testCasesWindows, setTestCasesWindows] = useState(() => sessionStore.getTestCasesWindows() || new Map());
  const [showAIHub, setShowAIHub] = useState(false);
  const [converterOpen, setConverterOpen] = useState(false);
  const [checksumsOpen, setChecksumsOpen] = useState(false);
  const [dtcCalcOpen, setDtcCalcOpen] = useState(false);

  const calc_actions = [
    { icon: <SwapVerticalCircleIcon />, name: 'Hex <> Dec <> Bin' },
    { icon: <PublishedWithChangesIcon />, name: 'CheckSum Calc' },
    { icon: <EmojiTransportationIcon />, name: 'DTC Calc' }
  ];

  // Register handlers for windows
  useEffect(() => {
    // Register graph window handler
    window.registerGraphWindow = ({ id, title, dataId, selectedSignals = [], plotData = [], isStacked = false }) => {
      console.log('[InScopePage] Registering graph window:', id, title);
      setGraphWindows(prev => {
        const newMap = new Map(prev);
        newMap.set(id, { 
          title, 
          dataId,
          selectedSignals: selectedSignals || [],  // Ensure window-specific signals
          plotData: plotData || [],
          isStacked: isStacked || false
        });
        sessionStore.setGraphWindows(newMap);
        return newMap;
      });
    };

    // Register trace window handler
    window.registerTraceWindow = ({ id, title, dataId }) => {
      console.log('[InScopePage] Registering trace window:', id, title);
      setTraceWindows(prev => {
        const newMap = new Map(prev);
        newMap.set(id, { title, dataId });
        sessionStore.setTraceWindows(newMap);
        return newMap;
      });
    };

    // Register test cases window handler
    window.registerTestCasesWindow = ({ id, title, dataId, testCases = [], results = [] }) => {
      console.log('[InScopePage] Registering test cases window:', id, title);
      setTestCasesWindows(prev => {
        const newMap = new Map(prev);
        newMap.set(id, { 
          title, 
          dataId,
          testCases: testCases || [],
          results: results || []
        });
        sessionStore.setTestCasesWindows(newMap);
        return newMap;
      });
    };

    // Add handler to update signal selections
    window.updateGraphWindowSignals = (windowId, signals, plotData, isStacked) => {
      console.log('[InScopePage] Updating graph window signals:', windowId);
      setGraphWindows(prev => {
        const newMap = new Map(prev);
        const window = newMap.get(windowId);
        if (window) {
          newMap.set(windowId, {
            ...window,
            selectedSignals: signals,
            plotData: plotData,
            isStacked: isStacked
          });
          
          // Use the utility function to find the node
          const node = findNodeInGraph(graph, windowId);
          if (node && typeof node.updateSelectedSignals === 'function') {
            node.updateSelectedSignals(signals);
          }
        }
        sessionStore.setGraphWindows(newMap);
        return newMap;
      });
    };

    // Add handler to update test case results
    window.updateTestCasesResults = (windowId, results) => {
      console.log('[InScopePage] Updating test case results for window:', windowId);
      console.log('[InScopePage] Results:', results);
      
      if (!windowId || !results || !Array.isArray(results)) {
        console.error('[InScopePage] Invalid parameters for updateTestCasesResults');
        return;
      }

      setTestCasesWindows(prev => {
        const newMap = new Map(prev);
        const window = newMap.get(windowId);
        
        if (window) {
          console.log('[InScopePage] Found window, setting results');
          newMap.set(windowId, {
            ...window,
            results: results
          });
          
          // Update the node if it exists
          const node = findNodeInGraph(graph, windowId);
          if (node && typeof node.updateResults === 'function') {
            console.log('[InScopePage] Calling node.updateResults');
            node.updateResults(results);
          } else {
            console.warn('[InScopePage] Either node not found or updateResults method not available');
          }
        } else {
          console.warn('[InScopePage] Window not found:', windowId);
        }
        
        // Save to session store
        sessionStore.setTestCasesWindows(newMap);
        return newMap;
      });
    };

    return () => {
      // Clean up global handlers on unmount
      delete window.registerGraphWindow;
      delete window.registerTraceWindow;
      delete window.registerTestCasesWindow;
      delete window.updateGraphWindowSignals;
      delete window.updateTestCasesResults;
    };
  }, [graph]);

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    sessionStore.setGraphWindows(graphWindows);
  }, [graphWindows]);

  useEffect(() => {
    sessionStore.setTraceWindows(traceWindows);
  }, [traceWindows]);

  // Store test cases windows in session
  useEffect(() => {
    sessionStore.setTestCasesWindows(testCasesWindows);
  }, [testCasesWindows]);

  const handleChange = (event, newValue) => {
    setValue(newValue);  // Update local state
    navigate(newValue);  // Navigate to new route
  };

  const handleAIHubOpen = () => {
    setShowAIHub(true);
  };

  const handleAIHubClose = () => {
    setShowAIHub(false);
  };

  const handleClearWorkspace = () => {
    // Clear all window maps
    setGraphWindows(new Map());
    setTraceWindows(new Map());
    setTestCasesWindows(new Map());
    // Navigate back to workspace view if not already there
    if (location.pathname !== '/inscope/workspace') {
      navigate('/inscope/workspace');
    }
  };

  const handleCalculatorAction = (action) => {
    if (action === "Hex <> Dec <> Bin") {
      setConverterOpen(true);
    }
    if (action === "CheckSum Calc") {
      setChecksumsOpen(true);
    }
    if (action === "DTC Calc") {
      setDtcCalcOpen(true);
    }
  };

  // Redirect with default path if not in scope
  const { windowId } = useParams();

  if (location.pathname.includes('inscope/trace') && !traceWindows.has(windowId)) {
    return <Navigate to="/inscope/workspace" replace />;
  }
  
  if (location.pathname.includes('inscope/graph') && !graphWindows.has(windowId)) {
    return <Navigate to="/inscope/workspace" replace />;
  }

  // Add check for test cases routes
  if (location.pathname.includes('inscope/testcases') && !testCasesWindows.has(windowId)) {
    return <Navigate to="/inscope/workspace" replace />;
  }

  // 4. Return with conditional rendering
  return isMobile ? (
    <Box 
      sx={{ 
        p: 4, 
        textAlign: 'center',
        height: 'calc(100vh - 64px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      className="inscope" 
    >
      <Typography variant="h6" color="text.secondary">
        Mobile version is not yet supported. Please use a desktop browser to access InScope features.
      </Typography>
    </Box>
  ) : (
    <Box 
      sx={{ 
        height: 'calc(100vh - 64px)', // Account for header
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'fixed',
        top: 64,
        left: 0,
        right: 0,
        bottom: 0
      }}
      className="inscope" 
    >
      {/* Tabs Container */}
      <Box 
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: 'background.paper',
          zIndex: 1100,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs"
          centered
        >
          <Tab 
            label="Workspace" 
            value="/inscope/workspace" 
            component={Link} 
            to="/inscope/workspace" 
          />
          {/* Add dynamic tabs for each graph window */}
          {Array.from(graphWindows.entries()).map(([id, { title }]) => (
            <Tab
              key={id}
              label={title}
              value={`/inscope/graph/${id}`}
              component={Link}
              to={`/inscope/graph/${id}`}
            />
          ))}
          {/* Add dynamic tabs for trace windows */}
          {Array.from(traceWindows.entries()).map(([id, { title }]) => (
            <Tab
              key={id}
              label={title}
              value={`/inscope/trace/${id}`}
              component={Link}
              to={`/inscope/trace/${id}`}
            />
          ))}
          {/* Add dynamic tabs for test cases windows */}
          {Array.from(testCasesWindows.entries()).map(([id, { title }]) => (
            <Tab
              key={id}
              label={title}
              value={`/inscope/testcases/${id}`}
              component={Link}
              to={`/inscope/testcases/${id}`}
            />
          ))}
        </Tabs>
      </Box>

      {/* Main Content Area */}
      <Box 
        sx={{
          flex: 1,
          display: 'flex',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        {location.pathname === '/inscope/workspace' && (
          <Box 
            sx={{
              width: 250,
              flexShrink: 0,
              borderRight: 1,
              borderColor: 'divider'
            }}
          >
            <Sidebar graph={graph} onClearWorkspace={handleClearWorkspace} />
          </Box>
        )}
        
        {/* Content Area */}
        <Box 
          sx={{
            flex: 1,
            overflow: 'hidden',
            position: 'relative'
          }}
        >
          <Outlet context={{ 
            graph, 
            graphWindows, 
            traceWindows, 
            testCasesWindows,
            handleClearWorkspace 
          }} />
        </Box>
      </Box>

      {/* Floating Action Buttons */}
      <SpeedDial
        ariaLabel="Calculators"
        sx={{ position: 'fixed', bottom: 125, right: 50 }}
        icon={<CalculateIcon fontSize='large' />}
      >
        {calc_actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleCalculatorAction(action.name)}
          />
        ))}
      </SpeedDial>
      <Fab
        onClick={handleAIHubOpen}
        color="primary"
        aria-label="chat"
        style={{ 
          position: 'fixed', 
          bottom: 50, 
          right: 50 
        }}
      >
        <AutoAwesomeIcon fontSize='large'/>
      </Fab>

      {/* AIHub Dialog */}
      <Dialog
        open={showAIHub}
        onClose={handleAIHubClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            minHeight: '60vh',
            padding: '20px'
          }
        }}
      >
        <IconButton
          onClick={handleAIHubClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <AIHub graph={graph}/>
      </Dialog>

      <NumberConverter 
        open={converterOpen} 
        onClose={() => setConverterOpen(false)} 
      />

      <Checksums 
        open={checksumsOpen} 
        onClose={() => setChecksumsOpen(false)} 
      />

      <DTCCalculator
        open={dtcCalcOpen}
        onClose={() => setDtcCalcOpen(false)}
      />
    </Box>
  );
}

export default InScopePage;