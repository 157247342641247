// src/utils/dataStore.js
import { sessionStore } from './sessionStore';

export const dataStore = {
  hasDecodedData(id) {
    return !!sessionStore.getDecodedData(id);
  },
  
  hasSignals(id) {
    return !!sessionStore.getSignals(id);
  },
  
  setDecodedData(id, data) {
    sessionStore.setDecodedData(id, data);
  },
  
  getDecodedData(id) {
    return sessionStore.getDecodedData(id);
  },
  
  setSignals(id, signals) {
    sessionStore.setSignals(id, signals);
  },
  
  getSignals(id) {
    return sessionStore.getSignals(id);
  },
  
  clearData() {
    sessionStore.clearAll();
  }
};