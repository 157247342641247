// React (ResetPasswordPage.js)
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, TextField, Button, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';

function ResetPasswordPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState({message: '', severity: ''});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Extract token from query parameters e.g., ?token=...
  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setSnackbarMessage({
        message: "Passwords do not match",
        severity: "error"
      });
      setOpenSnackbar(true);
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/set-new-password`, { 
        token,
        new_password: newPassword 
      });
      setSnackbarMessage({
        message: "Password reset successfully",
        severity: "success"
      });
      setOpenSnackbar(true);
      setTimeout(() => navigate('/signin'), 2000);
    } catch (err) {
      let errorMessage = "Failed to reset password";
      // Check if the error detail is a string
      if (typeof err.response?.data?.detail === 'string') {
        errorMessage = err.response.data.detail;
      }
      setSnackbarMessage({
        message: errorMessage,
        severity: "error"
      });
      setOpenSnackbar(true);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" sx={{ my: 4 }}>
        Reset Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField 
          label="New Password" 
          type="password" 
          fullWidth 
          margin="normal" 
          value={newPassword} 
          onChange={(e) => setNewPassword(e.target.value)}
          required 
        />
        <TextField 
          label="Confirm New Password" 
          type="password" 
          fullWidth 
          margin="normal" 
          value={confirmPassword} 
          onChange={(e) => setConfirmPassword(e.target.value)}
          required 
        />
        <Button 
          variant="contained" 
          color="primary" 
          type="submit"
          fullWidth 
          sx={{ mt: 2 }}
        >
          Reset Password
        </Button>
      </form>
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert 
            elevation={6} 
            variant="filled" 
            onClose={handleClose} 
            severity={snackbarMessage.severity}
        >
            {snackbarMessage.message}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
}

export default ResetPasswordPage;