import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Button, Chip, useTheme, useMediaQuery, CircularProgress, Grid, List, ListItem, ListItemText, Paper, Divider, Table, TableBody, TableRow, TableCell } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MarkdownContent from '../components/MarkdownContent';
import axios from 'axios';
import { CartContext } from '../context/CartContext';
import { AuthContext } from '../context/AuthContext';

const ProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addToCart } = useContext(CartContext);
  const { authToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`);
        setProduct(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching product:', err);
        setError('Product not found');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchProduct();
    }
  }, [id]);

  const handleAddToCart = () => {
    if (!authToken) {
      navigate('/login');
      return;
    }

    if (product.category === 'subscription') {
      const plan = 'monthly'; // Default to monthly plan
      addToCart({
        ...product,
        type: 'subscription',
        selectedPlan: plan,
        price_id: product.prices[plan].price_id, // Set the price_id based on selected plan
        price: product.prices[plan].amount
      });
    } else {
      addToCart({
        ...product,
        type: 'hardware',
        price_id: product.price_id,
        price: product.price,
        quantity: 1
      });
    }
  };

  const handleSubscriptionClick = () => {
    navigate('/account#subscriptions');
  };

  if (loading) {
    return (
      <Container>
        <Box sx={{ mt: 10, p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !product) {
    return (
      <Container>
        <Box sx={{ mt: 10, p: 2 }}>
          <Typography color="error">{error}</Typography>
          <Button onClick={() => navigate('/products')}>Back to Products</Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: { xs: 2, sm: 3, md: 4 }, mt: { xs: 8, sm: 9, md: 10 } }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/products')}
          sx={{ mb: { xs: 2, sm: 3, md: 4 } }}
        >
          Back to Products
        </Button>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={product.image_data}
              alt={product.name}
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 1,
                boxShadow: 3
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant={isMobile ? "h4" : "h3"} component="h2" gutterBottom>
              {product.name}
            </Typography>

            <Typography variant="h5" color="text.secondary" paragraph>
              {product.description}
            </Typography>

            {product.category === 'subscription' ? (
              <Box sx={{ mb: 3 }}>
                <Typography variant="h4" gutterBottom>
                  Pricing Options
                </Typography>
                <Paper sx={{ p: 2 }}>
                  {product.prices?.monthly && (
                    <>
                      <Typography variant="h6">
                        Monthly: {product.prices.monthly.amount}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Flexible monthly billing
                      </Typography>
                    </>
                  )}
                  {product.prices?.yearly && (
                    <>
                      <Typography variant="h6">
                        Yearly: {product.prices.yearly.amount}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Save with annual billing
                      </Typography>
                    </>
                  )}
                </Paper>
              </Box>
            ) : (
              <Typography variant="h4" gutterBottom>
                {product.price}
              </Typography>
            )}

            {product.is_purchasable && (
              <Button
                variant="contained"
                size="large"
                startIcon={product.category === 'subscription' ? null : <ShoppingCartIcon />}
                onClick={product.category === 'subscription' ? handleSubscriptionClick : handleAddToCart}
                sx={{ mt: 2 }}
              >
                {product.category === 'subscription' ? 'Manage Subscription' : 'Add to Cart'}
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 4 }} />
            
            <Typography variant="h4" gutterBottom>
              Details
            </Typography>
            
            {product.detailed_description && (
              <MarkdownContent variant="default">
                {product.detailed_description}
              </MarkdownContent>
            )}

            {product.features && product.features.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h5" gutterBottom>
                  Features
                </Typography>
                <List component="ul" sx={{ listStyleType: 'disc', pl: 4 }}>
                  {product.features.map((feature, index) => (
                    <ListItem key={index} sx={{ display: 'list-item' }}>
                      <ListItemText 
                        primary={
                          <MarkdownContent variant="compact">
                            {feature}
                          </MarkdownContent>
                        } 
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            {product.specs && Object.keys(product.specs).length > 0 && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h5" gutterBottom>
                  Technical Specifications
                </Typography>
                <Table>
                  <TableBody>
                    {Object.entries(product.specs).map(([key, value], index) => (
                      <TableRow key={index}>
                        <TableCell 
                          component="th" 
                          scope="row"
                          sx={{ 
                            width: '30%',
                            backgroundColor: theme.palette.background.default
                          }}
                        >
                          <Typography variant="subtitle1" color="text.secondary">
                            {key}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <MarkdownContent variant="compact">
                            {value}
                          </MarkdownContent>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ProductPage;