// src/components/Calculators/NumberConverter.js
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { sessionStore } from '../../utils/sessionStore';

const SESSION_KEY = 'numberConverter';

export const NumberConverter = ({ open, onClose }) => {
  const [hex, setHex] = useState('');
  const [decimal, setDecimal] = useState('');
  const [binary, setBinary] = useState('');

  useEffect(() => {
    const savedValues = sessionStore.getItem(SESSION_KEY);
    if (savedValues) {
      setHex(savedValues.hex);
      setDecimal(savedValues.decimal);
      setBinary(savedValues.binary);
    }
  }, [open]);

  const saveToSession = (hex, decimal, binary) => {
    sessionStore.setItem(SESSION_KEY, { hex, decimal, binary });
  };

  const handleHexChange = (value) => {
    try {
      const dec = parseInt(value, 16);
      setHex(value);
      setDecimal(dec.toString());
      setBinary(dec.toString(2));
      saveToSession(value, dec.toString(), dec.toString(2));
    } catch (e) {}
  };

  const handleDecimalChange = (value) => {
    try {
      const dec = parseInt(value);
      setDecimal(value);
      setHex(dec.toString(16).toUpperCase());
      setBinary(dec.toString(2));
      saveToSession(dec.toString(16).toUpperCase(), value, dec.toString(2));
    } catch (e) {}
  };

  const handleBinaryChange = (value) => {
    try {
      const dec = parseInt(value, 2);
      setBinary(value);
      setHex(dec.toString(16).toUpperCase());
      setDecimal(dec.toString());
      saveToSession(dec.toString(16).toUpperCase(), dec.toString(), value);
    } catch (e) {}
  };

  const handleClear = () => {
    setHex('');
    setDecimal('');
    setBinary('');
    saveToSession('', '', '');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Number Converter</DialogTitle>
      <DialogContent>
        <TextField
          label="Hexadecimal"
          value={hex}
          onChange={(e) => handleHexChange(e.target.value)}
          fullWidth
          margin="normal"
          placeholder="Enter hex..."
        />
        <TextField
          label="Decimal"
          value={decimal}
          onChange={(e) => handleDecimalChange(e.target.value)}
          fullWidth
          margin="normal"
          placeholder="Enter decimal..."
        />
        <TextField
          label="Binary"
          value={binary}
          onChange={(e) => handleBinaryChange(e.target.value)}
          fullWidth
          margin="normal"
          placeholder="Enter binary..."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClear}>Clear</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};