// src/pages/SignUpPage.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Button, TextField, Container, Typography, Link, Alert, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { GoogleLogin } from '@react-oauth/google';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';

function SignUpPage() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, {
        username: formData.username,
        email: formData.email,
        password: formData.password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        // Create access token for the new user
        const loginResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/login`, 
          new URLSearchParams({
            username: formData.username,
            password: formData.password
          }), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        );

        // Pass the token to the login function
        login(loginResponse.data.access_token);
        navigate('/');
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'Signup failed');
      console.error('Signup failed:', err);
      setOpenSnackbar(true);
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/google`, {
        token: credentialResponse.credential
      });
      
      if (response.data) {
        // Store the access token from your backend
        login(response.data.access_token); // Update auth context
        navigate('/'); // Redirect to home page
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'Google sign in failed');
      console.error('Google sign in failed:', err);
      setOpenSnackbar(true);
    }
  };

  const handleGoogleError = () => {
    console.error('Google Signup Failed');
  };

  return (
    <Container className='signuppage' maxWidth="sm">
      <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mt: 4 }}>
        Sign Up
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      )}
      <form onSubmit={handleSubmit}>
        <TextField 
          name="username"
          label="Username" 
          value={formData.username}
          onChange={handleChange}
          fullWidth 
          margin="normal" 
          required
        />
        <TextField 
          name="email"
          label="Email" 
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth 
          margin="normal" 
          required
        />
        <TextField 
          name="password"
          label="Password" 
          type="password"
          value={formData.password}
          onChange={handleChange}
          fullWidth 
          margin="normal" 
          required
        />
        <TextField 
          name="confirmPassword"
          label="Confirm Password" 
          type="password"
          value={formData.confirmPassword}
          onChange={handleChange}
          fullWidth 
          margin="normal" 
          required
        />
        <Button 
          variant="contained" 
          color="primary" 
          type="submit" 
          fullWidth 
          sx={{ mt: 2, mb: 2 }}
        >
          Sign Up
        </Button>
      </form>

      <Typography align="center" sx={{ mt: 2, mb: 2 }}>
        - OR -
      </Typography>

      <div style={{ display: 'flex', justifyContent: 'center', margin: '16px 0' }}>
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleError}
        />
      </div>

      <Typography align="center" sx={{ mt: 2 }}>
        Already have an account?{' '}
        <Link component={RouterLink} to="/signin">
          Sign In
        </Link>
      </Typography>

      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert 
          elevation={6} 
          variant="filled" 
          onClose={handleClose} 
          severity="error"
        >
          {error}
        </MuiAlert>
      </Snackbar>

    </Container>
  );
}

export default SignUpPage;