// src/components/TestCasesWindow.js
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  Typography, Box, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, CircularProgress, Button, 
  Chip, TextField, InputAdornment, Icon, Alert
} from '@mui/material';
// Import jsPDF as a named import instead of default import
import { jsPDF } from "jspdf";
// Import the autotable plugin AFTER jsPDF
import "jspdf-autotable";
import { useParams, useOutletContext } from 'react-router-dom';
import { sessionStore } from '../utils/sessionStore';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const formatDateTime = (epoch) => {
  return new Date(epoch * 1000).toISOString();
};

function TestCasesWindow() {
  const { windowId } = useParams();
  const { testCasesWindows } = useOutletContext();
  const currentWindow = testCasesWindows.get(windowId);
  const tableRef = useRef(null);

  // State hooks
  const [testCases, setTestCases] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [evaluationComplete, setEvaluationComplete] = useState(false);
  const [error, setError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Refs to track component lifecycle and prevent race conditions
  const isMounted = useRef(true);
  const apiCallInProgress = useRef(false);
  const currentRequestId = useRef(null);  // Track the current request to handle out-of-order responses
  const initialLoadComplete = useRef(false); // Track if we've already loaded results from window data
  
  // Get auth token from session storage in methods that need it
  const getAuthToken = () => {
    const token = localStorage.getItem('authToken');
    return token;
  };

  // Track component mount status for cleanup
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Load test cases from window data
  useEffect(() => {
    if (!currentWindow) return;
    
    // Reset state if window ID changes
    if (initialLoadComplete.current && !apiCallInProgress.current) {
      initialLoadComplete.current = false;
    }
    
    // Set test cases from window data
    setTestCases(currentWindow.testCases || []);
    
    // Check if we already have results in the window data
    const windowResults = currentWindow.results || [];
    if (windowResults && windowResults.length > 0) {
      setResults(windowResults);
      setEvaluationComplete(true);
      initialLoadComplete.current = true;
    } else if (!initialLoadComplete.current) {
      setResults([]);
      setEvaluationComplete(false);
      
      // Start evaluation if we have data ID and test cases
      if (currentWindow.dataId && currentWindow.testCases?.length > 0) {
        evaluateTestCases(currentWindow.testCases);
        initialLoadComplete.current = true;
      }
    }
  }, [windowId, currentWindow]);

  // Function to evaluate test cases against CAN data using backend API
  const evaluateTestCases = async (casesToEvaluate) => {
    if (!currentWindow?.dataId || !casesToEvaluate?.length) {
      setError("Missing data ID or test cases. Cannot evaluate.");
      return;
    }
    
    if (apiCallInProgress.current) {
      return;
    }
    
    apiCallInProgress.current = true;
    const requestId = Date.now().toString(); // Generate unique ID for this request
    currentRequestId.current = requestId;
    
    setLoading(true);
    setError(null);
    setSuccessMessage(null);
    
    try {
      const token = getAuthToken();
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      const requestData = {
        decoded_data_id: currentWindow.dataId,
        test_cases: casesToEvaluate
      };
      
      // Define API URL and make request
      const apiUrl = `${process.env.REACT_APP_API_URL}/offline_files/evaluate_test_cases`;
      
      const response = await axios.post(
        apiUrl,
        requestData,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      // Check if this is still the current request and component is still mounted
      if (requestId !== currentRequestId.current || !isMounted.current) {
        return;
      }
      
      // Only process response if this component is still mounted
      if (isMounted.current) {
        setApiResponse(response.data);
        
        // Process the response - directly access the results array
        if (response.data && Array.isArray(response.data.results)) {
          const resultsArray = response.data.results;
          
          // Update local state
          setResults(resultsArray);
          setEvaluationComplete(true);
          setSuccessMessage("Test cases evaluated successfully!");
          
          // Notify the node
          if (typeof window.updateTestCasesResults === 'function') {
            window.updateTestCasesResults(windowId, resultsArray);
          }
        } else {
          throw new Error('Invalid response format from server - missing results array');
        }
      }
    } catch (error) {
      if (isMounted.current && requestId === currentRequestId.current) {
        setError(error.message || 'Failed to evaluate test cases');
      }
    } finally {
      if (isMounted.current && requestId === currentRequestId.current) {
        setLoading(false);
      }
      apiCallInProgress.current = false;
    }
  };

  // Filter test cases based on search term
  const filteredTestCases = testCases.filter(testCase => {
    if (!searchTerm) return true;
    const searchLower = searchTerm.toLowerCase();
    
    // Search in all fields
    return (
      (testCase.conditions && testCase.conditions.toLowerCase().includes(searchLower)) ||
      (testCase.expected && testCase.expected.toLowerCase().includes(searchLower)) ||
      (testCase.timing && testCase.timing.toLowerCase().includes(searchLower)) ||
      (testCase.comments && testCase.comments.toLowerCase().includes(searchLower))
    );
  });

  // Re-evaluate button handler
  const handleReEvaluate = () => {
    if (testCases.length > 0 && currentWindow?.dataId) {
      evaluateTestCases(testCases);
    }
  };

  // Export to CSV
  const handleExportCSV = () => {
    if (!results || results.length === 0) return;
    
    // Create CSV content with headers and data
    let csvContent = 'Conditions,Expected,Timing,Comments,Result,Reason\n';
    
    // Use filteredTestCases to match search criteria
    const casesToExport = searchTerm ? 
      filteredTestCases.map(testCase => {
        const result = results.find(r => r.conditions === testCase.conditions);
        return result || {
          conditions: testCase.conditions,
          expected: testCase.expected,
          timing: testCase.timing,
          comments: testCase.comments,
          result: '-',
          reason: '-'
        };
      }) : 
      results;
    
    casesToExport.forEach(row => {
      // Properly escape and quote CSV cell values
      const escapedValues = [
        `"${(row.conditions || '').replace(/"/g, '""')}"`,
        `"${(row.expected || '').replace(/"/g, '""')}"`,
        `"${(row.timing || '').replace(/"/g, '""')}"`,
        `"${(row.comments || '').replace(/"/g, '""')}"`,
        `"${(row.result || '').replace(/"/g, '""')}"`,
        `"${(row.reason || '').replace(/"/g, '""')}"`,
      ];
      
      csvContent += `${escapedValues.join(',')}\n`;
    });
    
    // Create a Blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    const fileName = `test_cases_${windowId}_${new Date().toISOString().slice(0, 10)}.csv`;
    
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Export to PDF
  const handleExportPDF = () => {
    if (!results || results.length === 0) return;
    
    try {
      // Create new document with explicit unit and format
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "letter"
      });
      
      const title = `Test Case Results - ${currentWindow?.title || 'Untitled'}`;
      const date = new Date().toLocaleDateString();
      
      // Add title and date
      doc.setFontSize(16);
      doc.text(title, 14, 20);
      doc.setFontSize(10);
      doc.text(`Generated on: ${date}`, 14, 28);
      
      // Use filteredTestCases to match search criteria when search is active
      const casesToExport = searchTerm ? 
        filteredTestCases.map(testCase => {
          const result = results.find(r => r.conditions === testCase.conditions);
          return [
            testCase.conditions || '',
            testCase.expected || '',
            testCase.timing || '',
            testCase.comments || '',
            result ? result.result : '-',
            result ? result.reason : '-'
          ];
        }) : 
        results.map(row => [
          row.conditions || '',
          row.expected || '',
          row.timing || '',
          row.comments || '',
          row.result || '',
          row.reason || ''
        ]);
      
      // Verify that autoTable is available
      if (typeof doc.autoTable !== 'function') {
        setError('PDF generation failed: autoTable plugin not loaded correctly');
        return;
      }
      
      // Create table with more explicit options
      doc.autoTable({
        startY: 35,
        head: [['Conditions', 'Expected', 'Timing', 'Comments', 'Result', 'Reason']],
        body: casesToExport,
        theme: 'striped',
        headStyles: { fillColor: [33, 150, 243] },
        styles: { 
          overflow: 'linebreak', 
          cellWidth: 'wrap',
          cellPadding: 2,
          fontSize: 8
        },
        margin: { top: 35 },
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 30 },
          2: { cellWidth: 15 },
          3: { cellWidth: 30 },
          4: { cellWidth: 20 },
          5: { cellWidth: 30 }
        }
      });
      
      // Save the PDF
      const fileName = `test_cases_${windowId}_${new Date().toISOString().slice(0, 10)}.pdf`;
      doc.save(fileName);
      
      // Show success message
      setSuccessMessage("PDF exported successfully!");
    } catch (error) {
      setError(`PDF generation failed: ${error.message}`);
    }
  };

  // Helper function to render result chip with proper color
  const renderResultChip = (result) => {
    let color = 'default';
    
    switch (result) {
      case 'PASS':
        color = 'success';
        break;
      case 'FAIL':
        color = 'error';
        break;
      case 'NOT_ENCOUNTERED':
        color = 'warning';
        break;
      case 'ERROR':
      case 'INVALID':
        color = 'error';
        break;
      case 'NOT_FOUND':
        color = 'info';
        break;
      default:
        color = 'default';
    }
    
    return <Chip label={result} color={color} size="small" />;
  };

  // Additional safety check to fix loading state if it gets stuck
  useEffect(() => {
    if (evaluationComplete && loading) {
      setLoading(false);
    }
  }, [evaluationComplete, loading]);

  // Render an error message if applicable
  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error" variant="h6">Error: {error}</Typography>
        <Button 
          variant="contained" 
          sx={{ mt: 2 }}
          onClick={handleReEvaluate}
        >
          Try Again
        </Button>
      </Box>
    );
  }

  // Render loading state if no window data
  if (!currentWindow) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading test cases data...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccessMessage(null)}>
          {successMessage}
        </Alert>
      )}
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
        <Typography variant="h5">
          Test Cases
          {loading && <CircularProgress size={20} sx={{ ml: 2 }} />}
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search test cases..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          
          <Button 
            variant="outlined" 
            onClick={handleReEvaluate}
            disabled={loading || !testCases.length}
          >
            Re-evaluate
          </Button>
          
          <Button 
            variant="outlined" 
            startIcon={<DownloadIcon />} 
            onClick={handleExportCSV} 
            disabled={!evaluationComplete || results.length === 0}
          >
            CSV
          </Button>
          
          <Button 
            variant="outlined" 
            startIcon={<PictureAsPdfIcon />} 
            onClick={handleExportPDF}
            disabled={!evaluationComplete || results.length === 0}
          >
            PDF
          </Button>
        </Box>
      </Box>
      
      <TableContainer 
        component={Paper} 
        sx={{ flex: 1, overflow: 'auto' }}
        ref={tableRef}
      >
        <Table stickyHeader aria-label="test cases table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', width: '25%' }}>Conditions</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '15%' }}>Expected</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Timing (ms)</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Comments</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Result</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!testCases || testCases.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No test cases available
                </TableCell>
              </TableRow>
            ) : loading && results.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress size={30} />
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Evaluating test cases...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              // Use filteredTestCases instead of testCases for search functionality
              filteredTestCases.map((testCase, index) => {
                // Find corresponding result by matching conditions
                const result = results.find(r => r.conditions === testCase.conditions);
                
                return (
                  <TableRow key={index} hover>
                    <TableCell 
                      sx={{ 
                        whiteSpace: 'pre-wrap', 
                        wordBreak: 'break-word',
                        fontFamily: 'monospace'
                      }}
                    >
                      {testCase.conditions}
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        whiteSpace: 'pre-wrap', 
                        wordBreak: 'break-word',
                        fontFamily: 'monospace'
                      }}
                    >
                      {testCase.expected}
                    </TableCell>
                    <TableCell>{testCase.timing}</TableCell>
                    <TableCell 
                      sx={{ 
                        whiteSpace: 'pre-wrap', 
                        wordBreak: 'break-word'
                      }}
                    >
                      {testCase.comments}
                    </TableCell>
                    <TableCell>
                      {result ? renderResultChip(result.result) : '-'}
                    </TableCell>
                    <TableCell>{result?.reason || '-'}</TableCell>
                  </TableRow>
                );
              })
            )}
            {testCases.length > 0 && filteredTestCases.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No test cases match your search
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default TestCasesWindow;