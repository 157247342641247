// src/utils/nodeRegistration.js
import { LiteGraph } from 'litegraph.js';
import ChannelFilterNode from '../components/NodeTypes/ChannelFilterNode';
import MessageIDFilterNode from '../components/NodeTypes/MessageIDFilterNode';
import DecodeCANNode from '../components/NodeTypes/DecodeCANNode';
import GraphWindowNode from '../components/NodeTypes/GraphWindowNode';
import LoadBLFNode from '../components/NodeTypes/LoadBLFNode';
import LoadASCNode from '../components/NodeTypes/LoadASCNode';
import LoadDBCNode from '../components/NodeTypes/LoadDBCNode';
import OnlineDeviceNode from '../components/NodeTypes/OnlineDeviceNode';
import SaveTraceNode from '../components/NodeTypes/SaveTraceNode';
import TraceWindowNode from '../components/NodeTypes/TraceWindowNode';
import TestCasesNode from '../components/NodeTypes/TestCasesNode';

export const registerNodes = () => {
    LiteGraph.registerNodeType('filter/messageFilter', MessageIDFilterNode);
    LiteGraph.registerNodeType('filter/channelFilter', ChannelFilterNode);
    LiteGraph.registerNodeType('can/decodeCAN', DecodeCANNode);
    LiteGraph.registerNodeType('display/graphWindow', GraphWindowNode);
    LiteGraph.registerNodeType('file/loadBLF', LoadBLFNode);
    LiteGraph.registerNodeType('file/loadASC', LoadASCNode);
    LiteGraph.registerNodeType('file/loadDBC', LoadDBCNode);
    LiteGraph.registerNodeType('data/onlineDevice', OnlineDeviceNode);
    LiteGraph.registerNodeType('file/saveTrace', SaveTraceNode);
    LiteGraph.registerNodeType('display/traceWindow', TraceWindowNode);
    LiteGraph.registerNodeType('display/testCases', TestCasesNode);
};

export default registerNodes;