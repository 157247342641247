// components/AIHub.js
import React, { useState, useRef, useEffect, useContext } from 'react';
import { 
    TextField, 
    Button, 
    Typography, 
    Box, 
    Paper,
    Container,
    CircularProgress,
    Alert,
    Avatar,
    Grid
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { fetchOpenAIResponse } from '../services/openai';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

// Message type definition
const MESSAGE_TYPE = {
    USER: 'user',
    AI: 'ai'
};

const AIHub = ({ graph }) => {
    const location = useLocation();
    const { authToken } = useContext(AuthContext);
    const [query, setQuery] = useState('');
    const [conversations, setConversations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const chatContainerRef = useRef(null);
    const [userData, setUserData] = useState(null);

    // Fetch user data to get avatar
    useEffect(() => {
        const fetchUserData = async () => {
            if (authToken) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/users/me`,
                        { 
                            withCredentials: true,
                            headers: {
                                'Authorization': `Bearer ${authToken}`
                            }
                        }
                    );
                    setUserData(response.data);
                } catch (err) {
                    console.error('Error fetching user data:', err);
                }
            }
        };

        fetchUserData();
    }, [authToken]);

    // Helper function to generate avatar color
    function stringToColor(string) {
        if (!string) return '#000000';
        
        let hash = 0;
        let i;
        
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        
        let color = '#';
        
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        
        return color;
    }

    // Generate avatar props based on user name
    function stringAvatar(name) {
        if (!name) return {};
        
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: name.split(' ').length > 1 
                ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` 
                : name[0],
        };
    }

    // Scroll to bottom of chat when new messages are added
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [conversations]);

    // Get current page from path
    const getCurrentPage = () => {
        const path = location.pathname;
        if (path.includes('workspace')) return 'Workspace';
        if (path.includes('graph')) return 'Graph';
        if (path.includes('trace')) return 'Trace';
        return 'Unknown';
    };

    const getExtraContext = (currentPage) => {
        if (currentPage === 'Workspace' && graph) {
            try {
                const graphData = graph.serialize();
                const graphDataCopy = { ...graphData };
                // Recursively remove plotData from anywhere in the object
                const removeFromJSON = (obj) => {
                    if (Array.isArray(obj)) {
                        obj.forEach(item => {
                            if (typeof item === 'object' && item !== null) {
                                removeFromJSON(item);
                            }
                        });
                    } else if (typeof obj === 'object' && obj !== null) {
                        for (const key in obj) {
                            if (key === 'plotData') {
                                delete obj[key];
                            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                                removeFromJSON(obj[key]);
                            }
                        }
                    }
                };
                removeFromJSON(graphDataCopy);
                const workspace_resp = JSON.stringify(graphDataCopy);
                return 'Current Workspace: ' + workspace_resp;
            } catch (error) {
                console.error('Error serializing graph:', error);
                return '';
            }
        } else if (currentPage === 'Graph') {
            console.log('Checking Analysis context...');
            // Remove chartContainer check since Plotly may not expose the DOM element immediately
            if (window.currentChartData) {
                const analysisContext = {
                    selectedSignals: window.currentChartData.selectedSignals || [],
                    data: window.currentChartData.data || []
                };
                console.log('Analysis context created:', analysisContext);
                return 'Current Analysis Data: ' + JSON.stringify(analysisContext);
            }
            console.log('No chart data available');
            return 'No analysis data currently plotted';
        }
        return '';
    };
    
    const handleInputChange = (e) => {
        setQuery(e.target.value);
        setError(null); // Clear any previous errors
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!query.trim()) {
            setError('Please enter a question');
            return;
        }

        setIsLoading(true);
        setError(null);
        
        // Add user message to conversation
        const userMessage = {
            type: MESSAGE_TYPE.USER,
            content: query,
            timestamp: new Date()
        };
        
        setConversations(prev => [...prev, userMessage]);
        
        // Clear input field
        const userQuery = query;
        setQuery('');
        
        try {
            let currentPage = getCurrentPage();
            let extra_context = getExtraContext(currentPage);

            console.log('Query:', userQuery, '\nCurrent Page:', currentPage, '\nExtra Context:', extra_context);
            const aiResponse = await fetchOpenAIResponse(userQuery, currentPage, extra_context);
            console.log('API Response:', aiResponse);
            
            // Extract content from response
            let messageContent;
            if (typeof aiResponse === 'object' && aiResponse.content) {
                messageContent = aiResponse.content;
            } else if (typeof aiResponse === 'string') {
                messageContent = aiResponse;
            } else {
                throw new Error('Invalid response format');
            }

            // Add AI response to conversation
            const aiMessage = {
                type: MESSAGE_TYPE.AI,
                content: messageContent,
                timestamp: new Date()
            };
            
            setConversations(prev => [...prev, aiMessage]);
        } catch (err) {
            console.error('Error:', err);
            setError(err.message || 'Failed to get response');
            
            // Add error message to conversation
            const errorMessage = {
                type: MESSAGE_TYPE.AI,
                content: `Error: ${err.message || 'Failed to get response'}`,
                timestamp: new Date(),
                isError: true
            };
            
            setConversations(prev => [...prev, errorMessage]);
        } finally {
            setIsLoading(false);
        }
    };

    // Rendering message bubbles based on type
    const renderMessage = (message, index) => {
        const isUser = message.type === MESSAGE_TYPE.USER;
        const avatarSrc = isUser ? null : '/images/avatar/revvy.png';
        
        return (
            <Box
                key={index}
                sx={{
                    display: 'flex',
                    justifyContent: isUser ? 'flex-end' : 'flex-start',
                    mb: 2
                }}
            >
                {!isUser && (
                    <Avatar 
                        src={avatarSrc} 
                        alt="AI Assistant"
                        sx={{ mr: 2, width: 40, height: 40 }}
                    />
                )}
                
                <Paper 
                    elevation={1}
                    sx={{
                        p: 2,
                        maxWidth: '70%',
                        bgcolor: isUser ? 'primary.light' : (message.isError ? 'error.light' : 'background.default'),
                        color: isUser ? 'white' : 'text.primary',
                        borderRadius: 2,
                        wordBreak: 'break-word',
                        marginRight: isUser ? 2 : 0,  // Add right margin for user messages
                        marginLeft: !isUser ? 0 : 2   // Add left margin for AI messages
                    }}
                >
                    <Typography variant="body1">
                        {message.content}
                    </Typography>
                    <Typography 
                        variant="caption" 
                        sx={{ 
                            display: 'block', 
                            mt: 1,
                            textAlign: isUser ? 'right' : 'left',
                            color: isUser ? 'rgba(255,255,255,0.7)' : 'text.secondary'
                        }}
                    >
                        {message.timestamp.toLocaleTimeString()}
                    </Typography>
                </Paper>
                
                {isUser && (
                    <Avatar 
                        sx={{ ml: 2, width: 40, height: 40 }}
                        alt={userData?.username || "User"}
                        {...(userData?.profile_picture 
                            ? { src: userData.profile_picture } 
                            : userData?.username ? stringAvatar(userData.username) : {})}
                    />
                )}
            </Box>
        );
    };

    return (
        <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', height: '100%', py: 2 }}>
            <Typography variant="h4" gutterBottom>
                Revisus AI
            </Typography>
            
            {error && !conversations.some(msg => msg.isError) && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            {/* Chat messages container */}
            <Paper 
                elevation={3} 
                ref={chatContainerRef}
                sx={{ 
                    p: 3, 
                    mb: 2, 
                    flexGrow: 1,
                    height: 'calc(100vh - 420px)',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {conversations.length === 0 ? (
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            height: '100%',
                            opacity: 0.7
                        }}
                    >
                        <Typography variant="h6" align="center" color="textSecondary">
                            Ask me anything about your data or workspace!
                        </Typography>
                        <Avatar 
                            src="/images/avatar/revvy.png" 
                            alt="AI Assistant"
                            sx={{ width: 80, height: 80, mt: 2 }}
                        />
                    </Box>
                ) : (
                    conversations.map(renderMessage)
                )}
            </Paper>

            {/* Chat input area */}
            <Paper elevation={3} sx={{ p: 2 }}>
                <Box 
                    component="form" 
                    onSubmit={handleSubmit} 
                    sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    <TextField
                        fullWidth
                        value={query}
                        onChange={handleInputChange}
                        placeholder="Type your message..."
                        variant="outlined"
                        size="medium"
                        disabled={isLoading}
                        autoFocus
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSubmit(e);
                            }
                        }}
                    />
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary"
                        disabled={isLoading || !query.trim()}
                        endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
                        sx={{ height: 56, minWidth: 100 }}
                    >
                        {isLoading ? 'Sending' : 'Send'}
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

// Add PropTypes validation
AIHub.propTypes = {
    graph: PropTypes.object
};

export default AIHub;