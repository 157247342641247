import React, { useState, useEffect } from 'react';
import { Box, Typography, Chip, Container, Grid, Card, CardMedia, CardContent, Avatar, useTheme, useMediaQuery, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  '&.MuiChip-outlined': {
    borderColor: theme.palette.primary.main,
  },
  '&.MuiChip-filled': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const LatestNewsPage = () => {
  const [selectedTag, setSelectedTag] = useState(null);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const url = selectedTag 
          ? `${process.env.REACT_APP_API_URL}/articles?tag=${selectedTag}`
          : `${process.env.REACT_APP_API_URL}/articles`;
        
        const response = await axios.get(url);
        setArticles(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching articles:', err);
        setError('Unable to load articles at this time');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [selectedTag]);

  // Get unique tags from articles safely
  const tags = [...new Set(articles?.flatMap(article => article.tags || []) || [])];

  const handleTagClick = (tag) => {
    setSelectedTag(selectedTag === tag ? null : tag);
  };

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ 
          py: { xs: 2, sm: 3, md: 4 }, 
          mt: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '50vh'
        }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ py: { xs: 2, sm: 3, md: 4 }, mt: 8 }}>
          <Typography color="error" align="center">
            {error}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: { xs: 2, sm: 3, md: 4 }, mt: 8 }}>
        <Typography 
          variant={isMobile ? "h4" : "h3"} 
          component="h1" 
          gutterBottom 
          align="center"
          sx={{ mb: { xs: 2, sm: 3 } }}
        >
          Latest News
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          flexWrap: 'wrap', 
          mb: { xs: 2, sm: 3, md: 4 },
          px: { xs: 1, sm: 0 }
        }}>
          {tags.map((tag) => (
            <StyledChip
              key={tag}
              label={tag}
              onClick={() => handleTagClick(tag)}
              variant={selectedTag === tag ? 'filled' : 'outlined'}
              size={isMobile ? "small" : "medium"}
            />
          ))}
        </Box>

        <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
          {articles.length === 0 ? (
            <Grid item xs={12}>
              <Typography align="center" color="textSecondary">
                No articles found
              </Typography>
            </Grid>
          ) : (
            articles?.map((article) => (
              <Grid item xs={12} key={article.id}>
                <Link to={`/latest/${article.id}`} style={{ textDecoration: 'none' }}>
                  <StyledCard className='article-card'>
                    <CardMedia
                      component="img"
                      sx={{
                        width: { xs: '100%', md: 300 },
                        height: { xs: 200, md: 300 },
                        objectFit: 'cover'
                      }}
                      image={article.image_data}  // Changed from image_url
                      alt={article.title}
                    />
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      flex: 1,
                      width: '100%'
                    }}>
                      <CardContent sx={{ flex: '1 0 auto', p: { xs: 2, sm: 3 } }}>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
                          {article.tags?.map((tag, idx) => (
                            <Chip 
                              key={idx}
                              label={tag} 
                              size={isMobile ? "small" : "medium"} 
                              sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                            />
                          ))}
                        </Box>
                        <Typography 
                          component="h2" 
                          variant={isMobile ? "h5" : "h4"} 
                          gutterBottom
                          sx={{ 
                            fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' },
                            mb: { xs: 1, sm: 2 }
                          }}
                        >
                          {article.title}
                        </Typography>
                        <Typography 
                          variant="subtitle1" 
                          paragraph 
                          color="text.secondary"
                          sx={{ 
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden'
                          }}
                        >
                          {article.description}
                        </Typography>
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          mt: { xs: 1, sm: 2 },
                          flexWrap: 'wrap',
                          gap: 1
                        }}>
                          {article.authors.map((author, idx) => (
                            <Box key={idx} sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              mr: 2
                            }}>
                              <Avatar 
                                src={`/images/avatar/${author.avatar}`}
                                sx={{ 
                                  mr: 1,
                                  width: { xs: 24, sm: 32 },
                                  height: { xs: 24, sm: 32 }
                                }}
                              />
                              <Typography 
                                variant="subtitle2"
                                sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                              >
                                {author.name}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </CardContent>
                    </Box>
                  </StyledCard>
                </Link>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default LatestNewsPage;