// src/components/NodeTypes/GraphWindowNode.js

function GraphWindowNode() {
  this.addInput('Decoded CAN Data', 'decoded_can_data');
  
  // Unique ID for each instance
  this.id = 'graph_' + Math.random().toString(36).substr(2, 9);
  
  this.properties = {
    isCompleted: false,
    nodeState: 'idle',
    customTitle: '',  // Add customTitle property
    title: 'Graph Window ' + this.id.split('_')[1], // Keep default title
    currentDataId: null,  // Track current data ID
    selectedSignals: [],  // Add selected signals tracking
    plotData: [], // Add plot data storage
    isStacked: false // Add stacked state storage
  };

  // Add widget for title input
  this.addWidget("text", "Window Name", this.properties.customTitle, (value) => {
    this.properties.customTitle = value;
    this.properties.title = value || ('Graph Window ' + this.id.split('_')[1]); // Use custom or default
    
    // Update window registration if exists
    if (window.registerGraphWindow && this.properties.currentDataId) {
      window.registerGraphWindow({
        id: this.id,
        title: this.properties.title,
        dataId: this.properties.currentDataId,
        selectedSignals: this.properties.selectedSignals,
        plotData: this.properties.plotData,
        isStacked: this.properties.isStacked
      });
    }
  });

  this.backgroundColorByState = {
    idle: '#ccc',
    processing: '#FFA500',
    completed: '#00FF00'
  };

  // Set initial color
  this.boxcolor = this.backgroundColorByState.idle;

  // Add debug method
  this.debugState = function() {
    console.log('Node State:', {
      id: this.id,
      selectedSignals: this.properties.selectedSignals,
      currentDataId: this.properties.currentDataId
    });
  };

  // Add state persistence method
  this.serializeState = function() {
    return {
      selectedSignals: this.properties.selectedSignals,
      plotData: this.properties.plotData,
      isStacked: this.properties.isStacked,
      currentDataId: this.properties.currentDataId
    };
  };
  
  // Add state restoration method  
  this.restoreState = function(state) {
    if (state) {
      this.properties.selectedSignals = state.selectedSignals || [];
      this.properties.plotData = state.plotData || [];
      this.properties.isStacked = state.isStacked || false;
      this.properties.currentDataId = state.currentDataId || null;
    }
  };
}

GraphWindowNode.title = 'Graph Window';
GraphWindowNode.desc = 'View the Data Graph';

// Define the node's execution logic
GraphWindowNode.prototype.onExecute = function() {
  const inputData = this.getInputData(0);
  
  // Always start with 'processing' state if there's valid input data
  if (inputData) {
    this.properties.nodeState = 'processing';
    
    // Only register if data ID changed
    if (inputData !== this.properties.currentDataId) {
      const previousState = this.serializeState();
      this.properties.currentDataId = inputData;
      
      // Register with unique ID and data
      if (window.registerGraphWindow) {
        window.registerGraphWindow({
          id: this.id,
          title: this.properties.title,
          dataId: inputData,
          selectedSignals: previousState.selectedSignals || [],
          plotData: previousState.plotData || [],
          isStacked: previousState.isStacked || false
        });
      }
    }

    // Register with unique ID and data
    if (window.registerGraphWindow) {
      window.registerGraphWindow({
        id: this.id,
        title: this.properties.title,
        dataId: inputData,
        selectedSignals: this.properties.selectedSignals || [],
        plotData: this.properties.plotData || [],
        isStacked: this.properties.isStacked || false
      });
    }
    
    // Set completion after successful processing
    this.properties.isCompleted = true;
    this.properties.nodeState = 'completed';
  } else {
    // Reset state if no input data
    this.properties.nodeState = 'idle';
    this.properties.isCompleted = false;
    this.properties.currentDataId = null;
  }
  
  this.boxcolor = this.backgroundColorByState[this.properties.nodeState];
  this.setDirtyCanvas(true);
};

// Add method to update selected signals
GraphWindowNode.prototype.updateSelectedSignals = function(signals) {
  console.log('Updating node signals:', signals);
  if (!Array.isArray(signals)) return;
  this.properties.selectedSignals = [...signals];
  this.setDirtyCanvas(true);
  this.debugState();
};

GraphWindowNode.prototype.updateWindowState = function(state) {
  Object.assign(this.properties, state);
  this.setDirtyCanvas(true);
};

export default GraphWindowNode;
