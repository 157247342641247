// src/components/OrderSuccessDialog.js
 
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box
  } from '@mui/material';
  import CheckCircleIcon from '@mui/icons-material/CheckCircle';
  
  const OrderSuccessDialog = ({ open, onClose, title, message }) => {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {title || 'Thank You!'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            py: 2 
          }}>
            <CheckCircleIcon 
              color="success" 
              sx={{ fontSize: 64, mb: 2 }} 
            />
            <Typography variant="h6" align="center">
              {message || 'Your transaction was successful.'}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
          <Button 
            variant="contained" 
            onClick={onClose}
            color="primary"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default OrderSuccessDialog;