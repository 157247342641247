import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  CircularProgress,
  FormControlLabel,
  Switch,
  Stack,
  Grid,
  Chip,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReactMarkdown from 'react-markdown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MarkdownContent from '../components/MarkdownContent';

const MarkdownHelp = () => (
  <Box sx={{ ml: 1 }}>
    <Tooltip title={
      <Box>
        <Typography variant="body2">Markdown formatting supported:</Typography>
        <Typography variant="caption" component="div">
          • # Header 1<br/>
          • ## Header 2<br/>
          • **Bold**<br/>
          • *Italic*<br/>
          • - Bullet points<br/>
          • [Link](url)<br/>
          • `code`<br/>
          • &gt; Blockquote
        </Typography>
      </Box>
    } arrow>
      <HelpOutlineIcon fontSize="small" color="action" />
    </Tooltip>
  </Box>
);

const ProductForm = ({ product, onSubmit, onClose }) => {
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState(
    product || {
      name: '',
      description: '',
      detailed_description: '',
      image_data: '',
      category: 'hardware',
      price: '',
      prices: null,
      published: false,
      is_purchasable: true,
      specs: {},
      features: []
    }
  );
  const [newFeature, setNewFeature] = useState('');
  const [newSpecKey, setNewSpecKey] = useState('');
  const [newSpecValue, setNewSpecValue] = useState('');
  const [showPreviews, setShowPreviews] = useState(false);

  const handleImageUpload = async (event) => {
    try {
      setUploading(true);
      const file = event.target.files[0];
      
      if (!file) return;

      if (!file.type.startsWith('image/')) {
        alert('Please upload an image file');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          image_data: reader.result
        }));
        setUploading(false);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Failed to process image:', error);
      setUploading(false);
    }
  };

  const handleAddFeature = () => {
    if (newFeature.trim()) {
      setFormData(prev => ({
        ...prev,
        features: [...(prev.features || []), newFeature.trim()]
      }));
      setNewFeature('');
    }
  };

  const handleRemoveFeature = (index) => {
    setFormData(prev => ({
      ...prev,
      features: prev.features.filter((_, i) => i !== index)
    }));
  };

  const handleAddSpec = () => {
    if (newSpecKey.trim() && newSpecValue.trim()) {
      setFormData(prev => ({
        ...prev,
        specs: {
          ...(prev.specs || {}),
          [newSpecKey.trim()]: newSpecValue.trim()
        }
      }));
      setNewSpecKey('');
      setNewSpecValue('');
    }
  };

  const handleRemoveSpec = (key) => {
    const newSpecs = { ...formData.specs };
    delete newSpecs[key];
    setFormData(prev => ({
      ...prev,
      specs: newSpecs
    }));
  };

  const handleCategoryChange = (category) => {
    setFormData(prev => ({
      ...prev,
      category,
      price: category === 'hardware' ? '' : null,
      prices: category === 'subscription' ? {
        monthly: { amount: '', price_id: '' },
        yearly: { amount: '', price_id: '' }
      } : null
    }));
  };

  return (
    <>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Product ID"
            fullWidth
            required
            value={formData.product_id || ''}
            onChange={(e) => setFormData({ ...formData, product_id: e.target.value })}
            helperText="Unique identifier for the product"
          />
          <TextField
            label="Name"
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1">Detailed Description</Typography>
              <MarkdownHelp />
            </Box>
            <TextField
              fullWidth
              multiline
              rows={5}
              value={formData.detailed_description || ''}
              onChange={(e) => setFormData({ ...formData, detailed_description: e.target.value })}
              placeholder="Supports markdown formatting"
            />
            {formData.detailed_description && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body2">Preview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MarkdownContent variant="preview">
                    {formData.detailed_description}
                  </MarkdownContent>
                </AccordionDetails>
              </Accordion>
            )}
          </Box>

          <Box>
            <Button
              component="label"
              variant="outlined"
              startIcon={<ImageIcon />}
              sx={{ mb: 2 }}
            >
              Upload Image
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageUpload}
              />
            </Button>
            {formData.image_data && (
              <Box>
                <img 
                  src={formData.image_data} 
                  alt="Preview" 
                  style={{ maxWidth: '200px', maxHeight: '200px' }} 
                />
              </Box>
            )}
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Category
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button 
                variant={formData.category === 'hardware' ? 'contained' : 'outlined'}
                onClick={() => handleCategoryChange('hardware')}
              >
                Hardware
              </Button>
              <Button 
                variant={formData.category === 'subscription' ? 'contained' : 'outlined'}
                onClick={() => handleCategoryChange('subscription')}
              >
                Subscription
              </Button>
            </Stack>
          </Box>

          {formData.category === 'hardware' ? (
            <>
              <TextField
                label="Price"
                fullWidth
                value={formData.price || ''}
                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                placeholder="e.g. $999.99"
              />
              <TextField
                label="Price ID (for payment processing)"
                fullWidth
                value={formData.price_id || ''}
                onChange={(e) => setFormData({ ...formData, price_id: e.target.value })}
                placeholder="e.g. price_1234567890"
              />
            </>
          ) : (
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Subscription Prices
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Monthly Amount"
                    fullWidth
                    value={formData.prices?.monthly?.amount || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      prices: {
                        ...formData.prices,
                        monthly: { ...formData.prices?.monthly, amount: e.target.value }
                      }
                    })}
                    placeholder="e.g. $99/mo"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Monthly Price ID"
                    fullWidth
                    value={formData.prices?.monthly?.price_id || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      prices: {
                        ...formData.prices,
                        monthly: { ...formData.prices?.monthly, price_id: e.target.value }
                      }
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Yearly Amount"
                    fullWidth
                    value={formData.prices?.yearly?.amount || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      prices: {
                        ...formData.prices,
                        yearly: { ...formData.prices?.yearly, amount: e.target.value }
                      }
                    })}
                    placeholder="e.g. $990/yr"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Yearly Price ID"
                    fullWidth
                    value={formData.prices?.yearly?.price_id || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      prices: {
                        ...formData.prices,
                        yearly: { ...formData.prices?.yearly, price_id: e.target.value }
                      }
                    })}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1">Features</Typography>
              <MarkdownHelp />
            </Box>
            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
              <TextField
                label="Add Feature"
                size="small"
                fullWidth
                value={newFeature}
                onChange={(e) => setNewFeature(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddFeature();
                  }
                }}
                placeholder="Supports markdown formatting"
              />
              <Button
                variant="outlined"
                onClick={handleAddFeature}
                disabled={!newFeature.trim()}
              >
                Add
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {formData.features?.map((feature, index) => (
                <Box key={index} sx={{ width: '100%' }}>
                  <Chip
                    label={feature}
                    onDelete={() => handleRemoveFeature(index)}
                    sx={{ mb: 1 }}
                  />
                  {showPreviews && (
                    <Paper sx={{ p: 1, mb: 1 }}>
                      <MarkdownContent variant="preview">
                        {feature}
                      </MarkdownContent>
                    </Paper>
                  )}
                </Box>
              ))}
            </Box>
            {formData.features?.length > 0 && (
              <Button
                size="small"
                onClick={() => setShowPreviews(!showPreviews)}
                sx={{ mt: 1 }}
              >
                {showPreviews ? 'Hide' : 'Show'} Previews
              </Button>
            )}
          </Box>

          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1">Technical Specifications</Typography>
              <MarkdownHelp />
            </Box>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={5}>
                <TextField
                  label="Specification Name"
                  fullWidth
                  size="small"
                  value={newSpecKey}
                  onChange={(e) => setNewSpecKey(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  label="Value"
                  fullWidth
                  size="small"
                  value={newSpecValue}
                  onChange={(e) => setNewSpecValue(e.target.value)}
                  placeholder="Supports markdown formatting"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  onClick={handleAddSpec}
                  disabled={!newSpecKey.trim() || !newSpecValue.trim()}
                  fullWidth
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {Object.entries(formData.specs || {}).map(([key, value]) => (
                <Box key={key} sx={{ width: '100%' }}>
                  <Chip
                    label={`${key}: ${value}`}
                    onDelete={() => handleRemoveSpec(key)}
                    sx={{ mb: 1 }}
                  />
                  {showPreviews && (
                    <Paper sx={{ p: 1, mb: 1 }}>
                      <Typography variant="subtitle2">{key}</Typography>
                      <MarkdownContent variant="preview">
                        {value}
                      </MarkdownContent>
                    </Paper>
                  )}
                </Box>
              ))}
            </Box>
          </Box>

          <FormControlLabel
            control={
              <Switch
                checked={formData.published}
                onChange={(e) => setFormData({ ...formData, published: e.target.checked })}
              />
            }
            label="Published"
          />

          <FormControlLabel
            control={
              <Switch
                checked={formData.is_purchasable}
                onChange={(e) => setFormData({ ...formData, is_purchasable: e.target.checked })}
              />
            }
            label="Purchasable"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={() => onSubmit(formData)} 
          variant="contained"
          disabled={uploading}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

const AdminProductsPage = () => {
  const { isAdmin, authToken } = useContext(AuthContext);  // Add authToken
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [error, setError] = useState(null);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products?published_only=false`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        }
      );
      setProducts(response.data);
    } catch (err) {
      setError('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isAdmin) {
      navigate('/', { replace: true });
      return;
    }

    fetchProducts();
  }, [isAdmin, navigate]);

  const handleSubmit = async (formData) => {
    try {
      if (selectedProduct) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/products/${selectedProduct.id}/`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/products/`,  // Added trailing slash
          formData,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
      }
      fetchProducts();
      setOpenDialog(false);
      setSelectedProduct(null);
    } catch (err) {
      console.error('Error saving product:', err);
      setError(err.response?.data?.detail || 'Failed to save product');  // Added better error message
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/products/${id}`,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`
            }
          }
        );
        fetchProducts();
      } catch (err) {
        console.error('Error deleting product:', err);
        setError('Failed to delete product');
      }
    }
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: { xs: 2, sm: 3, md: 4 }, mt: 8 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate('/products')}
            >
              Back to Products
            </Button>
            <Typography variant="h4">Manage Products</Typography>
          </Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setSelectedProduct(null);
              setOpenDialog(true);
            }}
          >
            New Product
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Price/Prices</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>{product.id}</TableCell>
                  <TableCell>
                    <div>{product.name}</div>
                    <Typography variant="caption" color="textSecondary">
                      ID: {product.product_id}
                    </Typography>
                  </TableCell>
                  <TableCell>{product.category}</TableCell>
                  <TableCell>
                    {product.category === 'hardware' ? (
                      <div>
                        <div>{product.price}</div>
                        <Typography variant="caption" color="textSecondary">
                          ID: {product.price_id}
                        </Typography>
                      </div>
                    ) : (
                      <Box>
                        {product.prices?.monthly && (
                          <div>
                            <div>Monthly: {product.prices.monthly.amount}</div>
                            <Typography variant="caption" color="textSecondary">
                              ID: {product.prices.monthly.price_id}
                            </Typography>
                          </div>
                        )}
                        {product.prices?.yearly && (
                          <div>
                            <div>Yearly: {product.prices.yearly.amount}</div>
                            <Typography variant="caption" color="textSecondary">
                              ID: {product.prices.yearly.price_id}
                            </Typography>
                          </div>
                        )}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label={product.published ? 'Published' : 'Draft'}
                        color={product.published ? 'success' : 'default'}
                        size="small"
                      />
                      {!product.is_purchasable && (
                        <Chip
                          label="Not Purchasable"
                          color="warning"
                          size="small"
                        />
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setSelectedProduct(product);
                        setOpenDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(product.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setSelectedProduct(null);
          }}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {selectedProduct ? 'Edit Product' : 'New Product'}
          </DialogTitle>
          <ProductForm
            product={selectedProduct}
            onSubmit={handleSubmit}
            onClose={() => {
              setOpenDialog(false);
              setSelectedProduct(null);
            }}
          />
        </Dialog>
      </Box>
    </Container>
  );
};

export default AdminProductsPage;