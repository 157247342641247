// src/components/NodeTypes/DecodeCANNode.js
import axios from 'axios';
import { dataStore } from '../../utils/dataStore';

function DecodeCANNode() {
  this.addInput('Raw CAN Data', 'raw_can_data');
  this.addInput('DBC Data', 'dbc_data');
  this.addOutput('Decoded CAN Data', 'decoded_can_data');
  this.properties = { 
    decodedDataId: null, 
    rawCanDataId: null, 
    dbcDataId: null,
    isCompleted: false,
    hasValidInputs: false,
    nodeState: 'idle' // Can be 'idle', 'processing', or 'completed'
  };
  this.title = 'Decode CAN';
  this.desc = 'Decodes CAN data using BLF and DBC inputs';

  // Add these color definitions
  this.backgroundColorByState = {
    idle: '#ccc',
    processing: '#FFA500',  // Yellow/Orange for processing
    completed: '#00FF00',   // Green for completed
    error: "#FF0000"        // Red for error
  };

  // Set initial color
  this.boxcolor = this.backgroundColorByState.idle;

  // Get auth token from session storage in methods that need it
  this.getAuthToken = function() {
    return localStorage.getItem('authToken');
  };
}

DecodeCANNode.prototype.onExecute = async function() {
  // Set processing state at start
  this.properties.nodeState = 'processing';
  this.boxcolor = this.backgroundColorByState.processing;
  this.setDirtyCanvas(true);

  console.debug('Executing DecodeCANNode');
  const rawCanDataId = this.getInputData(0);
  const dbcDataId = this.getInputData(1);

  // Input validation
  if (!rawCanDataId || !dbcDataId) {
    console.debug('Waiting for valid input data IDs');
    this.properties.hasValidInputs = false;
    this.setOutputData(0, null);
    this.properties.nodeState = 'idle';
    this.boxcolor = this.backgroundColorByState.idle;
    this.setDirtyCanvas(true);
    return;
  }

  // Check if inputs have changed
  const inputsChanged = this.properties.rawCanDataId !== rawCanDataId || 
                       this.properties.dbcDataId !== dbcDataId;

  // If we have already processed this data successfully, just return the result
  if (!inputsChanged && this.properties.decodedDataId) {
    //if (dataStore.hasSignals(this.properties.decodedDataId)) {
    this.setOutputData(0, this.properties.decodedDataId);
    this.properties.isCompleted = true;
    this.properties.nodeState = 'completed';
    this.boxcolor = this.backgroundColorByState.completed;
    this.setDirtyCanvas(true);
    return;
    // }
  }

  // Process new inputs
  if (inputsChanged) {
    console.log('DecodeCANNode Inputs changed');
    this.properties.rawCanDataId = rawCanDataId;
    this.properties.dbcDataId = dbcDataId;
    this.properties.decodedDataId = null;
    this.properties.isCompleted = false;
    this.properties.hasValidInputs = true;

    try {
      const token = this.getAuthToken();
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/offline_files/decode_can`,
        {
          raw_can_data_id: rawCanDataId,
          dbc_data_id: dbcDataId,
        },
        {
          headers: { 
            'Authorization': `Bearer ${token}`
          },
        }
      );

      // Update the response handling section in onExecute
      if (response?.data?.decoded_data_id) {
        const decodedDataId = response.data.decoded_data_id;
        this.properties.decodedDataId = decodedDataId;

        console.log('Decoded data ID:', decodedDataId);

        this.setOutputData(0, decodedDataId);
        this.properties.isCompleted = true;
        this.properties.nodeState = 'completed';
        this.boxcolor = this.backgroundColorByState.completed;
        this.setDirtyCanvas(true);
        
      } else {
        console.error('No decoded_data_id in response:', response);
        this.setOutputData(0, null);
        this.properties.nodeState = 'idle';
        this.boxcolor = this.backgroundColorByState.idle;
      }
    } catch (error) {
      console.error('DecodeCANNode Error:', error);
      this.setOutputData(0, null);
      this.properties.nodeState = 'idle';
      this.boxcolor = this.backgroundColorByState.idle;
      this.setDirtyCanvas(true);
    }
  }
};

export default DecodeCANNode;
