// src/pages/DocumentationPage.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Drawer, List, ListItemButton, ListItemText, CssBaseline, AppBar} from '@mui/material';
import remarkGfm from 'remark-gfm';
import MarkdownContent from '../components/MarkdownContent';

function DocumentationPage() {
  const drawerWidth = 240;
  const [sections, setSections] = useState([]);
  const [error, setError] = useState(null);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };


  useEffect(() => {
    fetch('/docs/inscope.md')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to load documentation');
        }
        return response.text();
      })
      .then(text => {
        const parts = text.split('# ').filter(Boolean);
        const parsedSections = parts.map(part => {
          const [title, ...contentParts] = part.split('\n');
          return {
            title: title.trim(),
            id: title.toLowerCase().replace(/[^a-z0-9]+/g, '-'),
            content: contentParts.join('\n').trim()
          };
        });
        setSections(parsedSections);
      })
      .catch(err => {
        console.error('Documentation loading error:', err);
        setError(err.message);
      });
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (error) {
    return <Box sx={{ p: 3 }}><Typography color="error">{error}</Typography></Box>;
  }

  const drawer = (
    <List>
      {sections.map((section) => (
        <ListItemButton
          key={section.id}
          onClick={() => scrollToSection(section.id)}
        >
          <ListItemText className='docs-section' primary={section.title} />
        </ListItemButton>
      ))}
    </List>

    );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
      {/* Navigation Drawer */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* Mobile Drawer */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth 
            },
          }}
        >
          {drawer}
        </Drawer>

        {/* Desktop Drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth 
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Box sx={{ mb: 2 }} /> {/* Spacing for content */}
        {sections.map((section) => (
          <Box key={section.id} id={section.id} sx={{ mb: 4 }}>
            <Typography variant="h4" gutterBottom>
              {section.title}
            </Typography>
            <MarkdownContent remarkPlugins={[remarkGfm]} className='markdown'>
              {section.content}
              </MarkdownContent>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default DocumentationPage;
