// src/components/Trace.js
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { SlickgridReact } from 'slickgrid-react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { dataStore } from '../utils/dataStore';
import { useParams, useOutletContext } from 'react-router-dom';
import axios from 'axios';

// Add after imports
const formatDateTime = (timestamp) => {
    const date = new Date(timestamp*1000);
    const microseconds = Math.floor((timestamp % 1) * 1000000).toString().padStart(6, '0');
    
    const baseFormat = date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3
    });
    
    // Replace the last 3 digits (milliseconds) with our full microseconds
    return baseFormat.slice(0, -3) + microseconds;
};

// Constants for excluded properties when showing signal details
const EXCLUDED_PROPERTIES = [
  'arbitration_id', 'channel', 'dlc', 'timestamp',
  'is_extended_id', 'is_remote_frame', 'is_error_frame',
  'is_fd', 'bitrate_switch', 'error_state_indicator',
  'message_name'
];

const HEADER_HEIGHT = 64; // Header height in pixels
const TABS_HEIGHT = 48;   // Tabs height in pixels
const TOTAL_OFFSET = HEADER_HEIGHT + TABS_HEIGHT;

function TraceWindow() {
  const { windowId } = useParams();
  const { traceWindows } = useOutletContext();
  const currentWindow = traceWindows.get(windowId);
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Start with loading true
  const [fadeLoading, setFadeLoading] = useState(true);
  const containerRef = useRef(null);
  const gridRef = useRef(null);
  // Create stable container ID
  const containerId = `trace-grid-${windowId}`.replace(/[^a-zA-Z0-9-]/g, '');

  // Add ref for previous data ID
  const prevDataIdRef = useRef(null);

  // Get auth token from session storage in methods that need it
  const getAuthToken = () => {
    return localStorage.getItem('authToken');
  };

  // Update the columns definition
  const columns = [
    {
      id: 'timestamp', 
      name: 'Time',
      field: 'timestamp',
      width: 120,
      formatter: (row, cell, value, columnDef, dataContext) => {
        if (dataContext._level === 0) {
          return value ? formatDateTime(value) : '';
        }
        return value; // For child rows (signals)
      }
    },
    {
      id: 'channel',
      name: 'Channel',
      field: 'channel',
      width: 20
    },
    {
      id: 'arbitration_id',
      name: 'Message ID',
      field: 'arbitration_id',
      width: 100,
      formatter: (row, cell, value) => value ? `0x${value.toString(16).toUpperCase().padStart(3, '0')}` : ''
    },
    {
      id: 'message_name',
      name: 'Name',
      field: 'message_name',
      width: 200
    },
    {
      id: 'raw_data',
      name: 'Data',
      field: 'raw_data',
      width: 200,
      formatter: (row, cell, value) => {
        if (!value) return '';
        if (Array.isArray(value)) {
          return value
            .map(byte => byte.toString(16).padStart(2, '0').toUpperCase())
            .join(' ');
        }
        // Handle string data - add space every 2 characters and uppercase
        return value
          .replace(/\s+/g, '') // Remove existing spaces
          .toUpperCase()
          .match(/.{1,2}/g)?.join(' ') || value;
      }
    }
  ];

  // Modify gridOptions to properly enable tree functionality
  const gridOptions = {
    enableCellNavigation: true,
    enableColumnReorder: true,
    forceFitColumns: false,
    autoHeight: false,
    rowHeight: 25,
    enableTreeData: true,
    enableFiltering: true,
    showHeaderRow: false,
    multiColumnSort: false,
    enableAutoTooltip: true,
    datasetIdPropertyName: 'id',
    defaultFilterPlaceholder: 'Filter...',
    treeDataOptions: {
      columnId: 'timestamp',
      childrenPropName: '_children',
      hasChildrenPropName: '_hasChildren',
      levelPropName: '_level',
      indentMarginLeft: 15,
      initiallyCollapsed: true
    }
  };

  // Update getTreeData function
  const getTreeData = (messages) => {
    return messages.map(msg => {
      // Create base row for the message
      const baseRow = {
        id: `${msg.timestamp}-${msg.channel}-${msg.arbitration_id}`,
        timestamp: msg.timestamp,
        channel: msg.channel,
        arbitration_id: msg.arbitration_id,
        message_name: msg.message_name || '-',
        raw_data: msg.raw_data,
        _level: 0,
        _hasChildren: msg.is_decoded && msg.signals && Object.keys(msg.signals).length > 0,
        _collapsed: true // Start collapsed
      };

      // Add children if message has signals
      if (msg.is_decoded && msg.signals) {
        const signalRows = getSignalRows(msg);
        if (signalRows.length > 0) {
          baseRow._children = signalRows;
        }
      }

      return baseRow;
    });
  };

  // Update getSignalRows function to use signals property
  const getSignalRows = (msg) => {
    if (!msg.signals) return [];
    
    return Object.entries(msg.signals).map(([key, value]) => {
      const displayValue = typeof value === 'number' ? value.toFixed(2) : value;

      return {
        id: `${msg.timestamp}-${msg.channel}-${msg.arbitration_id}-${key}`,
        timestamp: `${key}: ${displayValue}`,
        channel: '',
        arbitration_id: '',
        message_name: '',
        raw_data: '',
        _level: 1,
        _hasChildren: false,
        _parentId: `${msg.timestamp}-${msg.channel}-${msg.arbitration_id}`
      };
    });
  };

  useEffect(() => {
    const loadData = async () => {
      if (!currentWindow?.dataId || prevDataIdRef.current === currentWindow.dataId) {
        return;
      }
      
      const dataId = currentWindow.dataId;
      prevDataIdRef.current = dataId;
      
      setLoading(true);
      try {
        const token = getAuthToken();
        if (!token) {
          throw new Error('No authentication token found');
        }

        let messageData = dataStore.getDecodedData(dataId);
        
        if (!messageData) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/offline_files/data/decoded_can_data/${dataId}/__messages/latest`,
            {
              headers: { 
                'Authorization': `Bearer ${token}`
              },
            }
          );
          if (response.data?.messages) {
            messageData = response.data.messages;
            dataStore.setDecodedData(dataId, messageData);
          }
        }
        
        if (messageData) {
          const treeData = getTreeData(messageData);
          setData(treeData);
        }
      } catch (error) {
        console.error('Error loading trace data:', error);
        // Reset the ref if the request fails
        if (prevDataIdRef.current === dataId) {
          prevDataIdRef.current = null;
        }
      } finally {
        setLoading(false);
      }
    };
    
    loadData();
  }, [currentWindow?.dataId]);

  useEffect(() => {
    if (loading) {
      setFadeLoading(true);
    } else {
      const timer = setTimeout(() => {
        setFadeLoading(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  const gridConfig = useMemo(() => ({
    gridId: containerId,
    columnDefinitions: columns,
    gridOptions: gridOptions,
    dataset: data,
  }), [containerId, columns, gridOptions, data]);

  return (
    <Box sx={{ 
      height: `calc(100vh - ${TOTAL_OFFSET}px)`,
      width: '100%',
      position: 'relative',
      overflow: 'hidden'
    }}>
      {/* Loading Overlay */}
      {fadeLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            zIndex: 9999,
            opacity: loading ? 1 : 0,
            transition: 'opacity 300ms ease-in-out',
            pointerEvents: loading ? 'auto' : 'none'
          }}
        >
          <CircularProgress size={48} />
          <Typography 
            variant="h6" 
            sx={{ mt: 2 }}
            color="text.secondary"
          >
            Loading Messages...
          </Typography>
        </Box>
      )}

      <div style={{ width: '100%', height: '100%' }}>
        {data.length > 0 && (
          <SlickgridReact
            gridId={containerId}
            columnDefinitions={columns}
            gridOptions={gridOptions}
            dataset={data}
            onReactGridCreated={({ grid }) => {
              gridRef.current = grid;
            }}
          />
        )}
      </div>
    </Box>
  );
}

export default TraceWindow;