// src/pages/HomePage.js
import React, { useState } from 'react';

// @mui material components
import { Link, Stack, Box, Container, Typography, useMediaQuery} from '@mui/material';

// @mui icons
import RedditIcon from "@mui/icons-material/Reddit";
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

import ArticlesSection from '../components/HomePage/ArticlesSection';
import WhoWeAreSection from '../components/HomePage/WhoWeAreSection';
import HowInscopeWorks from '../components/HomePage/HowInscopeWorks';
import DocumentDialog from '../components/DocumentDialog';
import WhatWeDoSection from '../components/HomePage/WhatWeDoSection';

const HomePage = () => {
  const isShortDevice = useMediaQuery('(max-height: 700px)');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [docTitle, setDocTitle] = useState('');
  const [docUrl, setDocUrl] = useState('');

  const handleOpenDialog = (title, url) => {
    setDocTitle(title);
    setDocUrl(url);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* Hero Section */}
      <Box 
        sx={{
          position: 'relative',
          overflow: 'hidden',
        }}
        className='full_viewport hero-section'
      >
        <Box
          component="video"
          autoPlay
          muted
          loop
          playsInline
          src="/videos/hero_placeholder_2.mp4"
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(55, 65, 81, 0.4)',
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: 'relative',
            zIndex: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textAlign: 'center',
          }}
        >
          <Typography variant="h2" component="h1" gutterBottom className='header-titlefont'>
            revisus
          </Typography>
          <Typography variant="h5" sx={{ mb: 4 }}>
            Leading Automotive Data Analysis Beyond Tomorrow.
          </Typography>
        </Box>
      </Box>

        {/* Who We Are Section */}
        <Box
          sx={{
            background: 'linear-gradient(to bottom,rgb(33, 150, 243), rgba(255, 255, 255, 0.25))',
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          className='full_viewport'
        >
          <WhoWeAreSection />
        </Box>

      {/* What We Do Section */}
      <Box 
        sx={{ 
          py: 8, 
          background: 'rgba(255, 255, 255, 0.25)',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
          <Typography 
            variant={isShortDevice ? "h3" : "h2"}
            align="center" 
            gutterBottom 
            sx={{ mb: 4 }}
            className='header-titlefont'
            color='rgb(55, 65, 81)'
          >
            What We Do
          </Typography>
          <WhatWeDoSection />
      </Box>

      {/* Inscope Section */}
      <Box 
        sx={{ 
          py: 8, 
          background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.25), rgb(33, 150, 243))',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: isShortDevice ? 'auto' : '100vh',
        }}
      >
          <Typography 
            variant={isShortDevice ? "h3" : "h2"}
            align="center" 
            gutterBottom 
            sx={{ mb: 6 }}
            className='header-titlefont' 
            color='rgb(55, 65, 81)'
          >
            HOW INSCOPE WORKS
          </Typography>
          <HowInscopeWorks />
      </Box>

      {/* Articles Section */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 4, 
        width: '100%',
        px: { xs: 2, sm: 3, md: 4 }, 
        mx: 'auto', 
        background: 'linear-gradient(to bottom,rgb(33, 150, 243), rgb(55, 65, 81))'
      }}>
        <Box sx={{ pb: "4px" }} />
        <Typography 
            variant={isShortDevice ? "h3" : "h2"}
            align="center" 
            gutterBottom 
            sx={{ mb: 6 }}
            className='header-titlefont'
            color='rgb(255, 255, 255)'
        >
            Latest News
        </Typography>
        <ArticlesSection />
        <Box sx={{ pb: "16px" }} />
      </Box>

    {/* Footer with Social Links */}
      <Box
        component="footer"
        sx={{
          py: 4,
          px: 2,
          mt: 'auto', // pushes footer to bottom
          bgcolor: 'rgb(55, 65, 81)',
          textAlign: 'center',
          width: '100%',
          position: 'relative', // ensures footer stays in document flow
          pb: { xs: 'calc(64px + env(safe-area-inset-bottom))', sm: '64px' } // extra bottom padding for mobile
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{ mb: 2 }}
          className='social-links'
          color='rgb(255, 255, 255)'
        >
          <Link href="https://x.com/revisus" color="inherit"><XIcon /></Link>
          <Link href="https://www.reddit.com/user/hello_revisus/" color="inherit"><RedditIcon /></Link>
          <Link href="https://www.linkedin.com/company/revisus" color="inherit"><LinkedInIcon /></Link>
          <Link href="https://www.youtube.com/@hellorevisus" color="inherit"><YouTubeIcon /></Link>
        </Stack>
        <Typography variant="body2" color="rgb(255, 255, 255)">
          <Link component="button" color="rgb(255, 255, 255)" onClick={() => handleOpenDialog('Privacy Policy', '/docs/privacy_policy.md')}>
            Privacy
          </Link>
          {' | '}
          <Link component="button" color="rgb(255, 255, 255)" onClick={() => handleOpenDialog('Acceptable Use Policy', '/docs/acceptable_use_policy.md')}>
            Acceptable Use
          </Link>
          {' | '}
          <Link component="button" color="rgb(255, 255, 255)" onClick={() => handleOpenDialog('Licensing and Attributions', '/docs/licensing.md')}>
            License
          </Link>
        </Typography>
        <Typography variant="body2" color="rgb(255, 255, 255)">
          © 2025 REVISUS. All rights reserved.
        </Typography>
      </Box>
      <DocumentDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={docTitle}
        docUrl={docUrl}
      />
    </Box>
  );
}

export default HomePage;