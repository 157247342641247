// src/components/Workspace.js
import React, { useEffect, useRef, useState } from 'react';
import { LGraphCanvas } from 'litegraph.js';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Fab, CircularProgress, Box, Alert, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import 'litegraph.js/css/litegraph.css';
import { useOutletContext } from 'react-router-dom';
import { sessionStore } from '../utils/sessionStore';

function Workspace() {
  const { graph, handleClearWorkspace, handleClearWindows } = useOutletContext();
  const canvasRef = useRef(null);
  const graphCanvasRef = useRef(null);
  const [isRunning, setIsRunning] = useState(false);
  const rafIdRef = useRef(null);
  const [error, setError] = useState(null);
  const [allNodesCompleted, setAllNodesCompleted] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Add handleClose function for Snackbar
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  // Initialize canvas immediately with fixed dimensions
  useEffect(() => {
    const canvasElement = canvasRef.current;
    const parentElement = canvasElement.parentElement;

    const updateCanvasSize = () => {
      if (!parentElement || !graphCanvasRef.current) return;

      const rect = parentElement.getBoundingClientRect();
      const width = rect.width;
      const height = rect.height;
      const ratio = window.devicePixelRatio || 1;

      // Update canvas dimensions
      canvasElement.width = width * ratio;
      canvasElement.height = height * ratio;
      canvasElement.style.width = `${width}px`;
      canvasElement.style.height = `${height}px`;

      // Update graph canvas
      graphCanvasRef.current.resize(width, height);
    };

    // Initial setup
    graphCanvasRef.current = new LGraphCanvas(canvasElement, graph);
    window.graph = graph;
    updateCanvasSize();

    // Handle resize
    const handleResize = () => {
      requestAnimationFrame(updateCanvasSize);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (graphCanvasRef.current) {
        graphCanvasRef.current.graph = null;
        graphCanvasRef.current = null;
        window.graph = null;
      }
    };
  }, [graph]);

  // Save workspace state when it changes
  useEffect(() => {
    if (graph) {
      const workspaceState = graph.serialize();
      sessionStore.setWorkspace(workspaceState);
    }
  }, [graph]);

  // Restore workspace state on mount
  useEffect(() => {
    console.log('Restoring workspace state...');
    const savedState = sessionStore.getWorkspace();
    const savedGraphWindows = sessionStore.getGraphWindows();

    if (savedState && graph) {
      graph.configure(savedState);

      // Restore all nodes' properties from saved state
      graph._nodes.forEach(node => {
        switch (node.type) {
          case 'file/loadBLF':
            // Restore BLF filename to text widget
            if (node.widgets[0] && node.properties.filepath) {
              node.widgets[0].value = node.properties.filepath;
              // Update node size based on filename
              node.size[0] = node.computeNodeSize(node.properties.filepath);
            }
            break;

          case 'file/loadDBC':
            // Restore DBC files and channels
            if (node.properties.dbcList) {
              node.clearWidgets();
              node.updateWidgets();

              node.properties.dbcList.forEach((dbc, index) => {
                
                if (dbc.file) {
                  // Update filename in widget
                  const fileWidget = node.properties.fileWidgets[index];
                  if (fileWidget) {
                    fileWidget.value = dbc.filename;
                  }
                }
              });
              node.size[0] = node.computeNodeSize();
            }
            break;

          case 'display/graphWindow':
            // Restore graph window state
            const graphWindowState = savedGraphWindows.get(node.id);
            if (graphWindowState) {
              node.properties = {
                ...node.properties,
                selectedSignals: graphWindowState.selectedSignals || [],
                plotData: graphWindowState.plotData || [],
                isStacked: graphWindowState.isStacked || false
              };
              // Update window name widget
              if (node.widgets[0]) {
                node.widgets[0].value = node.properties.title;
              }
              // Call restore method if available
              if (node.restoreState) {
                node.restoreState(graphWindowState);
              }
            }
            break;

          case 'display/traceWindow':
            // Restore trace window name
            if (node.widgets[0]) {
              node.widgets[0].value = node.properties.title;
            }
            break;
            
          case 'display/testCases':
            // Restore test cases window name and state
            const testCasesWindowState = sessionStore.getTestCasesWindows().get(node.id);
            if (testCasesWindowState) {
              node.properties = {
                ...node.properties,
                testCases: testCasesWindowState.testCases || [],
                results: testCasesWindowState.results || []
              };
              // Update window name widget
              if (node.widgets[0]) {
                node.widgets[0].value = node.properties.title;
              }
              // Call restore method if available
              if (node.restoreState) {
                node.restoreState(testCasesWindowState);
              }
            }
            break;
        }
        // Ensure canvas is updated
        node.setDirtyCanvas(true);
      });
    }
  }, []);

  useEffect(() => {
    if (isRunning) {
      handleStart();
    }
  }, [isRunning]);

  const handleStart = () => {
    if (!graph) {
      console.error('Graph instance is not available');
      return;
    }

    // Check for input nodes
    const ascNode = graph._nodes.find(node => node.type === 'file/loadASC');
    const blfNode = graph._nodes.find(node => node.type === 'file/loadBLF');
    const dbcNode = graph._nodes.find(node => node.type === 'file/loadDBC');

    // Check if we have at least one input file (either BLF or ASC)
    const hasInputFile = (blfNode && blfNode.properties.filepath) || 
                        (ascNode && ascNode.properties.filepath);

    if (!hasInputFile || !dbcNode || !dbcNode.properties.dbcList?.some(dbc => dbc.file)) {
      setError('Please load both a CAN log file (BLF or ASC) and a DBC file before running the workspace');
      setIsRunning(false);
      setOpenSnackbar(true);
      return;
    }

    // Access all nodes in the graph
    const nodes = graph._nodes;

    // Find all display nodes (graph, trace, and test cases windows)
    const displayNodes = nodes.filter(node => 
      node.type === 'display/graphWindow' || 
      node.type === 'display/traceWindow' ||
      node.type === 'display/testCases'
    );

    if (displayNodes.length === 0) {
      setError('No display nodes found. Please add at least one display window.');
      setIsRunning(false);
      setOpenSnackbar(true);
      return;
    }

    // Save workspace state before starting
    sessionStore.setWorkspace(graph.serialize());

    // Clear error when all conditions are met
    setError(null);
    setIsRunning(true);

    // Function to execute graph steps recursively
    const executeGraph = () => {
      if (!isRunning) {
        return;
      }

      graph.runStep();

      // If all output nodes are completed, then set allNodesCompleted to true
      const allNodesCompleted = displayNodes.every(node => 
        node.properties.nodeState === 'completed'
      );

      if (allNodesCompleted) {
        setIsRunning(false);
        setAllNodesCompleted(true)
        setOpenSnackbar(true);
      } else {
        rafIdRef.current = requestAnimationFrame(executeGraph);
      }
    };

    // Start executing the graph
    executeGraph();
  };

  const handlePlayStop = () => {
    if (isRunning) {
      // Stop execution
      if (rafIdRef.current) {
        cancelAnimationFrame(rafIdRef.current);
        rafIdRef.current = null;
      }
      if (graph) {
        graph.stop();
        // graph.clear();
        // Reset nodes
        graph._nodes.forEach(node => {
          if (node.properties) {
            node.properties.isCompleted = false;
            node.properties.nodeState = 'idle';
          }
        });
      }
      setIsRunning(false);
      setAllNodesCompleted(true)
    } else {
      // Clear workspace before starting
      if (graph) {
        // graph.clear();
        handleClearWorkspace?.();  // Clear workspace
        handleClearWindows?.();    // Clear windows/tabs
        
        // Reset nodes and start
        // graph._nodes.forEach(node => {
        //   if (node.properties) {
        //     node.properties.isCompleted = false;
        //   }
        // });
        setIsRunning(true);
        setAllNodesCompleted(false)
      }
    }
  };

  return (
    <Box 
      className="inscope" 
      sx={{ 
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden'
      }}
    >
      {error && (
        <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MuiAlert 
            elevation={6} 
            variant="filled" 
            onClose={handleClose} 
            severity="error"
          >
            {error}
          </MuiAlert>
        </Snackbar>
      )}
      <canvas 
        ref={canvasRef}
        style={{ 
          display: 'block',
          width: '100%',
          height: '100%'
        }}
      />
      <Fab
        className="run-workspace"
        onClick={handlePlayStop}
        color={isRunning ? "error" : "primary"}
        aria-label={isRunning ? "stop" : "start"}
        style={{
          position: 'fixed',
          top: 150,
          right: 50,
          zIndex: 10,
        }}
      >
        {isRunning ? (
          <CircularProgress color="inherit" size={24} />
        ) : (
          <PlayCircleOutlineIcon fontSize='large' />
        )}
      </Fab>
      {allNodesCompleted && (
        <Snackbar 
          open={openSnackbar} 
          autoHideDuration={6000} 
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MuiAlert 
            elevation={6} 
            variant="filled" 
            onClose={handleClose} 
            severity="success"
          >
            Workspace execution completed successfully!
          </MuiAlert>
        </Snackbar>
      )}
    </Box>
  );
}

export default Workspace;
