// src/pages/AccountPage.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this import
import { AuthContext } from '../context/AuthContext';
import {
  Box,
  Avatar,
  Typography,
  Grid,
  Paper,
  Button,
  Switch,
  FormControlLabel,
  CircularProgress,
  Snackbar,
  IconButton,
  Divider,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { loadStripe } from '@stripe/stripe-js';
import INSCOPE_SUBSCRIPTION_PLANS from '../constants/subscription_plans';
import OrderSuccessDialog from '../components/OrderSuccessDialog';

const useStripeSuccess = (setOpenDialog, setMessage) => {
  useEffect(() => {
    // Check for both the success parameter and the hash
    const checkStripeSuccess = () => {
      const params = new URLSearchParams(window.location.search);
      const isSuccess = params.get('success') === 'true';
      const isSubscriptionHash = window.location.hash.includes('subscriptions');

      if (isSuccess && isSubscriptionHash) {
        setMessage('Thank you for your subscription! Your account has been upgraded.');
        setOpenDialog(true);
        // Clean up the URL but keep the hash
        const hash = window.location.hash;
        window.history.replaceState({}, document.title, window.location.pathname + hash);
      }
    };

    // Check immediately and also set up a listener for hash changes
    checkStripeSuccess();
    window.addEventListener('hashchange', checkStripeSuccess);

    return () => {
      window.removeEventListener('hashchange', checkStripeSuccess);
    };
  }, [setOpenDialog, setMessage]);
};

const SubscriptionDialog = ({ open, onClose, onSelect, selectedPlan, onSubscribe, userData }) => {
  const handlePlanSelect = (plan, interval) => {
    onSelect({
      ...plan,
      interval,
      price_id: interval === 'monthly' ? plan.prices.monthly.price_id : plan.prices.yearly.price_id,
      amount: interval === 'monthly' ? plan.prices.monthly.amount : plan.prices.yearly.amount
    });
  };

  const handleCheckout = () => {
    if (selectedPlan) {
      onSubscribe(selectedPlan.price_id);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Choose a Subscription Plan</DialogTitle>
      <DialogContent>
        {!userData?.has_had_trial && (
          <Typography 
            sx={{ mb: 2, p: 2, bgcolor: 'success.light', borderRadius: 1 }}
          >
            🎉 Try any plan free for 7 days! <br />
            Your card won't be charged until the trial ends.
          </Typography>
        )}
        <List>
          {Array.isArray(INSCOPE_SUBSCRIPTION_PLANS) && INSCOPE_SUBSCRIPTION_PLANS
            .filter(plan => plan.isPurchasable)
            .map(plan => (
              <React.Fragment key={plan.id}>
                {plan.prices?.monthly && (
                  <ListItem
                    component="button"
                    onClick={() => handlePlanSelect(plan, 'monthly')}
                    sx={{
                      border: selectedPlan?.id === plan.id && selectedPlan?.interval === 'monthly'
                        ? 2
                        : 1,
                      borderColor: selectedPlan?.id === plan.id && selectedPlan?.interval === 'monthly'
                        ? 'primary.main'
                        : 'divider',
                      borderRadius: 1,
                      mb: 1,
                      transition: 'all 0.2s ease-in-out'
                    }}
                  >
                    <ListItemText 
                      primary={`${plan.name} (Monthly)`}
                      secondary={plan.prices.monthly.amount}
                    />
                  </ListItem>
                )}
                {plan.prices?.yearly && (
                  <ListItem
                    component="button"
                    onClick={() => handlePlanSelect(plan, 'yearly')}
                    sx={{
                      border: selectedPlan?.id === plan.id && selectedPlan?.interval === 'yearly'
                        ? 2
                        : 1,
                      borderColor: selectedPlan?.id === plan.id && selectedPlan?.interval === 'yearly'
                        ? 'primary.main'
                        : 'divider',
                      borderRadius: 1,
                      mb: 1,
                      transition: 'all 0.2s ease-in-out'
                    }}
                  >
                    <ListItemText 
                      primary={`${plan.name} (Yearly)`}
                      secondary={plan.prices.yearly.amount}
                    />
                  </ListItem>
                )}
              </React.Fragment>
            ))}
        </List>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          variant="contained" 
          onClick={handleCheckout}
          disabled={!selectedPlan}
        >
          Checkout Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function AccountPage() {
  const { authToken, isAdmin } = useContext(AuthContext);  // Add isAdmin to context extraction
  const navigate = useNavigate(); // Initialize useNavigate
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState(null);
  const [avatarHover, setAvatarHover] = useState(false);
  const fileInputRef = useRef(null);
  const [openPlanDialog, setOpenPlanDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [openOrderSuccessDialog, setOpenOrderSuccessDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 150, 
        height: 150,
        fontSize: '5rem'
      },
      children: name.split(' ').length > 1 
        ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` 
        : name[0],
    };
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'Unknown';
    try {
      
      // Handle PostgreSQL timestamp format more precisely
      const cleanDate = dateString
        .replace(/(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})\..*(\+\d{2})/, '$1T$2Z');
      
      const parsedDate = parseISO(cleanDate);
      
      return format(parsedDate, 'PPP');
    } catch (err) {
      console.error('Date parsing error:', err, 'for date string:', dateString);
      return 'Unknown';
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleAvatarUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      // Check file type and size
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        setOpenSnackbar(true);
        return;
      }
      if (file.size > 5000000) { // 5MB limit
        setError('Image size should be less than 5MB');
        setOpenSnackbar(true);
        return;
      }

      const base64Image = await convertToBase64(file);
      
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/me/avatar`,
        { profile_picture: base64Image },
        { 
          headers: { 'Authorization': `Bearer ${authToken}` },
          withCredentials: true 
        }
      );

      setUserData({
        ...userData,
        profile_picture: response.data.profile_picture
      });
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update profile picture');
      setOpenSnackbar(true);
    }
  };

  const handleSettingsChange = async (e) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/me/settings`,
        { email_notifications: e.target.checked },
        { 
          headers: { 
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          withCredentials: true 
        }
      );
      
      if (response.status === 200) {
        setUserData(prev => ({
          ...prev,
          email_notifications: response.data.email_notifications
        }));
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to update settings');
      setOpenSnackbar(true);
      console.error('Settings update failed:', err);
    }
  };

  const handleChangePlan = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/subscriptions/change-plan`,
        { new_plan_id: 'your_new_price_id' }, // Replace with actual price ID
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      );
      
      // Refresh user data to show updated subscription
      window.location.reload();
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to change subscription plan');
      setOpenSnackbar(true);
    }
  };

  const handleCancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/subscriptions/cancel`,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }
        );
        
        // Refresh user data to show updated subscription status
        window.location.reload();
      } catch (err) {
        setError(err.response?.data?.detail || 'Failed to cancel subscription');
        setOpenSnackbar(true);
      }
    }
  };

  const handleSubscribe = async (planId) => {
    try {
      setIsCheckoutLoading(true); // Start loading
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscriptions/create`,
        { plan_id: planId },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      );

      if (response.data && response.data.sessionId) {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        await stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        });
      }
    } catch (err) {
      const errorMessage = err.response?.data?.detail || err.message || 'Failed to create subscription';
      setError(typeof errorMessage === 'string' ? errorMessage : 'An unexpected error occurred');
      setOpenSnackbar(true);
    } finally {
      setIsCheckoutLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/me`,
          { 
            withCredentials: true,
            headers: {
              'Authorization': `Bearer ${authToken}`
            }
          }
        );
        console.log('User data received:', response.data);
        console.log('Created at:', response.data.created_at);
        setUserData(response.data);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to load user data');
        setOpenSnackbar(true);
        console.error('Error fetching user data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [authToken]);

  useStripeSuccess(setOpenOrderSuccessDialog, setSuccessMessage);

  const handleDialogClose = () => {
    setOpenPlanDialog(false);
    // Don't reset selection when closing
  };

  if (loading) return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <CircularProgress fontSize='large'/>
    </Box>
  );
  
  return (
    <>
      {userData && (
        <Box className='account' sx={{ p: 4, maxWidth: 1200, margin: '0 auto' }}>
          {/* Profile Header */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Box
                  position="relative"
                  onMouseEnter={() => setAvatarHover(true)}
                  onMouseLeave={() => setAvatarHover(false)}
                >
                  <Avatar 
                    sx={{ width: 150, height: 150 }}
                    alt={userData.username}
                    {...(userData.profile_picture 
                      ? { src: userData.profile_picture }
                      : stringAvatar(userData.username)
                    )}
                  />
                  {avatarHover && (
                    <IconButton
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        },
                      }}
                      onClick={() => fileInputRef.current.click()}
                    >
                      <EditIcon sx={{ color: 'white' }} />
                    </IconButton>
                  )}
                  <input
                    type="file"
                    hidden
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={handleAvatarUpload}
                  />
                </Box>
              </Grid>
              <Grid item xs>
                <Typography variant="h4">{userData.username}</Typography>
                <Typography color="textSecondary">{userData.email}</Typography>
                <Typography color="textSecondary">
                  Member since: {formatDate(userData.created_at)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* Account Details */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Account Details
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography color="textSecondary">
                Last Login: {formatDate(userData.last_logged_in)}
              </Typography>
            </Box>
          </Paper>

          {/* Account Settings */}
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Account Settings
            </Typography>
            <Box sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={userData.email_notifications}
                    onChange={handleSettingsChange}
                  />
                }
                label="Email Notifications"
              />
            </Box>
          </Paper>

          {/* Subscription Management */}
          <Paper sx={{ p: 3, mt: 3 }} id="subscriptions">
            <Typography variant="h6" sx={{ mb: 2 }}>
              Subscription Management
            </Typography>
            <Box sx={{ mb: 2 }}>
              {userData.subscription ? (
                <>
                  <Typography variant="subtitle1">
                    Current Plan: {userData.subscription.name}
                  </Typography>
                  <Typography color="textSecondary">
                    Billing Period: {userData.subscription.billing_period}
                  </Typography>
                  <Typography color="textSecondary">
                    Next Payment: {formatDate(userData.subscription.next_payment)}
                  </Typography>
                  <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Button 
                      variant="contained" 
                      onClick={() => handleChangePlan()}
                    >
                      Change Plan
                    </Button>
                    <Button 
                      variant="outlined" 
                      color="error"
                      onClick={() => handleCancelSubscription()}
                    >
                      Cancel Subscription
                    </Button>
                  </Stack>
                </>
              ) : (
                <Box>
                  <Typography color="textSecondary" sx={{ mb: 2 }}>
                    Account Type: {userData.account_type}
                  </Typography>
                  <Typography color="textSecondary" sx={{ mb: 2 }}>
                    Account Valid Until: {userData.account_valid_until ? 
                      formatDate(userData.account_valid_until) : 
                      'Forever'}
                  </Typography>
                  <Button 
                    variant="contained" 
                    onClick={() => setOpenPlanDialog(true)}
                  >
                    Subscribe Now
                  </Button>
                  <SubscriptionDialog 
                    open={openPlanDialog}
                    onClose={handleDialogClose}
                    onSelect={setSelectedPlan}
                    selectedPlan={selectedPlan}
                    onSubscribe={handleSubscribe}
                    userData={userData}
                  />
                </Box>
              )}
            </Box>
          </Paper>

          {/* Admin Card - Only visible for admins */}
          {isAdmin && (
            <Paper sx={{ p: 3, mt: 3 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Admin Controls
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/admin/articles')}
                  startIcon={<EditIcon />}
                >
                  Manage Articles
                </Button>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, paddingTop: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/admin/products')}
                  startIcon={<EditIcon />}
                >
                  Manage Products
                </Button>
              </Box>
            </Paper>
          )}

        </Box>
      )}

      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert 
          elevation={6} 
          variant="filled" 
          onClose={handleClose} 
          severity="error"
        >
          {typeof error === 'string' ? error : 'An unexpected error occurred'}
        </MuiAlert>
      </Snackbar>

      <OrderSuccessDialog
        open={openOrderSuccessDialog}
        onClose={() => {
          setOpenOrderSuccessDialog(false);
          window.location.reload(); // Refresh to show updated subscription
        }}
        title="Subscription Successful!"
        message={successMessage}
      />

      {isCheckoutLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999
          }}
        >
          <CircularProgress size={60} sx={{ color: 'white' }} />
        </Box>
      )}
    </>
  );
}

export default AccountPage;