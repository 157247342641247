// src/components/CartDialog.js
import React, { useContext, useState } from 'react';
import { CartContext } from '../context/CartContext';
import { 
  Typography, Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, List, ListItem, ListItemText, ListItemSecondaryAction, 
  IconButton, RadioGroup, Radio, FormControlLabel, Box, Switch, Stack, TextField, CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { styled } from '@mui/material/styles';
import OrderSuccessDialog from './OrderSuccessDialog';

// Initialize Stripe outside component to avoid recreating
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Add helper function at the top of the component
const createUniqueId = (item) => `${item.type || 'hardware'}_${item.id}`;

// Update the BillingSwitch styled component
const BillingSwitch = styled(Switch)(({ theme }) => ({
  width: 90,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      transform: 'translateX(48px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    position: 'relative',
    '&::after': {
      content: item => item.checked ? '"Y"' : '"M"',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 14,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: 20,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#E9E9EA',
    opacity: 1
  }
}));

function CartDialog({ open, onClose }) {
  const { 
    cartItems, 
    removeFromCart, 
    updateCartItem, 
    clearCart, 
    updateQuantity,
    addToCart  // Add this to the destructuring
  } = useContext(CartContext);
  const [openOrderSuccessDialog, setOpenOrderSuccessDialog] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);

  const handleCheckout = async () => {
    try {
      setIsCheckoutLoading(true);
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No authentication token found');
        return;
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      };

      // Format the items according to the backend's CheckoutItem schema
      const items = cartItems.map(item => {
        // For subscription items, get the price_id based on selected plan
        const price = item.type === 'subscription' && item.selectedPlan 
          ? item.prices[item.selectedPlan].price_id
          : item.price_id;
          
        return {
          price,
          quantity: parseInt(item.quantity || 1)
        };
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/cart/create-checkout-session`,
        {
          items,
          mode: 'payment'
        },
        config
      );

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      });

    } catch (error) {
      console.error('Checkout error:', error);
    } finally {
      setIsCheckoutLoading(false);
    }
  };

  const handlePlanChange = (itemId, plan) => {
    const item = cartItems.find(i => i.id === itemId);
    if (item) {
      updateCartItem(itemId, { 
        ...item, 
        selectedPlan: plan,
        price_id: item.prices[plan].price_id,
        price: item.prices[plan].amount
      });
    }
  };

  const handleAddToCart = (product) => {
    const subscription = {
      id: product.id,
      type: 'subscription',  // <-- Add this property
      name: product.name,
      description: product.description,
      prices: product.prices,
      selectedPlan: 'monthly',
      price_id: product.prices.monthly.price_id,
      price: product.prices.monthly.amount
    };
    addToCart(subscription);
  };

  const handleQuantityChange = (item, newQuantity) => {
    updateQuantity(item.id, newQuantity, item.selectedPlan);
  };

  const calculateTotal = (items) => {
    return items.reduce((total, item) => {
      try {
        if (item.type === 'subscription' && item.prices && item.selectedPlan) {
          const amount = item.prices[item.selectedPlan].amount;
          if (amount) {
            const priceMatch = amount.match(/\$(\d+)/);
            if (priceMatch && priceMatch[1]) {
              return total + (parseFloat(priceMatch[1]) * (item.quantity || 1));
            }
          }
        } else if (item.price) {
          const priceMatch = item.price.match(/\$(\d+)/);
          if (priceMatch && priceMatch[1]) {
            return total + (parseFloat(priceMatch[1]) * (item.quantity || 1));
          }
        }
        return total;
      } catch (error) {
        console.error('Error calculating price for item:', item, error);
        return total;
      }
    }, 0);
  };

  const renderCartItem = (item) => {
    if (item.type === 'subscription') {
      return (
        <Box sx={{ 
          p: 2, 
          borderBottom: 1, 
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between', // Changed from flex-start
          alignItems: 'center',            // Added for vertical centering
          gap: 4                          // Added more gap between elements
        }}>
          <Box sx={{ flex: 1 }}>         // Added flex: 1 to allow text wrapping
            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
              {item.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {item.description}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 4 }}> // Added ml: 4 for left margin
            <TextField
              type="number"
              value={item.quantity || 1}
              onChange={(e) => handleQuantityChange(item, parseInt(e.target.value))}
              InputProps={{
                inputProps: { min: 1 },
                sx: { width: '70px' }
              }}
              size="small"
            />
            <IconButton onClick={() => removeFromCart(item.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ 
          p: 2, 
          borderBottom: 1, 
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 4                         // Added more gap between elements
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
              {item.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {item.price}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 4 }}>
            <TextField
              type="number"
              value={item.quantity || 1}
              onChange={(e) => handleQuantityChange(item, parseInt(e.target.value))}
              InputProps={{
                inputProps: { min: 1 },
                sx: { width: '70px' }
              }}
              size="small"
            />
            <IconButton onClick={() => removeFromCart(item.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      );
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ m: 0, p: 2 }}>Shopping Cart</DialogTitle>
        <DialogContent sx={{ position: 'relative', minHeight: '200px' }}>
          {isCheckoutLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 1
              }}
            >
              <CircularProgress size={60} />
            </Box>
          )}
          {cartItems.length === 0 ? (
            <Typography variant="body1" sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
              Your cart is empty
            </Typography>
          ) : (
            <>
              <List>
                {cartItems.map(renderCartItem)}
              </List>
              <Box sx={{ 
                borderTop: 1, 
                borderColor: 'divider',
                pt: 2,
                mt: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Typography variant="subtitle1">
                  Total:
                </Typography>
                <Typography variant="h6">
                  ${calculateTotal(cartItems).toFixed(2)}
                </Typography>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          {cartItems.length > 0 && (
            <Button 
              onClick={handleCheckout} 
              variant="contained" 
              color="primary"
              disabled={cartItems.some(item => item.prices && !item.selectedPlan)}
            >
              Checkout
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <OrderSuccessDialog
        open={openOrderSuccessDialog}
        onClose={() => {
          setOpenOrderSuccessDialog(false);
          onClose(); // Close the cart dialog
        }}
        title="Purchase Successful!"
        message="Thank you for your purchase! Your order has been confirmed."
      />
      {isCheckoutLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999999 // Increased z-index to be higher than MUI Dialog
          }}
        >
          <CircularProgress size={60} sx={{ color: 'white' }} />
        </Box>
      )}
    </>
  );
}

export default CartDialog;