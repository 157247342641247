// src/components/NodeTypes/TraceWindowNode.js

function TraceWindowNode() {
  this.addInput('Decoded CAN Data', 'decoded_can_data');
  
  // Unique ID for each instance
  this.id = 'trace_' + Math.random().toString(36).substr(2, 9);
  
  this.backgroundColorByState = {
    idle: '#ccc',
    processing: '#FFA500',
    completed: '#00FF00'
  };

  this.properties = {
    isCompleted: false,
    nodeState: 'idle',
    customTitle: '',  // Add customTitle property
    title: 'Trace Window ' + this.id.split('_')[1], // Keep default title
    currentDataId: null  // Track current data ID
  };

  // Add widget for title input
  this.addWidget("text", "Window Name", this.properties.customTitle, (value) => {
    this.properties.customTitle = value;
    this.properties.title = value || ('Trace Window ' + this.id.split('_')[1]); // Use custom or default
    
    // Update window registration if exists
    if (window.registerTraceWindow && this.properties.currentDataId) {
      window.registerTraceWindow({
        id: this.id,
        title: this.properties.title,
        dataId: this.properties.currentDataId
      });
    }
  });

  // Set initial color
  this.boxcolor = this.backgroundColorByState.idle;
}

TraceWindowNode.title = 'Trace Window';
TraceWindowNode.desc = 'View the Data Trace';

TraceWindowNode.prototype.onExecute = function() {
  const inputData = this.getInputData(0);
  
  // Always start with 'processing' state if there's valid input data
  if (inputData) {
    this.properties.nodeState = 'processing';
    
    // Only register if data ID changed
    if (inputData !== this.properties.currentDataId) {
      this.properties.currentDataId = inputData;
      
      // Register with unique ID and data
      if (window.registerTraceWindow) {
        window.registerTraceWindow({
          id: this.id,
          title: this.properties.title,
          dataId: inputData
        });
      }
    }
    
    // Set completion after successful processing
    this.properties.isCompleted = true;
    this.properties.nodeState = 'completed';
  } else {
    // Reset state if no input data
    this.properties.nodeState = 'idle';
    this.properties.isCompleted = false;
    this.properties.currentDataId = null;
  }
  
  this.boxcolor = this.backgroundColorByState[this.properties.nodeState];
  this.setDirtyCanvas(true);
};

TraceWindowNode.prototype.updateWindowState = function(state) {
  Object.assign(this.properties, state);
  this.setDirtyCanvas(true);
};

export default TraceWindowNode;
