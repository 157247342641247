// src/pages/WhoWeAreSection.js
import React from 'react';

// @mui material components
import { Box, Typography, IconButton } from '@mui/material';

// @mui icons
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



const who_we_are_slides = [
  {
    text: 'We are Revisus, a provider of cost-effective, user friendly analysis tools for software and hardware, designed to meet the highest standards of quality and performance.'
  },
  {
    text: 'Revisus empowers success with innovative software and hardware solutions. We help business unlock insights, optimize operations, and stay ahead in a dynamic world.'
  },
  {
    text: 'Revisus is your trusted partner in innovation, crafting solutions that solve challenges of today and shape opportunities for tomorrow.'
  }
];

const WhoWeAreSection = () => {
    const [currentSlide, setCurrentSlide] = React.useState(0);

    const handlePrevSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? who_we_are_slides.length - 1 : prev - 1));
    };

    const handleNextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % who_we_are_slides.length);
    };

    return (
        <Box
            sx={{
                maxWidth: '1000px',
                width: '100%',
                px: 4,
                display: 'flex',
                gap: 8,
                justifyContent: 'center',
                alignItems: { xs: 'center', md: 'flex-start' },
                flexDirection: { xs: 'column', md: 'row' }
            }}
            className="who-we-are-section"
        >
            <Box
                className="who-we-are-title"
                sx={{
                    width: { xs: '100%', md: 'auto' },
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    variant="h2"
                    className="header-titlefont"
                    sx={{
                        flex: '0 0 auto',
                        minWidth: '300px',
                        textAlign: 'center'
                    }}
                >
                    revisus
                </Typography>
            </Box>

            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: { xs: 'center', md: 'flex-start' }, // Center everything on mobile
                    maxWidth: '400px',
                    width: '100%'
                }}
                className="who-we-are-slides-nav"
            >
                <Typography 
                    variant="body1" 
                    sx={{ 
                        mb: 4,
                        textAlign: { xs: 'center', md: 'left' }, // Center text on mobile
                        minHeight: '10px',
                        maxWidth: '400px',
                        width: '100%'
                    }}
                >
                    {who_we_are_slides[currentSlide].text}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        width: '100%',
                        justifyContent: { xs: 'center', md: 'flex-start' } // Center nav on mobile
                    }}
                >
                    <IconButton color="inherit" onClick={handlePrevSlide}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <IconButton color="inherit" onClick={handleNextSlide}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}

export default WhoWeAreSection;