// src/pages/SignInPage.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this import
import { AuthContext } from '../context/AuthContext';
import { Button, TextField, Container, Typography, Link, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { GoogleLogin } from '@react-oauth/google';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
// import { set } from 'date-fns';

function SignInPage() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState({message: '', severity: ''});
  const [resetEmail, setResetEmail] = useState('');
  // const [resetStatus, setResetStatus] = useState({message: '', severity: ''});
  const [openDialog, setOpenDialog] = useState(false);
  const [tempEmail, setTempEmail] = useState('');

  const { login } = useContext(AuthContext);
  const navigate = useNavigate(); 

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const formData = new FormData(event.target);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, 
        new URLSearchParams({
          username: formData.get('username'),
          password: formData.get('password')
        }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        withCredentials: true
      });

      if (response.data) {
        login(response.data.access_token);
        navigate('/inscope/workspace');
      }
    } catch (err) {
      console.error('Login failed:', err);
      setSnackbarMessage({
        message: err.response?.data?.detail || 'Login failed', 
        severity: 'error'
      });
      setOpenSnackbar(true);
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      console.log('Google credential received:', credentialResponse);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/google`,
        { token: credentialResponse.credential },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data && response.data.access_token) {
        login(response.data.access_token); // Pass the token to login function
        navigate('/');
      }
    } catch (err) {
      console.error('Google Login Failed:', err.response?.data);
      setSnackbarMessage({
        message: err.response?.data?.detail || 'Google login failed', 
        severity: 'error'
      });
      setOpenSnackbar(true);
    }
  };

  const handleGoogleError = () => {
    console.error('Google Login Failed');
    setSnackbarMessage({
      message: 'Google login failed', 
      severity: 'error'
    });
    setOpenSnackbar(true);
  };

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, { 
        email_data: { 
          email: tempEmail  // Changed from resetEmail to tempEmail
        }
      });
      setSnackbarMessage({
        message: 'Password reset link sent',
        severity: 'success'
      });
      setOpenSnackbar(true);
      handleDialogClose();
    } catch (err) {
      let errorMessage = 'Failed to send reset link';
      if (typeof err.response?.data?.detail === 'string') {
        errorMessage = err.response.data.detail;
      } else if (err.response?.data?.detail?.msg) {
        errorMessage = err.response.data.detail.msg;
      }
      setSnackbarMessage({
        message: errorMessage,
        severity: 'error'
      });
      setOpenSnackbar(true);
    }
  };

  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setTempEmail('');
  };

  const handleSubmitReset = () => {
    if (!tempEmail) {
      setSnackbarMessage({
        message: 'Please enter an email address',
        severity: 'error'
      });
      setOpenSnackbar(true);
      return;
    }
  
    setResetEmail(tempEmail);
    handleForgotPassword();
    handleDialogClose();
  };

  return (
    <Container className="signinpage" maxWidth="sm">
      <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mt: 4 }}>
        Sign In
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField 
          name="username"
          label="Username" 
          fullWidth 
          margin="normal" 
          required 
        />
        <TextField 
          name="password"
          label="Password" 
          type="password" 
          fullWidth 
          margin="normal" 
          required
        />
        <Button 
          variant="contained" 
          color="primary" 
          type="submit" 
          fullWidth 
          sx={{ mt: 2, mb: 2 }}
        >
          Sign In
        </Button>
      </form>

      <Typography align="center" sx={{ mt: 2, mb: 2 }}>
        - OR -
      </Typography>

      <div style={{ display: 'flex', justifyContent: 'center', margin: '16px 0' }}>
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleError}
          useOneTap={false}
          cookiePolicy={'single_host_origin'}
          isSignedIn={false}
        />
      </div>

      <Typography align="center" sx={{ mt: 2 }}>
        Don't have an account?{' '}
        <Link component={RouterLink} to="/signup">
          Sign Up
        </Link>
      </Typography>
      <Typography align="center" sx={{ mt: 2 }}>
        <Link
          component="button"
          variant="body2"
          onClick={handleDialogOpen}
        >
          Forgot Password?
        </Link>
      </Typography>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <Typography align="center" sx={{ mt: 2 }}>
            Please enter your email address and we'll send you a link to reset your password.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={tempEmail}
            onChange={(e) => setTempEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSubmitReset}>Submit</Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert 
          elevation={6} 
          variant="filled" 
          onClose={handleClose} 
          severity={snackbarMessage.severity}
        >
          {snackbarMessage.message}
        </MuiAlert>
      </Snackbar>
      
    </Container>
  );
}

export default SignInPage;
