// src/components/Sidebar.js
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, Paper, Divider } from '@mui/material';
import { LiteGraph } from 'litegraph.js';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import UploadIcon from '@mui/icons-material/Upload';
import { sessionStore } from '../utils/sessionStore';

function Sidebar({ graph, onClearWorkspace }) {
  // Group node types by category
  const nodeGroups = {
    input: ['Load BLF File', 'Load ASC File', 'Load DBC Files'],
    middle: ['Decode CAN', 'Channel Filter', 'Message Filter'],
    output: ['Trace Window', 'Graph Window', 'Test Cases']
  };

  // Combine all node types for existing logic
  const nodeTypes = [...nodeGroups.input, ...nodeGroups.middle, ...nodeGroups.output];

  const handleAddNode = (type) => {
    let nodeTypeName = '';
    switch (type) {
      case 'Load BLF File':
        nodeTypeName = 'file/loadBLF';
        break;
      case 'Load ASC File':
        nodeTypeName = 'file/loadASC';
        break;
      case 'Load DBC Files':
        nodeTypeName = 'file/loadDBC';
        break;
      case 'Decode CAN':
        nodeTypeName = 'can/decodeCAN';
        break;
      case 'Channel Filter':
        nodeTypeName = 'filter/channelFilter';
        break;
      case 'Message Filter':
        nodeTypeName = 'filter/messageFilter';
        break;
      // case 'Save Trace':
      //   nodeTypeName = 'file/saveTrace';
      //   break;
      case 'Trace Window':
        nodeTypeName = 'display/traceWindow';
        break;
      case 'Graph Window':
        nodeTypeName = 'display/graphWindow';
        break;
      case 'Test Cases':
        nodeTypeName = 'display/testCases';
        break;
      default:
        console.error('Unknown node type:', type);
        return;
    }

    try {
      const node = LiteGraph.createNode(nodeTypeName);
      if (!node) {
        console.error(`Failed to create node of type ${nodeTypeName}`);
        return;
      }

      // Initialize with default position
      let position = {
        x: 200,
        y: 200
      };

      // Get existing node positions
      const occupiedPositions = new Set();
      if (graph && graph._nodes) {
        graph._nodes.forEach(existingNode => {
          occupiedPositions.add(`${Math.round(existingNode.pos[0])},${Math.round(existingNode.pos[1])}`);
        });
      }

      // Use viewport dimensions as boundaries
      const gridSize = 250;
      const maxWidth = window.innerWidth - 400;  // Leave space for sidebar
      const maxHeight = window.innerHeight - 200; // Leave space for header/footer

      // Find first available position in grid
      let found = false;
      for (let y = 200; y < maxHeight && !found; y += gridSize) {
        for (let x = 200; x < maxWidth && !found; x += gridSize) {
          if (!occupiedPositions.has(`${x},${y}`)) {
            position.x = x;
            position.y = y;
            found = true;
            break;
          }
        }
      }

      node.pos = [position.x, position.y];
      graph.add(node);
      graph.setDirtyCanvas(true);

    } catch (error) {
      console.error('Error creating node:', error);
    }
  };
  
  const handleSaveWorkspace = () => {
    if (!graph) {
      console.error('Graph instance is not available');
      return;
    }
    const data = graph.serialize();
    const jsonString = JSON.stringify(data, null, 2);
  
    // Emit save event with data
    const saveEvent = new CustomEvent('workspaceSaved', {
      detail: { data: jsonString }
    });
    document.dispatchEvent(saveEvent);
  
    // Normal browser download flow
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'workspace.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    // Save to Session Storage
    sessionStore.setWorkspace(data);
  };
  
  const handleLoadWorkspace = () => {
    if (!graph) {
      console.error('Graph instance is not available');
      return;
    }
    // Create an input element for file selection
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        //clear the workspace
        graph.clear();

        // Clear Session Storage
        sessionStore.clearWorkspace();
        
        // Call the parent callback to clear windows
        onClearWorkspace?.();

        // Read the file content
        const reader = new FileReader();
        reader.onload = (event) => {
          try {
            const jsonData = JSON.parse(event.target.result);
            graph.clear(); // Clear the existing graph
            graph.configure(jsonData); // Load the new graph

            graph._nodes.forEach(node => {
              switch (node.type) {
                case 'file/loadBLF':
                  // Restore BLF filename to text widget
                  if (node.widgets[0] && node.properties.filepath) {
                    node.widgets[0].value = node.properties.filepath;
                    // Update node size based on filename
                    node.size[0] = node.computeNodeSize(node.properties.filepath);
                  }
                  break;
      
                case 'file/loadDBC':
                  // Restore DBC files and channels
                  if (node.properties.dbcList) {
                    node.clearWidgets();
                    node.updateWidgets();

                    node.properties.dbcList.forEach((dbc, index) => {
                      
                      if (dbc.file) {
                        // Update filename in widget
                        const fileWidget = node.properties.fileWidgets[index];
                        if (fileWidget) {
                          fileWidget.value = dbc.filename;
                        }
                      }
                    });
                    // Update node size
                    node.size[0] = node.computeNodeSize();
                  }
                  break;
      
                case 'display/graphWindow':
                  // Update window name widget
                  if (node.widgets[0]) {
                    node.widgets[0].value = node.properties.title;
                  }
                  break;
      
                case 'display/traceWindow':
                  // Restore trace window name
                  if (node.widgets[0]) {
                    node.widgets[0].value = node.properties.title;
                  }
                  break;

                case 'display/testCases':
                  // Restore test cases window name
                  if (node.widgets[0]) {
                    node.widgets[0].value = node.properties.title;
                  }
                  // Restore file name
                  if (node.widgets[1] && node.properties.filepath) {
                    node.widgets[1].value = node.properties.filepath;
                  }
                  break;
              }
              // Ensure canvas is updated
              node.setDirtyCanvas(true);
            });

          } catch (error) {
            console.error('Error loading workspace:', error);
            alert('Failed to load workspace. Invalid file format.');
          }
        };
        reader.readAsText(file);

        // Save to Session Storage
        const data = graph.serialize();
        sessionStore.setWorkspace(data);
      }
    };
    input.click();
  };

  const handleClearWorkspace = () => {
    if (graph) {
      if (window.confirm('Are you sure you want to clear the workspace?')) {
        graph.clear();
        graph.stop();

        // Clear Session Storage
        sessionStore.clearWorkspace();

        // Call the parent callback to clear windows
        onClearWorkspace?.();
      }
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      {/* Input Nodes Section */}
      <Paper 
        variant="outlined" 
        sx={{ mb: 2, p: 1 }}
      >
        <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold', textAlign: 'center' }}>
          Input
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {nodeGroups.input.map((type) => (
          <Button
            key={type}
            variant="contained"
            color="info"
            fullWidth
            onClick={() => handleAddNode(type)}
            sx={{ mb: 1 }}
          >
            {type}
          </Button>
        ))}
      </Paper>

      {/* Middle Nodes Section */}
      <Paper 
        variant="outlined" 
        sx={{ mb: 2, p: 1 }}
      >
        <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold', textAlign: 'center' }}>
          Processing
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {nodeGroups.middle.map((type) => (
          <Button
            key={type}
            variant="contained"
            color="info"
            fullWidth
            onClick={() => handleAddNode(type)}
            sx={{ mb: 1 }}
          >
            {type}
          </Button>
        ))}
      </Paper>

      {/* Output Nodes Section */}
      <Paper 
        variant="outlined" 
        sx={{ mb: 2, p: 1 }}
      >
        <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold', textAlign: 'center' }}>
          Output
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {nodeGroups.output.map((type) => (
          <Button
            key={type}
            variant="contained"
            color="info"
            fullWidth
            onClick={() => handleAddNode(type)}
            sx={{ mb: 1 }}
          >
            {type}
          </Button>
        ))}
      </Paper>

      {/* Spacer to push buttons to the bottom */}
      <Box sx={{ flexGrow: 1 }} />
      
      {/* Workspace control buttons remain unchanged */}
      <Button
        className='save-workspace'
        variant="outlined"
        color="info"
        startIcon={<SaveAltIcon />}
        fullWidth
        onClick={handleSaveWorkspace}
        sx={{ mb: 1 }}
      >
        Save
      </Button>
      <Button
        className='load-workspace'
        variant="outlined"
        color="info"
        startIcon={<UploadIcon />}
        fullWidth
        onClick={handleLoadWorkspace}
        sx={{ mb: 1 }}
      >
        Load
      </Button>
      <Button
        className='clear-workspace'
        variant="outlined"
        color="info"
        startIcon={<DeleteIcon />}
        fullWidth
        onClick={handleClearWorkspace}
        sx={{ mb: 1 }}
      >
        Clear
      </Button>
      <Box sx={{ height: '5px' }} />
      <Typography variant="h5" color="info" sx={{ textAlign: 'center' }}>
        <strong>InScope</strong>
      </Typography>
    </Box>
  );
}

Sidebar.propTypes = {
  graph: PropTypes.object.isRequired,
  onClearWorkspace: PropTypes.func,
};

export default Sidebar;
