// src/components/NodeTypes/TestCasesNode.js
import axios from 'axios';

function TestCasesNode() {
  this.addInput('Decoded CAN Data', 'decoded_can_data');
  this.addInput('CSV File', 'file');
  
  // Unique ID for each instance
  this.id = 'testcases_' + Math.random().toString(36).substr(2, 9);
  
  this.properties = {
    isCompleted: false,
    nodeState: 'idle',
    customTitle: '',
    title: 'Test Cases ' + this.id.split('_')[1],
    currentDataId: null,
    testCases: [],
    results: [],
    csvFilepath: ''
  };

  // Add widget for title input
  this.addWidget("text", "Window Name", this.properties.customTitle, (value) => {
    this.properties.customTitle = value;
    this.properties.title = value || ('Test Cases ' + this.id.split('_')[1]);
    
    // Update window registration if exists
    this.registerWindow();
  });

  // Add widget for CSV file input
  this.addWidget("text", "CSV File", this.properties.csvFilepath, (value) => {
    this.properties.csvFilepath = value;
  });

  // Add widget for CSV file upload
  this.addWidget("button", "Load CSV", "browse", () => {
    this.onLoadButtonClick();
  });

  this.backgroundColorByState = {
    idle: '#ccc',
    processing: '#FFA500',
    completed: '#00FF00',
    error: '#FF0000'
  };

  // Set initial color
  this.boxcolor = this.backgroundColorByState.idle;

  // Helper method to register window in global system
  this.registerWindow = function() {
    console.log('[TestCasesNode] Attempting to register window:', this.id);
    console.log('[TestCasesNode] Window data:', {
      id: this.id,
      title: this.properties.title,
      dataId: this.properties.currentDataId,
      testCases: this.properties.testCases?.length,
      results: this.properties.results?.length
    });
    
    // Only register if we have both a data ID and test cases
    if (window.registerTestCasesWindow && this.properties.currentDataId && this.properties.testCases?.length > 0) {
      console.log('[TestCasesNode] Registering test cases window');
      
      window.registerTestCasesWindow({
        id: this.id,
        title: this.properties.title,
        dataId: this.properties.currentDataId,
        testCases: this.properties.testCases,
        results: this.properties.results
      });
      
      console.log('[TestCasesNode] Window registered successfully');
      
      // Set complete state after successful registration
      if (this.properties.nodeState === 'processing') {
        this.properties.isCompleted = true;
        this.properties.nodeState = 'completed';
        this.boxcolor = this.backgroundColorByState.completed;
        this.setDirtyCanvas(true);
      }
    } else {
      console.log('[TestCasesNode] Not registering window. Global handler exists:', !!window.registerTestCasesWindow);
      console.log('[TestCasesNode] dataId:', this.properties.currentDataId);
      console.log('[TestCasesNode] testCases length:', this.properties.testCases?.length);
    }
  };

  // Get auth token from localStorage
  this.getAuthToken = function() {
    return localStorage.getItem('authToken');
  };

  // Utility to calculate text width for sizing
  this.computeTextWidth = function(text, includeLabel = true) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = '12px Arial, sans-serif';
    
    let totalWidth = ctx.measureText(text).width;
    
    if (includeLabel) {
      const labelWidth = ctx.measureText('Selected File: ').width;
      totalWidth += labelWidth;
    }
    
    // Add padding
    return totalWidth + 60;
  };

  // Add file input handling
  this.cleanupFileInput = function() {
    const existingInput = document.getElementById('testcases-file-input');
    if (existingInput) {
      existingInput.remove();
    }
    this._fileDialogOpen = false;
  };

  this.createFileInput = function() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.id = 'testcases-file-input';
    input.style.display = 'none';
    document.body.appendChild(input);

    this._fileDialogOpen = true;

    const cleanup = () => {
      this.cleanupFileInput();
    };

    input.addEventListener('change', async (e) => {
      const file = e.target.files[0];
      if (!file) {
        cleanup();
        return;
      }

      // Update widget with filename
      this.properties.csvFilepath = file.name;
      if (this.widgets && this.widgets[1]) {
        this.widgets[1].value = file.name;
      }
      this.size[0] = Math.max(200, this.computeTextWidth(file.name));
      
      // Parse CSV file
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          console.log('[TestCasesNode] Parsing CSV file');
          const csvContent = event.target.result;
          const parsed = this.parseCSV(csvContent);
          
          // Store parsed test cases
          this.properties.testCases = parsed;
          console.log('[TestCasesNode] Parsed test cases:', parsed.length);
          
          // Reset results when a new CSV is loaded
          this.properties.results = [];

          // If we have decoded data already, register the window
          if (this.properties.currentDataId) {
            // Set processing state when both data and test cases are ready
            this.properties.nodeState = 'processing';
            this.boxcolor = this.backgroundColorByState.processing;
            
            // Register with window system
            this.registerWindow();
          } else {
            // Just set completed state for CSV load
            this.properties.nodeState = 'completed';
            this.boxcolor = this.backgroundColorByState.completed;
            console.log('[TestCasesNode] CSV loaded, waiting for CAN data input');
          }
          
        } catch (error) {
          console.error("[TestCasesNode] Error parsing CSV:", error);
          this.properties.nodeState = 'error';
          this.boxcolor = this.backgroundColorByState.error;
        }
        
        this.setDirtyCanvas(true);
      };
      
      reader.readAsText(file);
      cleanup();
    });

    input.click();
  };
  
  // Parse CSV function
  this.parseCSV = function(csvContent) {
    // Handle different line endings and split into lines
    const lines = csvContent.replace(/\r\n/g, '\n').split('\n');
    
    // Look for header line
    let headerLine = 0;
    let foundHeader = false;
    
    // Find header row (check first few rows)
    for (let i = 0; i < Math.min(5, lines.length); i++) {
      const line = lines[i].toLowerCase();
      if (line.includes('condition') && (line.includes('expect') || line.includes('result')) && line.includes('timing')) {
        headerLine = i;
        foundHeader = true;
        break;
      }
    }
    
    if (!foundHeader) {
      throw new Error('CSV file must have headers including "Conditions", "Expected", "Timing", and "Comments"');
    }
    
    // Parse header row
    const headers = this.parseCSVRow(lines[headerLine]);
    
    // Find required columns
    const conditionsIndex = headers.findIndex(h => h.toLowerCase().includes('condition'));
    const expectedIndex = headers.findIndex(h => 
      h.toLowerCase().includes('expect') || h.toLowerCase().includes('result')
    );
    const timingIndex = headers.findIndex(h => h.toLowerCase().includes('timing'));
    const commentsIndex = headers.findIndex(h => h.toLowerCase().includes('comment'));
    
    if (conditionsIndex === -1 || expectedIndex === -1 || timingIndex === -1) {
      throw new Error('CSV file must have columns for conditions, expected results, and timing');
    }
    
    // Parse data rows
    const testCases = [];
    
    for (let i = headerLine + 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (!line) continue; // Skip empty lines
      
      const values = this.parseCSVRow(line);
      
      // Skip if we don't have enough values
      if (values.length <= Math.max(conditionsIndex, expectedIndex, timingIndex)) {
        continue;
      }
      
      // Create test case object
      const testCase = {
        conditions: values[conditionsIndex],
        expected: values[expectedIndex],
        timing: values[timingIndex],
        comments: commentsIndex !== -1 ? values[commentsIndex] : ''
      };
      
      // Make sure conditions has at least one signal reference in CAN{N}.signal format
      if (testCase.conditions.match(/CAN\d+\.[a-zA-Z0-9_]+/)) {
        testCases.push(testCase);
      }
    }
    
    return testCases;
  };
  
  // Helper to parse a CSV row accounting for quoted fields
  this.parseCSVRow = function(row) {
    const result = [];
    let current = '';
    let inQuotes = false;
    
    for (let i = 0; i < row.length; i++) {
      const char = row[i];
      
      if (char === '"') {
        inQuotes = !inQuotes;
      } else if (char === ',' && !inQuotes) {
        result.push(current.trim());
        current = '';
      } else {
        current += char;
      }
    }
    
    // Add the last field
    result.push(current.trim());
    return result;
  };

  // Add button handler
  this.onLoadButtonClick = function() {
    this.cleanupFileInput();
    this.createFileInput();
  };
  
  // Add state restoration method
  this.restoreState = function(state) {
    console.log('[TestCasesNode] Restoring state:', state);
    
    if (state) {
      this.properties.testCases = state.testCases || [];
      this.properties.results = state.results || [];
      this.properties.currentDataId = state.currentDataId || null;
      this.properties.csvFilepath = state.csvFilepath || '';
      this.properties.customTitle = state.customTitle || '';
      this.properties.title = state.title || ('Test Cases ' + this.id.split('_')[1]);
      
      // Update widget values after state restoration
      if (this.widgets) {
        if (this.widgets[0]) {
          this.widgets[0].value = this.properties.customTitle || '';
        }
        
        if (this.widgets[1]) {
          this.widgets[1].value = this.properties.csvFilepath || '';
        }
      }
      
      // Restore node state based on properties
      if (this.properties.testCases.length > 0) {
        if (this.properties.currentDataId) {
          if (this.properties.results && this.properties.results.length > 0) {
            this.properties.nodeState = 'completed';
            this.properties.isCompleted = true;
          } else {
            this.properties.nodeState = 'processing';
          }
          
          // Re-register with window system
          setTimeout(() => this.registerWindow(), 100);
        } else {
          this.properties.nodeState = 'completed'; // CSV loaded but no data yet
        }
      } else {
        this.properties.nodeState = 'idle';
      }
      
      this.boxcolor = this.backgroundColorByState[this.properties.nodeState];
    }
  };

  // Debug method
  this.debugState = function() {
    console.log('[TestCasesNode] Node State:', {
      id: this.id,
      title: this.properties.title,
      testCasesCount: this.properties.testCases.length,
      resultsCount: this.properties.results.length,
      currentDataId: this.properties.currentDataId,
      nodeState: this.properties.nodeState,
      isCompleted: this.properties.isCompleted
    });
  };

  // State persistence method
  this.serializeState = function() {
    return {
      testCases: this.properties.testCases,
      results: this.properties.results,
      currentDataId: this.properties.currentDataId,
      csvFilepath: this.properties.csvFilepath,
      customTitle: this.properties.customTitle,
      title: this.properties.title
    };
  };
  
  // Method to compute size based on content
  this.computeSize = function() {
    return [
      Math.max(200, this.computeTextWidth(this.properties.csvFilepath || '')),
      90  // Fixed height
    ];
  };
}

TestCasesNode.title = 'Test Cases';
TestCasesNode.desc = 'Load and evaluate test cases from CSV file';

// Define the node's execution logic
TestCasesNode.prototype.onExecute = function() {
  console.log('[TestCasesNode] Execute running');
  const inputData = this.getInputData(0);  // Decoded CAN data
  const csvData = this.getInputData(1);    // CSV file (if provided through another node)
  
  // Parse CSV data if it was provided through input
  if (csvData && typeof csvData === 'string' && csvData !== this.lastCsvData) {
    console.log('[TestCasesNode] New CSV data received from input');
    this.lastCsvData = csvData;
    try {
      const parsed = this.parseCSV(csvData);
      this.properties.testCases = parsed;
      this.properties.results = []; // Reset results when CSV changes
      this.properties.nodeState = 'processing';
      this.boxcolor = this.backgroundColorByState.processing;
    } catch (error) {
      console.error("[TestCasesNode] Error parsing CSV data:", error);
      this.properties.nodeState = 'error';
      this.boxcolor = this.backgroundColorByState.error;
    }
  }
  
  // Process if we have valid input data and test cases
  if (inputData && this.properties.testCases.length > 0) {
    console.log('[TestCasesNode] Have both input data and test cases');
    
    // Only update if the data ID changed
    if (inputData !== this.properties.currentDataId) {
      console.log('[TestCasesNode] New data ID detected:', inputData);
      this.properties.currentDataId = inputData;
      this.properties.nodeState = 'processing';
      this.properties.results = []; // Reset results when data changes
      this.boxcolor = this.backgroundColorByState.processing;
      
      // Register with the window system
      this.registerWindow();
    } else if (this.properties.nodeState === 'processing') {
      // If data hasn't changed but we're still in processing state,
      // make sure window registration happens
      if (!this.windowRegistered) {
        this.registerWindow();
        this.windowRegistered = true;
      }
    }
  } else {
    // Reset state if no input data or test cases
    if (!this.properties.testCases.length) {
      this.properties.nodeState = 'idle';
      this.properties.isCompleted = false;
      this.boxcolor = this.backgroundColorByState.idle;
    }
    
    if (!inputData && this.properties.currentDataId) {
      console.log('[TestCasesNode] Data input removed, resetting');
      this.properties.currentDataId = null;
      this.properties.nodeState = this.properties.testCases.length > 0 ? 'completed' : 'idle';
      this.boxcolor = this.backgroundColorByState[this.properties.nodeState];
      this.windowRegistered = false;
    }
  }
  
  this.setDirtyCanvas(true);
};

// Method to update results from TestCasesWindow
TestCasesNode.prototype.updateResults = function(results) {
  if (!Array.isArray(results)) return;
  console.log('[TestCasesNode] Received updated results:', results.length);
  this.properties.results = [...results];
  
  // Update node state to completed when results are received
  if (this.properties.nodeState === 'processing') {
    this.properties.nodeState = 'completed';
    this.properties.isCompleted = true;
    this.boxcolor = this.backgroundColorByState.completed;
  }
  
  this.setDirtyCanvas(true);
  
  // Force the window registration to update with the new results
  if (window.registerTestCasesWindow && this.properties.currentDataId) {
    console.log('[TestCasesNode] Re-registering window with updated results');
    window.registerTestCasesWindow({
      id: this.id,
      title: this.properties.title,
      dataId: this.properties.currentDataId,
      testCases: this.properties.testCases,
      results: this.properties.results
    });
  }
};

export default TestCasesNode;