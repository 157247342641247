// src/components/ArticlesSection.js
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

// Styled components remain the same
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1, 0),
  }
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  }
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  paddingTop: '56.25%', // 16:9 aspect ratio
  [theme.breakpoints.down('sm')]: {
    paddingTop: '75%', // 4:3 aspect ratio for mobile
  }
}));

const ArticlesSection = () => {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        console.log('Fetching articles from:', `${process.env.REACT_APP_API_URL}/articles?limit=6`);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/articles?limit=6`);
        console.log('Articles response:', response.data);
        setArticles(response.data);
      } catch (err) {
        const errorMessage = err.response?.data?.detail || err.message;
        console.error('Error fetching articles:', err);
        setError(`Failed to fetch articles: ${errorMessage}`);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center">
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ 
      px: { xs: 2, sm: 3, md: 4 }, 
      maxWidth: '1200px',
      margin: '0 auto'
    }}>
      <Grid 
        container 
        spacing={{ xs: 2, sm: 3, md: 4 }}
      >
        {articles?.map((article) => (
          <Grid 
            key={article.id}
            item xs={12} sm={6} md={4} lg={4}
          >
            <Link to={`/latest/${article.id}`} style={{ textDecoration: 'none' }}>
              <StyledCard>
                <StyledCardMedia
                  image={article.image_data}  // Changed from image_url
                  title={article.title}
                />
                <StyledCardContent>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      mb: 1,
                      fontSize: { xs: '1.1rem', sm: '1.25rem' }
                    }}
                  >
                    {article.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      fontSize: { xs: '0.875rem', sm: '1rem' }
                    }}
                  >
                    {article.description}
                  </Typography>
                  <Box sx={{ 
                    display: 'flex', 
                    gap: 1,
                    flexWrap: 'wrap',
                    mb: 1 
                  }}>
                    {article.tags?.map((tag, idx) => (
                      <Chip 
                        key={idx}
                        label={tag} 
                        size={"small"} 
                        sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                      />
                    ))}
                  </Box>
                </StyledCardContent>
                <Box sx={{ p: 2, pt: 0 }}>
                  <AvatarGroup max={3} sx={{ justifyContent: 'flex-start' }}>
                    {article.authors.map((author, idx) => (
                      <Avatar
                        key={idx}
                        alt={author.name}
                        src={`/images/avatar/${author.avatar}`}
                        sx={{ 
                          width: { xs: 24, sm: 32 },
                          height: { xs: 24, sm: 32 }
                        }}
                      />
                    ))}
                  </AvatarGroup>
                </Box>
              </StyledCard>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ArticlesSection;