// src/components/Header.js
import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, Tooltip, Avatar, IconButton, Box, Drawer, List, ListItem, ListItemText, ListItemButton } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import axios from 'axios';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { CartContext } from '../context/CartContext';
import CartDialog from './CartDialog';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.02),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  marginRight: theme.spacing(2), // Add right margin
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`, // Add subtle border
  boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.05)}`, // Add subtle shadow
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(2), // Increase left margin
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

function stringToColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name.split(' ').length > 1 
      ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` 
      : name[0],
  };
}

function Header() {
  const { isAuthenticated, authToken, logout } = useContext(AuthContext);
  const { cartItems } = useContext(CartContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  // Initialize userData from localStorage if available
  const [userData, setUserData] = useState(() => {
    const cached = localStorage.getItem('userData');
    return cached ? JSON.parse(cached) : null;
  });
  const [cartOpen, setCartOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (isAuthenticated && authToken) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/users/me`,
            { 
              withCredentials: true,
              headers: {
                'Authorization': `Bearer ${authToken}`
              }
            }
          );
          setUserData(response.data);
          // Cache the user data
          localStorage.setItem('userData', JSON.stringify(response.data));
        } catch (err) {
          console.error('Error fetching user data:', err);
        }
      } else {
        // Clear cached data when not authenticated
        localStorage.removeItem('userData');
        setUserData(null);
      }
    };

    fetchUserData();
  }, [isAuthenticated, authToken]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActivePage = (path) => {
    if (path === '/') {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  const activeButtonStyle = {
    backgroundColor: 'rgba(55, 65, 81, 0.15)',
  };

  // Convert Fragment content to array of MenuItems
  const menuItems = isAuthenticated ? [
    <MenuItem key="account" onClick={handleClose} component={Link} to="/account">
      My Account
    </MenuItem>,
    <MenuItem
      key="logout"
      onClick={() => {
        logout();
        handleClose();
      }}
    >
      Logout
    </MenuItem>
  ] : [
    <MenuItem key="login" onClick={handleClose} component={Link} to="/signin">
      Login
    </MenuItem>
  ];

  const renderAvatar = () => {
    if (isAuthenticated && userData) {
      return userData.profile_picture ? (
        <Avatar 
          alt={userData.username} 
          src={userData.profile_picture}
        />
      ) : (
        <Avatar {...stringAvatar(userData.username)} />
      );
    }
    return <AccountCircleIcon fontSize='large'/>;
  };

  // Add this function to calculate total quantity
  const getTotalQuantity = () => {
    return cartItems.reduce((total, item) => total + (item.quantity || 1), 0);
  };

  return (
    <header>
      <AppBar position="fixed" color="info-light">
        <Toolbar>
          {/* Mobile menu icon */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setMobileOpen(!mobileOpen)}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          {/* Company Logo/Name */}
          <Typography 
            variant="h4" 
            color="info" 
            className='header-titlefont'
            component={Link} 
            to="/"
            sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
          >
            <strong>revisus</strong>
          </Typography>

          <div style={{ flexGrow: 1 }} />

          {/* Desktop Navigation */}
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', gap: 1 }}>
            <Button 
              component={Link} 
              to="/" 
              variant="text" 
              color="info" 
              size="large"
              sx={isActivePage('/') ? activeButtonStyle : {'&:hover': {
                  backgroundColor: 'rgba(33, 150, 243, 0.1)'
                }}
              }
            >
              Home
            </Button>
            <Button 
              component={Link} 
              to="/products" 
              variant="text" 
              color="info" 
              size="large"
              sx={isActivePage('/products') ? activeButtonStyle : {'&:hover': {
                  backgroundColor: 'rgba(33, 150, 243, 0.1)'
                }}
              }
            >
              Products
            </Button>
            <Button 
              component={Link} 
              to="/latest" 
              variant="text" 
              color="info" 
              size="large"
              sx={isActivePage('/latest') ? activeButtonStyle : {'&:hover': {
                  backgroundColor: 'rgba(33, 150, 243, 0.1)'
                }}
              }
            >
              Latest
            </Button>
            {isAuthenticated && (
              <>
                <Button 
                  component={Link} 
                  to="/documentation" 
                  variant="text" 
                  color="info" 
                  size="large"
                  sx={isActivePage('/documentation') ? activeButtonStyle : {'&:hover': {
                      backgroundColor: 'rgba(33, 150, 243, 0.1)'
                    }}
                  }
                >
                  Documentation
                </Button>
                <Button 
                  component={Link} 
                  to="/inscope/workspace" 
                  variant="text" 
                  color="info" 
                  size="large"
                  sx={isActivePage('/inscope') ? activeButtonStyle : {'&:hover': {
                      backgroundColor: 'rgba(33, 150, 243, 0.1)'
                    }}
                  }
                >
                  InScope
                </Button>
              </>
            )}
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
            {isAuthenticated && (cartItems.length > 0) && (
              <IconButton color="inherit" onClick={() => setCartOpen(true)}>
                <Badge badgeContent={getTotalQuantity()} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            )}
            <Tooltip title="Open settings">
              <IconButton onClick={handleClick} sx={{ p: 0 }}>
                {renderAvatar()}
              </IconButton>
            </Tooltip>
          </Box>

          {/* Mobile Avatar */}
          <Box sx={{ display: { sm: 'none' } }}>
            <IconButton onClick={handleClick} sx={{ p: 0 }}>
              {renderAvatar()}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { width: 240 },
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton 
              component={Link} 
              to="/"
              onClick={() => setMobileOpen(false)}
              selected={isActivePage('/')}
            >
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton 
              component={Link} 
              to="/products"
              onClick={() => setMobileOpen(false)}
              selected={isActivePage('/products')}
            >
              <ListItemText primary="Products" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton 
              component={Link} 
              to="/latest"
              onClick={() => setMobileOpen(false)}
              selected={isActivePage('/latest')}
            >
              <ListItemText primary="Latest" />
            </ListItemButton>
          </ListItem>
          {isAuthenticated && (
            <>
              <ListItem disablePadding>
                <ListItemButton 
                  component={Link} 
                  to="/documentation"
                  onClick={() => setMobileOpen(false)}
                  selected={isActivePage('/documentation')}
                >
                  <ListItemText primary="Documentation" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton 
                  component={Link} 
                  to="/inscope/workspace"
                  onClick={() => setMobileOpen(false)}
                  selected={isActivePage('/inscope')}
                >
                  <ListItemText primary="InScope" />
                </ListItemButton>
              </ListItem>
            </>
          )}
          <ListItem>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </ListItem>
          {isAuthenticated && (cartItems.length > 0) && (
            <ListItem>
              <IconButton color="inherit" onClick={() => setCartOpen(true)}>
                <Badge badgeContent={getTotalQuantity()} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </ListItem>
          )}
        </List>
      </Drawer>

      {/* Settings Menu */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems}
      </Menu>
      <CartDialog open={cartOpen} onClose={() => setCartOpen(false)} />
    </header>
  );
}

export default Header;