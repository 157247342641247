import React from 'react';
import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const MarkdownContent = ({ children, variant = 'default' }) => {
  const styles = {
    default: {
      '& h1': { fontSize: 'h4.fontSize', mb: 2, mt: 3 },
      '& h2': { fontSize: 'h5.fontSize', mb: 2, mt: 3 },
      '& h3': { fontSize: 'h6.fontSize', mb: 1.5, mt: 2 },
      '& p': { mb: 2 },
      '& ul, & ol': { mb: 2, pl: 3 },
      '& li': { mb: 0.5 },
      '& a': { color: 'primary.main', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } },
      '& code': { backgroundColor: 'grey.100', px: 0.5, borderRadius: 0.5 },
      '& pre': { backgroundColor: 'grey.100', p: 2, borderRadius: 1, overflowX: 'auto' },
      '& blockquote': { borderLeft: 4, borderColor: 'grey.300', pl: 2, ml: 0, my: 2 },
      '& img': { maxWidth: '100%', height: 'auto' }
    },
    compact: {
      '& h1': { fontSize: 'h6.fontSize', mb: 1 },
      '& h2, & h3': { fontSize: 'body1.fontSize', mb: 0.5 },
      '& p': { mb: 1 },
      '& ul, & ol': { mb: 1, pl: 2 },
      '& li': { mb: 0.5 },
      '& a': { color: 'primary.main', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } },
      '& code': { backgroundColor: 'grey.100', px: 0.5, borderRadius: 0.5 },
      '& pre': { backgroundColor: 'grey.100', p: 1, borderRadius: 1, overflowX: 'auto' },
      '& blockquote': { borderLeft: 2, borderColor: 'grey.300', pl: 1, ml: 0, my: 1 },
      '& img': { maxWidth: '100%', height: 'auto' }
    },
    preview: {
      '& h1': { fontSize: 'h5.fontSize', mb: 2 },
      '& h2': { fontSize: 'h6.fontSize', mb: 1.5 },
      '& h3': { fontSize: 'subtitle1.fontSize', mb: 1 },
      '& p': { mb: 1 },
      '& ul, & ol': { mb: 1, pl: 2 },
      '& li': { mb: 0.5 },
      '& code': { backgroundColor: 'grey.100', px: 0.5, borderRadius: 0.5 },
      '& pre': { backgroundColor: 'grey.100', p: 1, borderRadius: 1, overflowX: 'auto' },
      '& blockquote': { borderLeft: 4, borderColor: 'grey.300', pl: 2, ml: 0, my: 1 },
      '& img': { maxWidth: '100%', height: 'auto' }
    }
  };

  return (
    <Box sx={styles[variant]}>
      <ReactMarkdown>{children}</ReactMarkdown>
    </Box>
  );
};

export default MarkdownContent;