// src/components/NodeTypes/ChannelFilterNode.js
import axios from 'axios';
import { dataStore } from '../../utils/dataStore';
import { AuthContext } from '../../context/AuthContext';

function ChannelFilterNode() {
  // Add input/output for decoded CAN data
  this.addInput('Decoded CAN Data', 'decoded_can_data');
  this.addOutput('Decoded CAN Data', 'decoded_can_data');

  // Node properties
  this.properties = {
    channelNumber: 1,
    filterType: 'pass', // 'pass' or 'stop'
    decodedDataId: null,
    filteredDataId: null,
    dataFetched: false,
    isCompleted: false,
    nodeState: 'idle' // Can be 'idle', 'processing', or 'completed'
  };

  // Add resetState function
  this.resetState = function() {
    this.properties.nodeState = 'idle';
    this.properties.filteredDataId = null;
    this.properties.decodedDataId = null;
    this.properties.dataFetched = false;
    this.properties.isCompleted = false;
    this.boxcolor = this.backgroundColorByState.idle;
    this.setDirtyCanvas(true);
  };

  // Add property widgets
  this.addWidget("combo", "Filter Type", this.properties.filterType, (v) => {
    this.properties.filterType = v;
    this.resetState();
  }, { values: ['pass', 'stop'] });

  this.addWidget("number", "Channel", this.properties.channelNumber, (v) => {
    this.properties.channelNumber = v;
    this.resetState();
  }, { min: 1, max: 16, step: 1, precision: 0 });

  // Visual state colors
  this.backgroundColorByState = {
    idle: '#ccc',
    processing: '#FFA500',
    completed: '#00FF00'
  };

  this.boxcolor = this.backgroundColorByState.idle;

  // Add tracking for previous output
  this.previousOutputId = null;
}

ChannelFilterNode.title = 'Channel Filter';
ChannelFilterNode.desc = 'Filter CAN messages by Channel';

ChannelFilterNode.prototype.onExecute = async function() {
  const inputDataId = this.getInputData(0);
  const currentOutput = this.properties.filteredDataId;
  const authToken = window.localStorage.getItem('authToken');

  // Early return if no input or already processing
  if (!inputDataId || this.properties.nodeState === 'processing' || !authToken) {
    return;
  }

  // Only reset if output would change
  if (!inputDataId && currentOutput !== null) {
    this.properties = {
      ...this.properties,
      isCompleted: false,
      nodeState: 'idle',
      filteredDataId: null,
      decodedDataId: null,
      dataFetched: false
    };
    this.boxcolor = this.backgroundColorByState.idle;
    this.setOutputData(0, null);
    this.previousOutputId = null;
    this.setDirtyCanvas(true);
    return;
  }

  // Skip if already completed with same input
  if (this.properties.isCompleted && 
      this.properties.decodedDataId === inputDataId &&
      this.previousOutputId === this.properties.filteredDataId) {
    return;
  }

  try {
    this.properties.nodeState = 'processing';
    this.boxcolor = this.backgroundColorByState.processing;
    this.setDirtyCanvas(true);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/offline_files/filter_by_channel`,
      {
        decoded_data_id: inputDataId,
        channel: this.properties.channelNumber,
        filter_type: this.properties.filterType
      },
      {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      }
    );

    // Update properties and output
    this.properties.decodedDataId = inputDataId;
    this.properties.filteredDataId = response.data.filtered_data_id;
    this.properties.isCompleted = true;
    this.properties.nodeState = 'completed';
    this.boxcolor = this.backgroundColorByState.completed;
    
    // Set output data
    this.setOutputData(0, this.properties.filteredDataId);
    this.previousOutputId = this.properties.filteredDataId;
    
  } catch (error) {
    console.error('Error in channel filter:', error);
    this.properties.nodeState = 'idle';
    this.boxcolor = this.backgroundColorByState.idle;
    // Clear output on error
    this.setOutputData(0, null);
    this.previousOutputId = null;
  }

  this.setDirtyCanvas(true);
};

export default ChannelFilterNode;
