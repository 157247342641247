const INSCOPE_SUBSCRIPTION_PLANS = [
    {
        id: 'prod_RiI8EOz3rnl5Zf',
        name: 'Maker Analytics',
        description: 'Advanced analytics solution for makers and hobbyists',
        image: '/images/Insight_Basic.png',
        price: '$10/mo or $100/yr',
        prices: {
            monthly: {
                amount: '$10',
                price_id: 'price_1QorY8K22YO2L5c7Z2BVokCa'
            },
            yearly: {
                amount: '$100',
                price_id: 'price_1QosLiK22YO2L5c7lhEavAEl'
            }
        },
        isPurchasable: true,
        trial_days: 7
    },
    {
        id: 'prod_RiI8rou2CEKYBf',
        name: 'Professional Analytics',
        description: 'Analytics solution for individuals and small businesses',
        image: '/images/Insight_Basic.png',
        price: '$25/mo or $250/yr',
        prices: {
            monthly: {
                amount: '$25',
                price_id: 'price_1QorYaK22YO2L5c7DymWR0S4'
            },
            yearly: {
                amount: '$250',
                price_id: 'price_1QosMTK22YO2L5c71SwOhSUu'
            }
        },
        isPurchasable: true
    },
    {
        id: 'prod_not_purchasable',
        name: 'Enterprise Analytics',
        description: "Custom analytics solution for enterprise customers",
        image: '/images/Insight_Basic.png',
        price: "Let's talk",
        isPurchasable: false,
        trial_days: 7
    }
];

export default INSCOPE_SUBSCRIPTION_PLANS;