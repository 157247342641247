import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, CardActions, Button, Snackbar, CircularProgress } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { CartContext } from '../context/CartContext';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import OrderSuccessDialog from '../components/OrderSuccessDialog';
import axios from 'axios';
import MarkdownContent from '../components/MarkdownContent';

function ProductsPage() {
  const [products, setProducts] = useState({ hardware: [], subscription: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addToCart, clearCart } = useContext(CartContext);
  const { authToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openOrderSuccessDialog, setOpenOrderSuccessDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products?published_only=true`);
        // Sort products by ID before organizing by category
        const sortedProducts = response.data.sort((a, b) => a.id - b.id);
        
        // Organize sorted products by category, include non-purchasable products
        const organizedProducts = sortedProducts.reduce((acc, product) => {
          if (product.published) {  // Removed is_purchasable check
            if (product.category === 'hardware') {
              acc.hardware.push(product);
            } else if (product.category === 'subscription') {
              acc.subscription.push(product);
            }
          }
          return acc;
        }, { hardware: [], subscription: [] });

        setProducts(organizedProducts);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProducts();

    // Check for successful purchase
    const params = new URLSearchParams(window.location.search);
    if (params.get('success') === 'true') {
      clearCart();
      setOpenOrderSuccessDialog(true);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [clearCart]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleAddToCart = (product) => {
    if (!authToken) {
      setOpenSnackbar(true);
      return;
    }

    if (product.category === 'subscription') {
      const plan = 'monthly'; // Default to monthly plan
      addToCart({
        ...product,
        type: 'subscription',
        selectedPlan: plan,
        price_id: product.prices[plan].price_id, // Set the price_id based on selected plan
        price: product.prices[plan].amount,
        quantity: 1
      });
    } else {
      addToCart({
        ...product,
        type: 'hardware',
        price_id: product.price_id,
        price: product.price,
        quantity: 1
      });
    }
  };

  const handleSubscriptionClick = () => {
    navigate('/account#subscriptions');
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, p: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Our Products
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" paragraph>
          Discover our suite of powerful analytics solutions
        </Typography>

        {products.subscription.length > 0 && (
          <>
            <Box sx={{height: "25px"}}/>
            <Typography variant="h4" align="center" color="text.secondary" paragraph>
              Analytics Solutions
            </Typography>
            
            <Grid container spacing={4} sx={{ mt: 4 }}>
              {products.subscription.map((product) => (
                <Grid item key={product.id} xs={12} sm={6} md={4}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="200"
                      image={product.image_data}
                      alt={product.name}
                      sx={{ backgroundColor: 'grey.300' }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {product.name}
                      </Typography>
                      <Box sx={{ minHeight: '80px' }}>
                        <MarkdownContent variant="compact">
                          {product.description}
                        </MarkdownContent>
                      </Box>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        {product.is_purchasable 
                          ? `${product.prices?.monthly?.amount}/mo or ${product.prices?.yearly?.amount}/yr`
                          : "Let's Talk"}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button 
                        size="small" 
                        color="primary"
                        onClick={() => navigate(`/products/${product.id}`)}
                      >
                        Learn More
                      </Button>
                      {product.is_purchasable ? (
                        <Button 
                          size="small" 
                          color="primary" 
                          onClick={handleSubscriptionClick}
                        >
                          Manage Subscription
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => window.location.href = 'mailto:sales@revisus.com'}
                        >
                          Contact Sales
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {products.hardware.length > 0 && (
          <>
            <Box sx={{height: "50px"}}/>
            <Typography variant="h4" align="center" color="text.secondary" paragraph>
              Hardware Solutions
            </Typography>
            
            <Grid container spacing={4} sx={{ mt: 4 }}>
              {products.hardware.map((product) => (
                <Grid item key={product.id} xs={12} sm={6} md={4}>
                  <Card className='product-card'>
                    <CardMedia
                      component="img"
                      height="200"
                      image={product.image_data}
                      alt={product.name}
                      sx={{ backgroundColor: 'grey.300' }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {product.name}
                      </Typography>
                      <Box sx={{ minHeight: '80px' }}>
                        <MarkdownContent variant="compact">
                          {product.description}
                        </MarkdownContent>
                      </Box>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        {product.is_purchasable 
                          ? `${product.price}`
                          : "Coming Soon!"}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button 
                        size="small" 
                        color="primary"
                        onClick={() => navigate(`/products/${product.id}`)}
                      >
                        Learn More
                      </Button>
                      {product.is_purchasable ? (
                        <Button 
                          size="small" 
                          color="primary" 
                          onClick={() => handleAddToCart(product)}
                        >
                          Add to Cart
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => window.location.href = 'mailto:contact@revisus.com'}
                        >
                          Contact Sales
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="info"
        >
          Please log in or create an account to make purchases
        </MuiAlert>
      </Snackbar>

      <OrderSuccessDialog
        open={openOrderSuccessDialog}
        onClose={() => {
          setOpenOrderSuccessDialog(false);
          window.location.reload();
        }}
        title="Purchase Successful!"
        message="Thank you for your purchase! Your order has been confirmed."
      />
    </>
  );
}

export default ProductsPage;