// services/openai.js
import axios from 'axios';

export async function fetchOpenAIResponse(query, current_page, extra_context) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/ai-hub/query`,
            {
                query,
                current_page,
                extra_context
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }
        );

        return response.data.response;
    } catch (error) {
        console.error('Error fetching AI response:', error);
        throw error;
    }
}