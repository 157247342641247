// src/components/NodeTypes/OnlineDeviceNode.js

function OnlineDeviceNode() {
  // Add one output: "Raw CAN Data"
  this.addOutput('Raw CAN Data', 'raw_can_data');

  // Node properties (if any)
  this.properties = {};
}

OnlineDeviceNode.title = 'Online Device';
OnlineDeviceNode.desc = 'CAN Data from Online Device';

// Define the node's execution logic
OnlineDeviceNode.prototype.onExecute = function () {
  // Get data from inputs
  
  this.setOutputData(0, null);
};


export default OnlineDeviceNode;
