// src/components/NodeTypes/LoadASCNode.js
import axios from 'axios';

function LoadASCNode() {
  this.addOutput('Raw CAN Data', 'raw_can_data'); // Output a reference ID
  this.properties = { filepath: '', dataId: null, nodeState: 'idle' }; // Can be 'idle', 'processing', or 'completed'
  this.title = 'Load ASC File';
  this.desc = 'Loads ASC file data and uploads to backend';
  
  // Get auth token from session storage in methods that need it
  this.getAuthToken = function() {
    return localStorage.getItem('authToken');
  };
  
  // Set default size
  this.size = [200, 80];
  
  // Add widget and handle file selection
  this.addWidget('text', 'Selected File', 'filepath', '', (value) => {
    // Update node size based on filename length
    const minWidth = 200;
    const textMetrics = this.computeTextWidth(value, true);
    const newWidth = Math.max(minWidth, textMetrics); // Add padding
    this.size[0] = newWidth;
    this.setDirtyCanvas(true);
  });

  // Helper method to compute text width
  this.computeTextWidth = function(text, includeLabel = true) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // Use more specific font settings to match the actual node style
    ctx.font = '12px Arial, sans-serif';
    
    let totalWidth = ctx.measureText(text).width;
    
    if (includeLabel) {
      const labelWidth = ctx.measureText('Selected File: ').width;
      totalWidth += labelWidth;
    }
    
    // Add padding: 20px left + 40px right
    return Math.ceil(totalWidth + 60);
  };

  // Add these color definitions
  this.backgroundColorByState = {
    idle: '#ccc',
    processing: '#FFA500',  // Yellow/Orange for processing
    completed: '#00FF00',    // Green for completed
    error: '#FF0000'         // Red for error
  };

  // Set initial color
  this.boxcolor = this.backgroundColorByState.idle;

  // Add resetState method
  this.resetState = function() {
    this.properties.nodeState = 'idle';
    this.properties.dataId = null;
    this.boxcolor = this.backgroundColorByState.idle;
    this.setDirtyCanvas(true);
  };

  this.addWidget('button', 'Load File', 'Load', () => {
    this.onFileButtonClick();
  });
}

LoadASCNode.prototype.onFileButtonClick = function() {
  if (this._fileDialogOpen) {
    return;
  }

  // Clean up any existing input
  this.cleanupFileInput();
  
  // Create and setup new input
  this.createFileInput();
};

LoadASCNode.prototype.cleanupFileInput = function() {
  const existingInput = document.getElementById('asc-file-input');
  if (existingInput) {
    existingInput.remove();
  }
  this._fileDialogOpen = false;
};

LoadASCNode.prototype.createFileInput = function() {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = '.asc';
  input.id = 'asc-file-input';
  input.style.display = 'none';
  document.body.appendChild(input);

  this._fileDialogOpen = true;

  const cleanup = () => {
    this.cleanupFileInput();
  };

  input.addEventListener('change', (e) => {
    e.stopPropagation();
    input.blur();
    document.body.focus();
    this.handleChange(e, input, cleanup);

    // Immediately consume the next "phantom" click
    setTimeout(() => {
      const down = new MouseEvent('mousedown', { bubbles: true, cancelable: true, button: 0 });
      const up = new MouseEvent('mouseup', { bubbles: true, cancelable: true, button: 0 });
      document.dispatchEvent(down);
      document.dispatchEvent(up);
    }, 0);
  });

  input.addEventListener('focusout', cleanup);
  input.oncontextmenu = (e) => e.preventDefault();

  input.click();
  input.blur();
};

LoadASCNode.prototype.handleChange = async function(e, input, cleanup) {
  const file = e.target.files[0];
  if (file) {
    this.resetState(); // Reset state when new file is loaded
    // Reset previous state
    this.properties.dataId = null;
    this.properties.nodeState = 'processing';
    this.boxcolor = this.backgroundColorByState.processing;
    

    // Update button to loading state
    this.addLoadFileButton();
    
    console.log(`File selected: ${file.name}`);
    this.properties.filepath = file.name;
    const widget = this.widgets[0];
    widget.value = file.name;
    
    // Set initial node size
    this.size[0] = this.computeNodeSize(file.name);

    const formData = new FormData();
    formData.append('file', file);

    try {
      // Get token using the new method
      const token = this.getAuthToken();
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/offline_files/upload_asc`, 
        formData,
        {
          headers: { 
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          },
        }
      );

      const dataId = response.data.raw_can_data_id;
      console.log(`Received Data ID: ${dataId}`);
      this.properties.dataId = dataId;
      
      this.setOutputData(0, dataId);
      this.properties.nodeState = 'completed';
      this.boxcolor = this.backgroundColorByState.completed;
      
      // Restore button after completion
      this.addLoadFileButton();

      // Ensure size is maintained after upload
      this.size[0] = this.computeNodeSize(file.name);

    } catch (error) {
      console.error('Error uploading ASC file:', error);
      this.properties.nodeState = 'error';
      this.boxcolor = this.backgroundColorByState.error;
      
      // Add more specific error handling
      if (error.response?.status === 401) {
        console.error('Authentication failed. Please log in again.');
      }
      
      // Restore button on error
      this.addLoadFileButton();
    }
  }
  this.setDirtyCanvas(true);
  cleanup();
};

LoadASCNode.prototype.computeNodeSize = function(filename) {
  const minWidth = 210; // Increased from 200 to 210
  const textMetrics = this.computeTextWidth(filename);
  return Math.max(minWidth, textMetrics + 40);
};

LoadASCNode.prototype.addLoadFileButton = function() {
  const buttonText = this.properties.nodeState === 'processing' ? 'Loading... Please Wait' : 'Load File';
  const buttonConfig = {
    className: "node-load-button",
    name: buttonText,
    callback: () => {
      if (this.properties.nodeState !== 'processing') {
        this.onFileButtonClick();
      }
    }
  };

  // Remove existing widgets if any
  if (this.widgets.length > 1) {
    this.widgets.pop();
  }

  // Add the button widget
  this.addWidget("button", buttonConfig.name, null, buttonConfig.callback);
  this.setDirtyCanvas(true);
};

LoadASCNode.prototype.onExecute = async function() {
  this.properties.nodeState = 'processing';
  this.boxcolor = this.backgroundColorByState.processing;
  this.setDirtyCanvas(true);

  console.debug('Executing LoadASCNode');
  // Check if the file has been uploaded
  if (this.properties.dataId) {
    // Output the data ID
    this.setOutputData(0, this.properties.dataId);
    console.debug('LoadASCNode Output DataID:', this.properties.dataId);
  } else {
    // If no file has been uploaded, output null
    this.setOutputData(0, null);
    console.debug('No ASC File uploaded');
  }
  console.debug('LoadASCNode executed successfully');

  if (this.properties.dataId) {
    this.properties.nodeState = 'completed';
    this.boxcolor = this.backgroundColorByState.completed;
  } 
  this.setDirtyCanvas(true);
}

export default LoadASCNode;