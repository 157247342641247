import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Avatar, Button, Chip, useTheme, useMediaQuery, CircularProgress, IconButton, Snackbar } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const ArticlePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/articles/${id}`);
        setArticle(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching article:', err);
        setError('Article not found');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchArticle();
    }
  }, [id]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const copyToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url)
      .then(() => {
        setSnackbarMessage('Link copied to clipboard!');
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.error('Failed to copy link: ', err);
        setSnackbarMessage('Failed to copy link');
        setOpenSnackbar(true);
      });
  };

  const shareOnX = () => {
    const url = window.location.href;
    const text = article ? encodeURIComponent(`Check out this article: ${article.title}`) : encodeURIComponent('Check out this article');
    window.open(`https://x.com/intent/tweet?text=${text}&url=${encodeURIComponent(url)}`, '_blank');
  };

  const shareOnFacebook = () => {
    const url = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
  };

  const shareOnLinkedIn = () => {
    const url = window.location.href;
    const title = article ? encodeURIComponent(article.title) : '';
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&title=${title}`, '_blank');
  };

  if (loading) {
    return (
      <Container>
        <Box sx={{ 
          mt: 10, 
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '50vh'
        }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !article) {
    return (
      <Container>
        <Box sx={{ mt: 10, p: 2 }}>
          <Typography color="error">{error}</Typography>
          <Button onClick={() => navigate('/latest')}>Back to Latest</Button>
        </Box>
      </Container>
    );
  }

return (
    <Container maxWidth="lg">
        <Box sx={{ py: { xs: 2, sm: 3, md: 4 }, mt: { xs: 8, sm: 9, md: 10 } }}>
            <Button
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate('/latest')}
                sx={{ mb: { xs: 2, sm: 3, md: 4 } }}
            >
                Back to Latest
            </Button>

            <Box sx={{ mb: { xs: 3, md: 4 } }}>
                <Typography 
                    variant={isMobile ? "h3" : "h2"} 
                    component="h1" 
                    gutterBottom
                    sx={{ 
                        fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                        lineHeight: { xs: 1.2, sm: 1.3 },
                        textAlign: 'center',
                        pb: { xs: 2, sm: 3, md: 4 }
                    }}
                >
                    {article.title}
                </Typography>

            <Box 
                sx={{ 
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                    gap: { xs: 3, sm: 4 },
                    mb: { xs: 3, md: 4 }
                }}
            >
                <Box
                    component="img"
                    src={article ? article.image_data : ''}  // Changed from image_url
                    alt={article ? article.title : ''}
                    sx={{
                        width: '100%',
                        height: 'auto',
                        aspectRatio: '1',
                        objectFit: 'cover',
                        borderRadius: 1
                    }}
                />
                <Box>
                    <Typography 
                        variant={isMobile ? "h5" : "h4"} 
                        gutterBottom
                        sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' } }}
                    >
                        {article.description}
                    </Typography>
                    <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'flex-start',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: { xs: 2, sm: 3 },
                            mb: { xs: 3, md: 4 }
                    }}>
                            {article.authors.map((author, idx) => (
                            <Box 
                                    key={idx} 
                                    sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center',
                                    gap: 1
                                    }}
                            >
                                    <Avatar 
                                    src={`/images/avatar/${author.avatar}`}
                                    sx={{ 
                                            width: { xs: 32, sm: 40 },
                                            height: { xs: 32, sm: 40 }
                                    }}
                                    />
                                    <Box>
                                    <Typography 
                                            variant="subtitle2"
                                            sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
                                    >
                                            {author.name}
                                    </Typography>
                                    <Typography 
                                            variant="caption" 
                                            color="text.secondary"
                                            sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                                    >
                                            {new Date(article.created_at).toLocaleDateString()}
                                    </Typography>
                                    </Box>
                            </Box>
                            ))}
                            </Box>
                            <Box sx={{ 
                                display: 'flex', 
                                gap: 1,
                                flexWrap: 'wrap',
                                mb: { xs: 2, md: 3 }
                            }}>
                                {article.tags?.map((tag, index) => (
                                    <Chip 
                                        key={index}
                                        label={tag} 
                                        color="primary" 
                                        sx={{ 
                                            fontSize: { xs: '0.75rem', sm: '0.875rem' }
                                        }} 
                                    />
                                ))}
                            </Box>
                            
                            {/* Share buttons */}
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                gap: 1,
                                mb: { xs: 3, md: 4 }
                            }}>
                                <IconButton 
                                    onClick={copyToClipboard}
                                    size="small"
                                    color="primary"
                                    sx={{ border: 1, borderColor: 'divider' }}
                                >
                                    <ContentCopyIcon fontSize="small" />
                                </IconButton>
                                <IconButton 
                                    onClick={shareOnX}
                                    size="small"
                                    color="primary"
                                    sx={{ border: 1, borderColor: 'divider' }}
                                >
                                    <XIcon fontSize="small" />
                                </IconButton>
                                <IconButton 
                                    onClick={shareOnFacebook}
                                    size="small"
                                    color="primary"
                                    sx={{ border: 1, borderColor: 'divider' }}
                                >
                                    <FacebookIcon fontSize="small" />
                                </IconButton>
                                <IconButton 
                                    onClick={shareOnLinkedIn}
                                    size="small"
                                    color="primary"
                                    sx={{ border: 1, borderColor: 'divider' }}
                                >
                                    <LinkedInIcon fontSize="small" />
                                </IconButton>
                            </Box>
                    </Box>
                    
                </Box>
                <Typography 
                        variant="body1"
                        component="div"
                        sx={{ 
                            fontSize: { xs: '1rem', sm: '1.125rem' },
                            '& img': {
                                maxWidth: '100%',
                                height: 'auto',
                            },
                            '& a': {
                                color: 'primary.main',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            },
                            '& h1, & h2, & h3, & h4, & h5, & h6': {
                                mt: 4,
                                mb: 2,
                            },
                            '& p': {
                                mb: 2,
                            },
                            '& ul, & ol': {
                                mb: 2,
                                pl: 2,
                            },
                            '& blockquote': {
                                borderLeft: '4px solid',
                                borderColor: 'grey.300',
                                pl: 2,
                                my: 2,
                                mx: 0,
                            },
                            '& code': {
                                backgroundColor: 'grey.100',
                                p: 0.5,
                                borderRadius: 1,
                                fontFamily: 'monospace',
                            },
                            '& pre': {
                                backgroundColor: 'grey.100',
                                p: 2,
                                borderRadius: 1,
                                overflow: 'auto',
                                '& code': {
                                    backgroundColor: 'transparent',
                                    p: 0,
                                },
                            },
                        }}
                    >
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {article.content}
                        </ReactMarkdown>
                    </Typography>
            </Box>
            
            {/* Snackbar for copy link feedback */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert 
                    elevation={6} 
                    variant="filled" 
                    onClose={handleClose} 
                    severity="success"
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    </Container>
);
};

export default ArticlePage;