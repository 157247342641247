// src/context/DataContext.js
import React, { createContext, useState } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [currentDataId, setCurrentDataId] = useState(null);

  window.setCurrentDataId = setCurrentDataId;

  return (
    <DataContext.Provider value={{ currentDataId, setCurrentDataId }}>
      {children}
    </DataContext.Provider>
  );
};