// src/components/NodeTypes/SaveTraceNode.js

function SaveTraceNode() {
  // Add one inputs: "Decoded CAN Data"
  this.addInput('Decoded CAN Data', 'decoded_can_data');

  // Node properties (if any)
  this.properties = {};
}

SaveTraceNode.title = 'Save Trace';
SaveTraceNode.desc = 'Saves Trace Data';

// Define the node's execution logic
SaveTraceNode.prototype.onExecute = function () {
  // Get data from inputs
  const inputData = this.getInputData(0); // BLF Data

  // Check if input is available
  if (inputData) {
    // Perform decoding logic
    // For demonstration, we'll just combine the data
    // Replace this with actual decoding using your backend or library

  } else {
    // If inputs are missing, output null
    this.setOutputData(0, null);
  }
};

export default SaveTraceNode;
