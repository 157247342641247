// src/utils/sessionStore.js

const SESSION_KEYS = {
  DECODED_DATA: 'decodedData',
  SIGNALS: 'signals',
  GRAPH_WINDOWS: 'graphWindows',
  TRACE_WINDOWS: 'traceWindows',
  TEST_CASES_WINDOWS: 'testCasesWindows',
  WORKSPACE: 'workspace',
  NUMBER_CONVERTER: 'numberConverter'
};

export const sessionStore = {
  // Store data
  setItem(key, value) {
    try {
      const serialized = JSON.stringify(value);
      sessionStorage.setItem(key, serialized);
    } catch (e) {
      console.error('Error saving to session storage:', e);
    }
  },

  // Get data
  getItem(key) {
    try {
      const serialized = sessionStorage.getItem(key);
      return serialized ? JSON.parse(serialized) : null;
    } catch (e) {
      console.error('Error reading from session storage:', e);
      return null;
    }
  },

  // Store decoded data
  setDecodedData(id, data) {
    const decodedData = this.getItem(SESSION_KEYS.DECODED_DATA) || {};
    decodedData[id] = data;
    this.setItem(SESSION_KEYS.DECODED_DATA, decodedData);
  },

  // Get decoded data
  getDecodedData(id) {
    const decodedData = this.getItem(SESSION_KEYS.DECODED_DATA) || {};
    return decodedData[id];
  },

  // Store signals
  setSignals(id, signals) {
    const allSignals = this.getItem(SESSION_KEYS.SIGNALS) || {};
    allSignals[id] = signals;
    this.setItem(SESSION_KEYS.SIGNALS, allSignals);
  },

  // Get signals
  getSignals(id) {
    const allSignals = this.getItem(SESSION_KEYS.SIGNALS) || {};
    return allSignals[id];
  },

  // Store graph windows state
  setGraphWindows(windows) {
    this.setItem(SESSION_KEYS.GRAPH_WINDOWS, Array.from(windows.entries()));
  },

  // Get graph windows state
  getGraphWindows() {
    const data = this.getItem(SESSION_KEYS.GRAPH_WINDOWS);
    return data ? new Map(data) : new Map();
  },

  // Store trace windows state
  setTraceWindows(windows) {
    this.setItem(SESSION_KEYS.TRACE_WINDOWS, Array.from(windows.entries()));
  },

  // Get trace windows state
  getTraceWindows() {
    const data = this.getItem(SESSION_KEYS.TRACE_WINDOWS);
    return data ? new Map(data) : new Map();
  },

  // Store test cases windows state
  setTestCasesWindows(windows) {
    this.setItem(SESSION_KEYS.TEST_CASES_WINDOWS, Array.from(windows.entries()));
  },

  // Get test cases windows state
  getTestCasesWindows() {
    const data = this.getItem(SESSION_KEYS.TEST_CASES_WINDOWS);
    return data ? new Map(data) : new Map();
  },

  // Store workspace state
  setWorkspace(workspace) {
    this.setItem(SESSION_KEYS.WORKSPACE, workspace);
  },

  // Get workspace state
  getWorkspace() {
    return this.getItem(SESSION_KEYS.WORKSPACE);
  },

  // Clear all stored data
  clearAll() {
    Object.values(SESSION_KEYS).forEach(key => {
      sessionStorage.removeItem(key);
    });
  },

  // Clear workspace state
  clearWorkspace() {
    sessionStorage.removeItem(SESSION_KEYS.WORKSPACE);
    sessionStorage.removeItem(SESSION_KEYS.SIGNALS);
    sessionStorage.removeItem(SESSION_KEYS.DECODED_DATA);
    sessionStorage.removeItem(SESSION_KEYS.TEST_CASES_WINDOWS);
  }
};