// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { sessionStore } from '../utils/sessionStore';

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();

  const checkAdminStatus = async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/me`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setIsAdmin(response.data.role === 'admin');
      return response.data.role === 'admin';
    } catch (error) {
      console.error('Error checking admin status:', error);
      setIsAdmin(false);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Move the token check to a separate useEffect for initialization
  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        setAuthToken(token);
        setIsAuthenticated(true);
        await checkAdminStatus(token);
      }
      setLoading(false); // Set loading to false after initialization
    };

    initializeAuth();
  }, []);

  // Set up periodic admin status check
  useEffect(() => {
    const adminCheckInterval = setInterval(() => {
      const currentToken = localStorage.getItem('authToken');
      if (currentToken) {
        checkAdminStatus(currentToken);
      }
    }, 5 * 60 * 1000); // Check every 5 minutes

    // Add axios interceptor to handle 401/403 responses
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // Only handle auth errors for protected routes
        const protectedRoutes = [
          '/admin',
          '/me', 
          '/users/me',
          '/articles',
          '/products',
          '/subscriptions'
        ];
        
        const isProtectedRoute = protectedRoutes.some(route => 
          error.config.url.includes(route)
        );

        if (isProtectedRoute && (error.response?.status === 401 || error.response?.status === 403)) {
          logout();
          navigate('/', { replace: true });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      clearInterval(adminCheckInterval);
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  const login = async (token) => {
    localStorage.setItem('authToken', token);
    setAuthToken(token);
    setIsAuthenticated(true);
    await checkAdminStatus(token);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userData');
    setAuthToken(null);
    setIsAuthenticated(false);
    setIsAdmin(false);
    navigate('/', { replace: true });
  };

  // Return loading state while initializing
  if (loading) {
    return null; // Or a loading spinner
  }

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      isAdmin, 
      authToken, 
      login, 
      logout,
      loading 
    }}>
      {children}
    </AuthContext.Provider>
  );
}
