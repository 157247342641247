// src/components/HowInscopeWorks.js
import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepButton,
  Box,
  Paper,
  useMediaQuery,
  Grid,
  MobileStepper,
  Button,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const content = [
  {
    id: 1,
    text: "Configure",
    image: "/videos/configure.mp4"
  },
  {
    id: 3,
    text: "Analyze",
    image: "/videos/analyze.mp4"
  },
  {
    id: 4,
    text: "Results",
    image: "/videos/results.mp4"
  }
];

const HowInscopeWorks = () => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const maxSteps = content.length;

  // First, update the getContainerHeight function
  const getContainerHeight = () => {
    if (isXs) return 300;  // Increased base heights
    if (isSm) return 500;
    if (isMd) return 700;
    return 700;
  };

  // Update getPaperHeight function to add more padding
  const getPaperHeight = () => {
    const containerHeight = getContainerHeight();
    const padding = isXs ? 12 : 24; // Increased padding (3rem or 6rem)
    return containerHeight + padding;
  };

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, maxSteps - 1));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: '100%', p: 2 }}>
      <Paper 
        elevation={3} 
        sx={{ 
          py: isXs ? 3 : 4,  // Increased padding
          px: isXs ? 2 : '2rem',
          display: 'flex',
          alignItems: 'center',
          minHeight: `${getPaperHeight()}px`,
          width: '100%',
          overflow: 'hidden'  // Add this to prevent content overflow
        }}
      >
        <Grid 
          container 
          spacing={isXs ? 3 : 1}
          direction={isXs ? 'column' : 'row'}
          alignItems="center"
          justifyContent="center"
          sx={{ 
            width: '100%',
            ml: isXs ? 0 : '-1rem' // Compensate for Grid spacing
          }}
        >
          {/* Video Section */}
          <Grid item xs={12} md={10} order={isXs ? 1 : 2}>
            <Box
              sx={{
                width: '100%',
                maxHeight: `${getContainerHeight()}px`,
                position: 'relative',
                bgcolor: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden'  // Add this to contain the video
              }}
            >
              <Box
                component="video"
                autoPlay
                muted
                loop
                playsInline
                bgcolor={'white'}
                src={content[activeStep].image}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',  // Changed from 'auto' to 'contain'
                  maxHeight: `${getContainerHeight()}px`
                }}
              />
            </Box>
          </Grid>

          {/* Stepper Section */}
          <Grid 
            item 
            xs={12} 
            md={2} 
            order={isXs ? 2 : 1}
            sx={{
              pl: isXs ? 2 : 4,
              pr: isXs ? 2 : 0,
              // Add these new styles
              '& .MuiStepper-root': {
                maxWidth: '180px',  // Limit the stepper width
                margin: '0 auto'    // Center the stepper
              },
              '& .MuiStepLabel-root': {
                padding: '8px 0'    // Add some vertical spacing between steps
              }
            }}
          >
            {isXs ? (
              <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                <Paper
                  square
                  elevation={0}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default',
                  }}
                >
                  <Typography>{content[activeStep].label}</Typography>
                </Paper>
                <MobileStepper
                  variant="text"
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  sx={{
                    width: '100%',
                    '& .MuiMobileStepper-dots': {
                      mx: 4
                    },
                    '& .MuiButton-root': {
                      flex: 1,
                      minWidth: '120px'
                    }
                  }}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >
                      <Box sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        ml: 'auto' // pushes content to right
                      }}>
                        {content[activeStep].text}
                        <KeyboardArrowRight />
                      </Box>
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      <Box sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mr: 'auto' // pushes content to left
                      }}>
                        <KeyboardArrowLeft />
                        {activeStep > 0 ? content[activeStep - 1].text : ''}
                      </Box>
                    </Button>
                  }
                />
              </Box>
            ) : (
              <Stepper
                nonLinear
                activeStep={activeStep}
                orientation="vertical"
              >
                {content.map((item, index) => (
                  <Step key={item.id} completed={false}>
                    <StepButton onClick={handleStep(index)}>
                      {item.text}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default HowInscopeWorks;