// src/components/NodeTypes/LoadDBCNode.js
import { LiteGraph } from 'litegraph.js';
import axios from 'axios';

function LoadDBCNode() {
  this.addOutput('DBC Data', 'dbc_data');
  this.properties = { 
    dbcList: [{ file: null, filename: "", channel: 0 }], 
    dataId: null, 
    nodeState: 'idle' // Can be 'idle', 'processing', or 'completed'
  }; // Initialize with one entry
  this.title = 'Load DBC File';
  this.desc = 'Loads DBC file data with channel association';
  this.state = 'idle'; // 'idle', 'loading', 'completed', 'error'

  // Add these color definitions
  this.backgroundColorByState = {
    idle: '#ccc',
    processing: '#FFA500',  // Yellow/Orange for processing
    completed: '#00FF00'    // Green for completed
  };

  // Set initial color
  this.boxcolor = this.backgroundColorByState.idle;

  // Initialize a promise variable to track the async operation
  this.loadingPromise = null;

  // Get auth token from session storage in methods that need it
  this.getAuthToken = function() {
    const token = localStorage.getItem('authToken');
    if (!token) {
        console.error('No authentication token found in localStorage');
        return null;
    }
    return token;
  };
  this.token = this.getAuthToken();

  // Function to clear existing widgets
  this.clearWidgets = function () {
    this.widgets = [];
  };

  // Add text width computation method
  this.computeTextWidth = function(text, includeLabel = true) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = '12px Arial, sans-serif';
    
    let totalWidth = ctx.measureText(text).width;
    
    if (includeLabel) {
      const labelWidth = ctx.measureText('Selected File: ').width;
      totalWidth += labelWidth;
    }
    
    // Add padding: 20px left + 40px right
    return Math.ceil(totalWidth + 60);
  };

  // Add function to find longest filename
  this.getLongestFilename = function() {
    return this.properties.dbcList
      .filter(entry => entry.file)
      .reduce((longest, current) => {
        if (!longest || (current.file.name.length > longest.length)) {
          return current.file.name;
        }
        return longest;
      }, '');
  };

  // Modify computeNodeSize to use longest filename
  this.computeNodeSize = function() {
    const minWidth = 210;
    const longestName = this.getLongestFilename();
    const textWidth = longestName ? this.computeTextWidth(longestName, true) : minWidth;
    return Math.max(minWidth, textWidth);
  };

  // Function to create a Load File button and Channel input side by side
  this.createFileChannelWidgets = function(index) {
    const currentFile = this.properties.dbcList[index].file;
    const currentChannel = this.properties.dbcList[index].channel;
    console.log('Current Channel:', currentChannel);

    // Add file name widget with stored value
    const fileLabelWidget = this.addWidget(
        'text',
        'Selected File',
        `filepath_${index}`,
        currentFile ? currentFile.name : '',
        {
            width: 1000,  // Increase width allowance
            options: {
                maxLength: 1000,  // Remove character limit
                forceHardwareRendering: true  // Force better text rendering
            }
        }
    );
    
    // Store widget reference in properties
    if (!this.properties.fileWidgets) {
        this.properties.fileWidgets = {};
    }
    this.properties.fileWidgets[index] = fileLabelWidget;

    // Load File Button
    this.addWidget(
      'button',
      `Load File ${index + 1}`,
      `load_${index}`,
      (value) => {
        console.log(`Load File button clicked for index ${index}`);
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.dbc';

        input.onchange = async (e) => {
          e.stopPropagation();
          const file = e.target.files[0];
          if (file) {
            console.log(`File selected: ${file.name}`);
            this.properties.dbcList[index].file = file;
            this.properties.dbcList[index].filename = file.name;
            
            // Update node size based on all files
            this.size[0] = this.computeNodeSize();
            
            this.updateWidgets();
            // Update the specific widget with the selected file name
            fileLabelWidget.value = file.name;
            this.setDirtyCanvas(true);
          }
          
          // Immediately consume next click
          setTimeout(() => {
            const down = new MouseEvent('mousedown', { bubbles: true, cancelable: true, button: 0 });
            const up = new MouseEvent('mouseup', { bubbles: true, cancelable: true, button: 0 });
            document.dispatchEvent(down);
            document.dispatchEvent(up);
          }, 0);
        };

        input.click();
      },
      { step: 0, precision: 0, align: LiteGraph.ALIGN_LEFT }
    );

    // Channel Number Input
    const currentChannelWidget = this.addWidget(
      'number',
      `Channel`,
      `channel_${index}`,
      (value) => {
        console.log(`Channel input changed for index ${index}: ${value}`);
        this.properties.dbcList[index].channel = parseInt(value, 10);
        this.setDirtyCanvas(true);
      },
      { step: 0, precision: 0, align: LiteGraph.ALIGN_LEFT }
    );
    // currentChannelWidget.value = currentChannel !== undefined ? currentChannel : 0; // Set existing channel or default to 0
    currentChannelWidget.value = this.properties.dbcList[index].channel || 0;
    this.properties.dbcList[index].channel = currentChannelWidget.value;
    
    // Add near channel widget setup:
    console.log(`Setting channel for index ${index}:`, {
        widgetValue: currentChannelWidget.value,
        propertiesChannel: this.properties.dbcList[index].channel
    });

    // Add this reset logic when channel changes
    currentChannelWidget.callback = (value) => {
      this.properties.dbcList[index].channel = parseInt(value, 10);

      // Extract signatures for comparison
      this.properties.previousSelection = null

      // Reset node state
      this.properties.nodeState = 'idle';
      this.properties.dataId = null;
      this.boxcolor = this.backgroundColorByState.idle;
      this.setOutputData(0, null);  // Clear output
      this.setDirtyCanvas(true);
    };

    // Add a spacer between each pair
    this.addWidget('label', '', '', null, { class: 'spacer' });

    if (currentFile) {
      // Update node size when there's a file
      this.size[0] = this.computeNodeSize();
    }
  };

  // Function to update all widgets based on dbcList
  this.updateWidgets = function() {
    // Store current state
    const currentState = {
        dbcList: [...this.properties.dbcList],
        fileWidgets: {...this.properties.fileWidgets}
    };
    
    this.clearWidgets();
    
    // Restore state
    this.properties.dbcList = currentState.dbcList;
    this.properties.fileWidgets = {};
    
    // Recreate widgets
    this.properties.dbcList.forEach((dbc, index) => {
        this.createFileChannelWidgets(index);
        // Update file name display if file exists
        if (dbc.file) {
            const fileWidget = this.properties.fileWidgets[index];
            if (fileWidget) {
                fileWidget.value = dbc.filename;
            }
        }
    });

    // Add spacer and + button
    this.addWidget('label', '', '', null, { class: 'spacer' });

    this.addWidget(
        'button',
        'Add DBC',
        '+',
        () => {
            const newList = [...this.properties.dbcList, { file: null, channel: 0 }];
            this.properties.dbcList = newList;
            console.log('Added new entry - dbcList:', this.properties.dbcList);
            this.setDirtyCanvas(true);
            this.updateWidgets();
        },
        { step: 0, precision: 0, align: LiteGraph.ALIGN_RIGHT }
    );

    // Update size after rebuilding widgets
    this.size[0] = this.computeNodeSize();
    this.setDirtyCanvas(true);
  };

  // When adding a new DBC entry, include the File object
  this.handleAdd = function (selectedFile) {
    this.properties.dbcList.push({ file: selectedFile, filename: selectedFile.name, channel: 0 });
    this.setDirtyCanvas(true);
    this.updateWidgets();
  };

  // Example file input handler
  this.onFileSelected = function (index, event) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.properties.dbcList[index].file = selectedFile;
      this.setDirtyCanvas(true);
    }
  };

  // Initial widget setup
  this.updateWidgets();

  this.resetState = function() {
    this.properties.nodeState = 'idle';
    this.properties.dataId = null;
    this.boxcolor = this.backgroundColorByState.idle;
    this.setDirtyCanvas(true);
  };

  // Override widget drawing to prevent text truncation
  this.onDrawForeground = function(ctx) {
    for (let w of this.widgets || []) {
        if (w.type === "text") {
            w.computeSize = function(width) {
                return [Math.max(width, ctx.measureText(this.value).width + 20), 20];
            };
        }
    }
  };
}

LoadDBCNode.prototype.onExecute = async function () {
  console.debug('Executing LoadDBCNode');
  
  // Don't process if already completed
  if (this.properties.nodeState === 'completed' && this.properties.dataId) {
      this.setOutputData(0, this.properties.dataId);
      return;
  }

  // Don't process if already processing
  if (this.properties.nodeState === 'processing') {
      return;
  }

  // Compute current selection signature
  const currentSelection = this.properties.dbcList
      .filter(dbc => dbc.file)
      .map(({ file, channel }) => ({
          name: file.name,
          size: file.size,
          lastModified: file.lastModified,
          channel: channel
      }));

  // If no files selected, reset and return
  if (currentSelection.length === 0) {
      this.properties.nodeState = 'idle';
      this.boxcolor = this.backgroundColorByState.idle;
      this.setOutputData(0, null);
      this.setDirtyCanvas(true);
      return;
  }

  try {
      this.properties.nodeState = 'processing';
      this.boxcolor = this.backgroundColorByState.processing;
      this.setDirtyCanvas(true);

      const dbcData = new FormData();
      this.properties.dbcList
          .filter(dbc => dbc.file)
          .forEach(({ file, channel }) => {
              dbcData.append('dbc_files', file, file.name);
              dbcData.append('channels', channel.toString());
          });

      // Get auth token
      const token = this.getAuthToken();
      if (!token) {
          throw new Error('No authentication token found');
      }

      console.log('Auth token:', token);
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/offline_files/upload_dbc`,
          dbcData,
          {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}`
              }
          }
      );

      const dataId = response.data.dbc_data_id;
      this.properties.dataId = dataId;
      this.setOutputData(0, dataId);
      this.properties.nodeState = 'completed';
      this.boxcolor = this.backgroundColorByState.completed;

  } catch (error) {
      console.error('Error processing DBC files:', error);
      this.properties.nodeState = 'error';
      this.boxcolor = this.backgroundColorByState.error;
      this.setOutputData(0, null);
      
      // More detailed error logging
      if (error.response) {
          console.error('Error response:', {
              status: error.response.status,
              data: error.response.data
          });
      }
  }

  this.setDirtyCanvas(true);
};

LoadDBCNode.prototype.onFileSelected = function (index, event) {
  const selectedFile = event.target.files[0];
  if (selectedFile) {
    this.resetState(); // Reset state when new file is loaded
    this.properties.dbcList[index].file = selectedFile;
    this.setDirtyCanvas(true);
  }
};

LoadDBCNode.prototype.onConfigure = function (savedData) {
  // If savedData already includes dbcList, copy it to this.properties
  if (savedData && savedData.dbcList) {
    this.properties.dbcList = savedData.dbcList;
  }

  // Rebuild the widget UI so it shows the correct channel/file names, etc.
  this.updateWidgets();
};


export default LoadDBCNode;
