// src/context/CartContext.js
import React, { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

export function CartProvider({ children }) {
  // Initialize state from localStorage if available
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Update localStorage whenever cart changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (product) => {
    setCartItems(prevItems => {
      const existingItem = prevItems.find(item => 
        item.id === product.id && 
        (!item.type || item.type === product.type) &&
        (!item.selectedPlan || item.selectedPlan === product.selectedPlan)
      );

      if (existingItem) {
        return prevItems.map(item =>
          item.id === product.id &&
          (!item.type || item.type === product.type) &&
          (!item.selectedPlan || item.selectedPlan === product.selectedPlan)
            ? { ...item, quantity: (item.quantity || 1) + 1 }
            : item
        );
      }

      return [...prevItems, {
        ...product,
        quantity: 1
      }];
    });
  };

  const removeFromCart = (productId) => {
    setCartItems(cartItems.filter(item => item.id !== productId));
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cart');
  };

  // Add updateCartItem function
  const updateCartItem = (productId, updatedItem) => {
    setCartItems(cartItems.map(item => 
      item.id === productId ? updatedItem : item
    ));
  };

  const updateQuantity = (productId, quantity, plan = null) => {
    setCartItems(prevItems =>
      prevItems.map(item =>
        (item.id === productId && (!plan || item.selectedPlan === plan))
          ? { ...item, quantity: Math.max(1, quantity) }
          : item
      )
    );
  };

  return (
    <CartContext.Provider value={{ 
      cartItems, 
      addToCart, 
      removeFromCart, 
      clearCart,
      updateCartItem,
      updateQuantity
    }}>
      {children}
    </CartContext.Provider>
  );
}