import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Chip,
  MenuItem,
  FormControlLabel,
  Switch,
  useTheme,
  useMediaQuery,
  Input,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const ArticleForm = ({ article, onSubmit, onClose }) => {
  const { authToken } = useContext(AuthContext);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [formData, setFormData] = useState(
    article || {
      title: '',
      description: '',
      content: '',
      tags: [],
      image_data: '',  // Changed from image_url
      authors: [{ name: '', avatar: '' }],
      published: false,
    }
  );
  const [newTag, setNewTag] = useState('');  // New state for tag input

  const handleImageUpload = async (event) => {
    try {
      setUploading(true);
      setUploadError(null);
      const file = event.target.files[0];
      
      if (!file) return;

      if (!file.type.startsWith('image/')) {
        setUploadError('Please upload an image file');
        return;
      }

      // Convert to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          image_data: reader.result
        }));
        setUploading(false);
      };
      reader.onerror = () => {
        setUploadError('Failed to read image file');
        setUploading(false);
      };
      reader.readAsDataURL(file);

    } catch (error) {
      setUploadError('Failed to process image');
      setUploading(false);
    }
  };

  const handleAuthorChange = (index, field, value) => {
    const newAuthors = [...formData.authors];
    newAuthors[index] = { ...newAuthors[index], [field]: value };
    setFormData({ ...formData, authors: newAuthors });
  };

  const addAuthor = () => {
    setFormData({
      ...formData,
      authors: [...formData.authors, { name: '', avatar: '' }],
    });
  };

  const removeAuthor = (index) => {
    const newAuthors = formData.authors.filter((_, i) => i !== index);
    setFormData({ ...formData, authors: newAuthors });
  };

  const handleTagAdd = () => {
    if (newTag.trim() && !formData.tags.includes(newTag.trim())) {
      setFormData({
        ...formData,
        tags: [...formData.tags, newTag.trim()]
      });
      setNewTag('');
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setFormData({
      ...formData,
      tags: formData.tags.filter(tag => tag !== tagToDelete)
    });
  };

  return (
    <>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Title"
            fullWidth
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
          <TextField
            label="Content"
            fullWidth
            multiline
            rows={5}
            value={formData.content}
            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
          />
          
          {/* Tags Section */}
          <Box>
            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
              <TextField
                label="Add Tag"
                size="small"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleTagAdd();
                  }
                }}
              />
              <Button
                variant="outlined"
                onClick={handleTagAdd}
                disabled={!newTag.trim()}
              >
                Add
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {formData.tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleTagDelete(tag)}
                />
              ))}
            </Box>
          </Box>
          
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Button
                component="label"
                variant="outlined"
                startIcon={<ImageIcon />}
                disabled={uploading}
              >
                Upload Image
                <Input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </Button>
              {uploading && <CircularProgress size={24} />}
              {formData.image_data && (
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Image uploaded successfully
                  </Typography>
                  <img 
                    src={formData.image_data} 
                    alt="Preview" 
                    style={{ 
                      maxWidth: '200px', 
                      maxHeight: '200px',
                      marginTop: '8px',
                      objectFit: 'contain' 
                    }} 
                  />
                </Box>
              )}
            </Box>
            {uploadError && (
              <Typography color="error" variant="caption">
                {uploadError}
              </Typography>
            )}
          </Box>
          
          <Typography variant="h6">Authors</Typography>
          {formData.authors.map((author, index) => (
            <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
              <TextField
                label="Author Name"
                value={author.name}
                onChange={(e) => handleAuthorChange(index, 'name', e.target.value)}
              />
              <TextField
                label="Avatar URL"
                value={author.avatar}
                onChange={(e) => handleAuthorChange(index, 'avatar', e.target.value)}
              />
              {index > 0 && (
                <Button color="error" onClick={() => removeAuthor(index)}>
                  Remove
                </Button>
              )}
            </Box>
          ))}
          <Button onClick={addAuthor}>Add Author</Button>
          
          <FormControlLabel
            control={
              <Switch
                checked={formData.published}
                onChange={(e) => setFormData({ ...formData, published: e.target.checked })}
              />
            }
            label="Published"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={() => onSubmit(formData)} 
          variant="contained" 
          disabled={uploading || !authToken}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

const AdminArticlesPage = () => {
  const { isAdmin, authToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const fetchArticles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/articles?published_only=false`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        }
      );
      setArticles(response.data);
    } catch (err) {
      setError('Failed to fetch articles');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isAdmin) {
      navigate('/', { replace: true });
      return;
    }

    fetchArticles();
  }, [isAdmin, navigate]);

  if (!isAdmin) {
    return null; // Don't render anything while redirecting
  }

  const handleSubmit = async (formData) => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };

      if (selectedArticle) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/articles/${selectedArticle.id}`, 
          formData,
          config
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/articles`,
          formData,
          config
        );
      }
      fetchArticles();
      setOpenDialog(false);
      setSelectedArticle(null);
    } catch (err) {
      console.error('Error saving article:', err);
      setError('Failed to save article');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this article?')) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/articles/${id}`,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`
            }
          }
        );
        fetchArticles();
      } catch (err) {
        console.error('Error deleting article:', err);
        setError('Failed to delete article');
      }
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: { xs: 2, sm: 3, md: 4 }, mt: 8 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate('/latest')}
            >
              Back to Latest
            </Button>
            <Typography variant={isMobile ? "h4" : "h3"}>Manage Articles</Typography>
          </Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setSelectedArticle(null);
              setOpenDialog(true);
            }}
          >
            New Article
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Tags</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articles.map((article) => (
                <TableRow key={article.id}>
                  <TableCell>{article.title}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                      {article.tags?.map((tag, index) => (
                        <Chip key={index} label={tag} size="small" />
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={article.published ? 'Published' : 'Draft'}
                      color={article.published ? 'success' : 'default'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setSelectedArticle(article);
                        setOpenDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(article.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setSelectedArticle(null);
          }}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {selectedArticle ? 'Edit Article' : 'New Article'}
          </DialogTitle>
          <ArticleForm
            article={selectedArticle}
            onSubmit={handleSubmit}
            onClose={() => {
              setOpenDialog(false);
              setSelectedArticle(null);
            }}
          />
        </Dialog>
      </Box>
    </Container>
  );
};

export default AdminArticlesPage;