// src/pages/WhatWeDoSection.js
import React, { useContext, useState } from 'react';

// @mui material components
import { Box, Typography, Button, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const WhatWeDoSection = () => {
    const { authToken } = useContext(AuthContext);
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleStartTrial = () => {
        if (!authToken) {
            setOpenSnackbar(true);
            return;
        }
        navigate('/account#subscriptions');
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };

    return (
        <Box
            sx={{
                maxWidth: '1500px',
                width: '100%',
                minHeight: '70vh',
                py: 8,
                px: 4,
                mt: -4,
                display: 'flex',
                gap: 6,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: { xs: 'column', md: 'row' },
                textAlign: 'center',
            }}
            className="what-we-do-section"
        >
            <Box
                component="img"
                src="/images/whatwedo.png"
                alt="What We Do"
                sx={{
                    width: { xs: '100%', md: '45%' },
                    height: 'auto',
                    objectFit: 'contain'
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    width: { xs: '100%', md: '45%' },
                    gap: 3
                }}
            >
                <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Empower experts and non experts in the vehicle data analysis field.
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="h4" color="primary">01.</Typography>
                    <Typography variant="body1" gutterBottom sx={{textAlign: 'left'}}>
                        Interface compatible with vehicle communication protocols, including CAN, CAN FD, LIN, and Automotive Ethernet.
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="h4" color="primary">02.</Typography>
                    <Typography variant="body1" gutterBottom sx={{textAlign: 'left'}}>
                        Available as a web app, with mobile and portable versions coming soon for enhanced accessibility.
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="h4" color="primary">03.</Typography>
                    <Typography variant="body1" gutterBottom sx={{textAlign: 'left'}}>
                        Provides real-time signal behavior visualization to detect anomalies and generate actionable insights.
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleStartTrial}
                >
                    Start free trial
                </Button>
            </Box>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity="info"
                >
                    Please log in or create an account to start your free trial
                </MuiAlert>
            </Snackbar>
        </Box>
    );
}

export default WhatWeDoSection;